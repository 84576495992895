import { spaceCategoryConfigs } from '@fastfive-dev/frontend-library/dist/fastfive/libs/space'
import { MS } from '@pkg/shared/dist/time/constants'

import { client } from '@/api'
import { IMemberGroup } from '@/interfaces/api/member-groups'
import { ISpaceCategory } from '@/interfaces/api/spaces/categories'
import { buildField } from '@/libs/api'
import { axiosHandler } from '@/libs/api/axios-handler'
import { failResponse } from '@/libs/api/custom-responses'

export * from '@/interfaces/api/member-groups'

/**
 * 기존 버전 API
 *
 * 하단의 MemberGroupApi로 변경할 예정
 */
export const MemberGroupLegacyApi = {
  async getBranchNotices({
    memberGroupId,
    branchId,
    page = 1,
    size = 15,
    indexId = null,
    isMainNotice,
  }) {
    const params: any = {
      indexId,
      page,
      size,
    }
    if (typeof isMainNotice === 'boolean') {
      params.isMainNotice = isMainNotice
    }
    return client.get(
      `/mobile/memberGroups/${memberGroupId}/branches/${branchId}/notices`,
      { params },
    )
  },
  async getMemberGroupStatus(memberGroupId) {
    return client.get(`/mobile/memberGroups/${memberGroupId}/status`)
  },
  async issueInvitationCode(memberGroupId) {
    return axiosHandler(() => {
      return client.get(`/mobile/memberGroups/${memberGroupId}/invitationCode`)
    })
  },
  async getCompanyViaInvitationCode(code) {
    return client.get(`/mobile/memberGroups/invitationCode/${code}`)
  },
  async getGuideMessage({
    memberGroupId,
    isImportant,
    page = 1,
    size = 15,
    indexId = null,
  }) {
    return client.get(`/mobile/memberGroups/${memberGroupId}/notifications`, {
      params: {
        isImportant,
        indexId,
        page,
        size,
      },
    })
  },
  /**
   * @deprecated
   */
  async setGroupImage(memberGroupId, fileId) {
    return client.post(`/mobile/memberGroups/${memberGroupId}/images`, {
      fileId,
    })
  },
  /**
   * @deprecated
   */
  async deleteGroupImage(memberGroupId) {
    return client.delete(`/mobile/memberGroups/${memberGroupId}/images`)
  },
}

export class MemberGroupApi {
  /**
   * 소속그룹 상세 정보 가져오기
   *
   * - 포스트맨 : 멤버 그룹 정보 조회 (예약시간, 이미지, 지점)
   *
   * 이 함수 사용 대신 `useMemberGroupStore().load(memberGroupId)` 사용 권장
   */
  static async getOne(memberGroupId: number) {
    if (!memberGroupId) {
      return failResponse<IMemberGroup.API.GetOne.Response>(
        '잘못된 요청입니다.',
      )
    }

    return axiosHandler<IMemberGroup.API.GetOne.Response>(() => {
      return client.get(`/mobile/memberGroups/${memberGroupId}`, {
        params: {
          fields: buildField([
            'success',
            {
              name: 'memberGroup',
              fields: [
                'name',
                'imageUrl',
                'serviceTypes',
                'isFlexibleOffice',
                'remainingCredits',
                'usedCredits',
                'freeRemainingCredits',
                'freeUsedCredits',
                'paidRemainingCredits',
                'paidUsedCredits',
              ],
            },
            'errorCode',
            'message',
          ]),
        },
      })
    })
  }

  static async getMemberCount(memberGroupId: number) {
    if (!memberGroupId) {
      return failResponse<IMemberGroup.API.GetMemberCount.Response>(
        '잘못된 요청입니다.',
      )
    }

    return axiosHandler<IMemberGroup.API.GetMemberCount.Response>(() => {
      return client.get(`/mobile/memberGroups/${memberGroupId}/memberCount`)
    })
  }

  static async getMemberList(payload: IMemberGroup.API.GetMemberList.Request) {
    const {
      isNew,
      memberGroupId,
      order,
      page = 0,
      role,
      size = 15,
      sort,
      searchText,
    } = payload

    if (!memberGroupId) {
      return failResponse<IMemberGroup.API.GetMemberList.Response>(
        '잘못된 요청입니다.',
      )
    }

    return axiosHandler<IMemberGroup.API.GetMemberList.Response>(() => {
      return client.get(`/mobile/memberGroups/${memberGroupId}/memberRoles`, {
        params: {
          isNew,
          order,
          page,
          role,
          size,
          sort,
          searchText,
        },
      })
    })
  }

  /**
   * 멤버 그룹별 공간 유형 목록 조회
   *
   * @see https://fastfive.postman.co/workspace/FASTFIVE-API~e8db32c4-3bd4-450d-884c-6163c00a6ff9/request/19200136-edb85907-fe67-4328-9558-ae8a2fe6de10
   */
  static async getSpaceCategories({
    memberGroupId,
  }: IMemberGroup.API.GetSpaceCategories.Request) {
    return axiosHandler<IMemberGroup.API.GetSpaceCategories.Response>(() => {
      return client.get(
        `/mobile/memberGroups/${memberGroupId}/spaceCategories`,
        {
          cache: {
            ttl: MS['1_MIN'],
          },
        },
      )
    }).then((res) => {
      if (Array.isArray(res.spaceCategories)) {
        const memo = new Map(
          spaceCategoryConfigs.map((row) => {
            return [row.name, row]
          }),
        )

        const nextSpaceCategories: ISpaceCategory.Category[] = []

        res.spaceCategories.forEach((row) => {
          const spaceCategory = memo.get(row.name)

          if (spaceCategory) {
            nextSpaceCategories.push(row)
          }
        })
        memo.clear()

        res.spaceCategories = nextSpaceCategories
      }

      return res
    })
  }

  /**
   * 멤버 그룹 내의 멤버 리스트 조회
   */
  static async getMemberListWithCard({
    memberGroupId,
    ...payload
  }: IMemberGroup.API.GetMembers.Request) {
    return axiosHandler<IMemberGroup.API.GetMembers.Response>(() => {
      return client.get(`/mobile/memberGroups/${memberGroupId}/members`, {
        params: payload,
      })
    })
  }

  /**
   * 멤버 그룹에서 내보내기
   */
  static async kickoutMember({
    memberGroupId,
    memberId,
  }: IMemberGroup.API.KickoutMember.Request) {
    return axiosHandler<IMemberGroup.API.KickoutMember.Response>(() => {
      return client.delete(
        `/mobile/memberGroups/${memberGroupId}/members/${memberId}`,
      )
    })
  }

  /**
   * 지정한 다수 멤버 멤버 그룹 내보내기
   */
  static async kickoutSelectedMembers({
    memberGroupId,
    ...payload
  }: IMemberGroup.API.KickoutSelectedMembers.Request) {
    return axiosHandler<IMemberGroup.API.KickoutSelectedMembers.Response>(
      () => {
        return client.patch(
          `/mobile/memberGroups/${memberGroupId}/members`,
          payload,
        )
      },
    )
  }

  /**
   * 멤버 역할 변경하기
   */
  static async changeMemberRole({
    memberGroupId,
    memberId,
    ...payload
  }: IMemberGroup.API.ChangeMemberRole.Request) {
    return axiosHandler<IMemberGroup.API.ChangeMemberRole.Response>(() => {
      return client.patch(
        `/mobile/memberGroups/${memberGroupId}/members/${memberId}/changeRole`,
        payload,
      )
    })
  }

  /**
   * 예약하기> 지점목록 조회 (공간유형이 변경될 때 마다 호출)
   */
  static async getMemberGroupBranchList({
    memberGroupId,
    spaceCategoryId,
  }: IMemberGroup.API.GetMemberGroupBranchList.Request) {
    return axiosHandler<IMemberGroup.API.GetMemberGroupBranchList.Response>(
      () => {
        return client.get(`/mobile/memberGroups/${memberGroupId}/branches`, {
          params: {
            spaceCategoryId,
          },
          cache: {
            ttl: MS['1_MIN'],
          },
        })
      },
    )
  }
}
