import { AxiosCacheInstance } from 'axios-cache-interceptor'

import {
  BenefitCurationGetListApiResponse,
  BenefitCurationGetOneApiPayload,
  BenefitCurationGetOneApiResponse,
} from '@/interfaces/api/benefits/curation'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createBenefitCurationService = (client: AxiosCacheInstance) => {
  return {
    async getOne(id: number, payload: BenefitCurationGetOneApiPayload = {}) {
      return await axiosHandler<BenefitCurationGetOneApiResponse>(() => {
        return client.get(`/mobile/benefit/curation/${id}`, {
          params: {
            childPage: payload.childPage,
            childSize: payload.childSize,
          },
        })
      })
    },

    async getList() {
      return await axiosHandler<BenefitCurationGetListApiResponse>(() => {
        return client.get('/mobile/benefit/curation', {
          params: {
            page: 1,
            size: 20,
          },
        })
      })
    },
  }
}
