<template>
  <div
    class="ff-tag-slider"
    :style="cssVars"
  >
    <div class="tag-slider--container">
      <Swiper
        class="tag-slider--swiper"
        :modules="swiperOptions.modules"
        :speed="swiperOptions.speed"
        :autoHeight="swiperOptions.autoHeight"
        :allowTouchMove="swiperOptions.allowTouchMove"
        :spaceBetween="swiperOptions.spaceBetween"
        :slidesPerView="swiperOptions.slidesPerView"
        :freeMode="swiperOptions.freeMode"
        @swiper="setSwiperController"
        @resize="checkScrollable"
      >
        <template
          v-for="(row, key) in list"
          :key="key"
        >
          <SwiperSlide class="tag-slider--item">
            <Tag
              :theme="row.theme"
              :selected="row.selected"
              :onClick="() => onItemClick(row)"
            >
              {{ row.name }}
            </Tag>
          </SwiperSlide>
        </template>
      </Swiper>
    </div>

    <div
      v-show="state.progress !== 0 && state.isScrollable"
      class="tag-slider--nav tag-slider--nav--prev"
    >
      <Scroller
        type="dropshadow"
        direction="left"
        @click="slideTo('prev')"
      />
    </div>

    <div
      v-show="state.progress !== 1 && state.isScrollable"
      class="tag-slider--nav tag-slider--nav--next"
    >
      <Scroller
        type="dropshadow"
        direction="right"
        @click="slideTo('next')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import SwiperCore, { Controller, FreeMode, SwiperOptions } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'
import {
  PropType,
  computed,
  defineComponent,
  nextTick,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  reactive,
  ref,
  watch,
} from 'vue'

import Scroller from '@/components/common/slider/Scroller.vue'
import Tag from '@/components/common/tag/Tag.vue'

import colors from '@/assets/scss/base/colors.module.scss'
import { TagSlideItem } from '@/interfaces/components/common/tag'
import { hexToRGBA } from '@/libs/css'

export default defineComponent({
  name: 'TagSlider',
  components: {
    Swiper,
    SwiperSlide,
    Tag,
    Scroller,
  },
  props: {
    /**
     * HEX 코드 또는 정의된 컬러명(src/assets/scss/base/colors.module.scss 참고)
     */
    bgColor: {
      type: String,
      default: colors['white'],
    },
    list: {
      type: Array as PropType<TagSlideItem[]>,
      default: () => [],
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const swiperController = ref<SwiperCore>()

    const state = reactive({
      progress: 0,
      isScrollable: false,
    })

    const swiperOptions = computed<SwiperOptions>(() => {
      return {
        modules: [Controller, FreeMode],
        speed: 350,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 8,
        slidesPerView: 'auto',
        freeMode: {
          enabled: true,
          momentum: false,
        },
      }
    })

    const cssVars = computed(() => {
      const bgColor = colors[props.bgColor] ?? props.bgColor
      const bgTransparentColor = hexToRGBA(bgColor, 0)

      return {
        '--tag-slider--bg-color': bgColor,
        '--tag-slider--bg-transparent-color': bgTransparentColor,
      }
    })

    watch(
      () => props.list,
      () => {
        checkScrollable()
      },
    )

    onActivated(() => {
      swiperController.value?.enable()
      swiperController.value?.update()

      checkScrollable()
    })

    onDeactivated(() => {
      swiperController.value?.disable()
    })

    onBeforeUnmount(() => {
      swiperController.value?.destroy()
    })

    function setSwiperController(swiper) {
      swiperController.value = swiper
      checkScrollable()
    }

    function onItemClick(row: TagSlideItem) {
      row.onClick?.()
      emit('click', row)
    }

    function slideTo(direction: 'prev' | 'next') {
      const swiper = swiperController.value
      if (!swiper) return

      const { wrapperEl } = swiper

      const width = wrapperEl.clientWidth
      const maxWidth = wrapperEl.scrollWidth
      const minTranslate = width - maxWidth
      const distance = width * 0.6 * (direction === 'prev' ? -1 : 1)
      const nextTranslate = swiper.translate - distance
      let nextProgress = nextTranslate / minTranslate
      nextProgress = Math.round(nextProgress * 10000) / 10000
      nextProgress = Math.min(nextProgress, 1)
      nextProgress = Math.max(nextProgress, 0)

      state.progress = nextProgress

      swiper.setProgress(nextProgress, 350)
    }

    function checkScrollable() {
      nextTick(() => {
        const swiper = swiperController.value
        if (!swiper) return

        const { wrapperEl } = swiper

        state.isScrollable = wrapperEl.clientWidth < wrapperEl.scrollWidth
      })
    }

    return {
      state,
      swiperOptions,
      cssVars,

      // methods
      setSwiperController,
      onItemClick,
      slideTo,
      checkScrollable,
    }
  },
})
</script>

<style lang="scss" scoped>
.ff-tag-slider {
  position: relative;
  background-color: var(--tag-slider--bg-color);

  .tag-slider--swiper {
    .tag-slider--item {
      display: inline-block;
      width: auto;
    }
  }

  .tag-slider--nav {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 100px;
    display: flex;
    align-items: center;

    &.tag-slider--nav--prev {
      left: 0;
      justify-content: flex-start;
      background: linear-gradient(
        270deg,
        var(--tag-slider--bg-transparent-color) 0%,
        var(--tag-slider--bg-color) 64%
      );
    }

    &.tag-slider--nav--next {
      right: 0;
      justify-content: flex-end;
      background: linear-gradient(
        90deg,
        var(--tag-slider--bg-transparent-color) 0%,
        var(--tag-slider--bg-color) 64%
      );
    }
  }
}
</style>
