import { defineStore } from 'pinia'

import { KEEP_ALIVE } from '@/store/keys'

export const useKeepAliveStore = defineStore(KEEP_ALIVE, {
  state: () => ({
    includedComponentNames: [] as string[],
    history: new Set<string>(),
  }),
  getters: {
    visited: (state) => (routeComponentName: string) =>
      state.history.has(routeComponentName),
  },
  actions: {
    visit(routeComponentName: string) {
      if (!this.includedComponentNames.includes(routeComponentName)) {
        this.includedComponentNames.push(routeComponentName)
      }

      this.history.add(routeComponentName)
    },

    delete(routeComponentName: string) {
      this.history.delete(routeComponentName)
    },
  },
})
