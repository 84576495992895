<template>
  <div :class="[$style.container, { [$style.responsive]: isResponsive }]">
    <div :class="[$style.section, $style.firstSection]">
      <img
        :class="$style.logo"
        :src="images.logo"
        alt="logo"
      />

      <ul :class="$style.item">
        <li :class="$style.title">비즈니스</li>
        <li>
          <a
            v-text="'회사 소개'"
            href="https://www.fastfive.co.kr/bottom_officeplatform"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_about_us')"
          />
        </li>
        <li>
          <a
            v-text="'비즈니스 제휴 문의'"
            href="mailto:platform@fastfive.co.kr"
            target="_blank"
            @click="
              () => analytics.presetLogEvent('footer_businesspartnership_email')
            "
          />
        </li>
        <li>
          <a
            v-text="'부동산 입점 문의'"
            href="mailto:building@fastfive.co.kr"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_realestate_email')"
          />
        </li>
      </ul>

      <ul :class="$style.item">
        <li :class="$style.title">미디어</li>
        <li>
          <a
            v-text="'미디어'"
            href="https://corp.fastfive.co.kr/"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_media')"
          />
        </li>
        <li>
          <a
            v-text="'언론 홍보 문의'"
            href="mailto:pr@fastfive.co.kr"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_press_email')"
          />
        </li>
      </ul>

      <ul :class="$style.item">
        <li :class="$style.title">패스트파이브를 만나보세요</li>

        <li class="tw-flex">
          <a
            :class="$style.snsBtn"
            href="https://www.facebook.com/ftfive"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_SNS_facebook')"
            @mouseenter="onSnsHover('facebook')"
            @mouseleave="onSnsBlur"
          >
            <IconFacebook :color="getSnsIconColor('facebook')" />
          </a>

          <a
            :class="$style.snsBtn"
            href="https://www.instagram.com/fastfive_official/"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_SNS_instagram')"
            @mouseenter="onSnsHover('instagram')"
            @mouseleave="onSnsBlur"
          >
            <IconInstagram :stroke="getSnsIconColor('instagram')" />
          </a>

          <a
            :class="$style.snsBtn"
            href="https://blog.naver.com/fast_five_"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_SNS_naverblog')"
            @mouseenter="onSnsHover('naverblog')"
            @mouseleave="onSnsBlur"
          >
            <IconNaver :color="getSnsIconColor('naverblog')" />
          </a>

          <a
            :class="$style.snsBtn"
            href="https://www.youtube.com/c/FASTFIVEOFFICIAL"
            target="_blank"
            @click="() => analytics.presetLogEvent('footer_SNS_youtube')"
            @mouseenter="onSnsHover('youtube')"
            @mouseleave="onSnsBlur"
          >
            <IconYoutube :color="getSnsIconColor('youtube')" />
          </a>
        </li>
      </ul>
    </div>

    <div :class="[$style.section, $style.secondSection]">
      <div :class="$style.copyright">ⓒ FASTFIVE Corp.</div>
      <div :class="$style.item">
        <span
          :class="$style.pointer"
          @click="moveFaqServiceAgreement"
        >
          서비스 이용약관
        </span>
      </div>
      <div :class="$style.item">
        <a
          v-text="'개인정보 처리방침'"
          href="https://www.fastfive.co.kr/privacy-policy"
          target="_blank"
          @click="() => analytics.presetLogEvent('footer_privacypolicy')"
        />
      </div>
    </div>

    <div :class="[$style.section, $style.thirdSection]">
      <span>대표 : 김대일</span>
      <span :class="$style.dot" />
      <span>사업자등록번호 : 151-81-00025</span>
      <span :class="$style.address">
        <span :class="$style.dot" />
        <!-- prettier-ignore -->
        <span>서울시 서초구 남부순환로 333길 10, 2층, 3층 (서초동, 원일빌딩)</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import IconFacebook from '@/components/v2/icon/IconFacebook.vue'
import IconInstagram from '@/components/v2/icon/IconInstagram.vue'
import IconNaver from '@/components/v2/icon/IconNaver.vue'
import IconYoutube from '@/components/v2/icon/IconYoutube.vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'
import { useAnalytics } from '@/plugins/firebase'

const images = {
  logo: require('@/assets/images/logo/logo_fastfive.png'),
}

type SnsType = 'facebook' | 'instagram' | 'naverblog' | 'youtube'

export default defineComponent({
  components: {
    IconFacebook,
    IconInstagram,
    IconNaver,
    IconYoutube,
  },
  props: {
    isResponsive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const analytics = useAnalytics()

    const state = reactive({
      hoverSnsType: null as SnsType | null,
    })

    const router = useRouter()

    function onSnsHover(snsType: SnsType) {
      state.hoverSnsType = snsType
    }

    function onSnsBlur() {
      state.hoverSnsType = null
    }

    function getSnsIconColor(snsType: SnsType) {
      return state.hoverSnsType === snsType
        ? colors['f-primary-black']
        : colors['f-gray-35']
    }
    function moveFaqServiceAgreement() {
      router.push({
        name: 'FaqDetail',
        params: {
          KnowledgeId: 'Ka05g000001ixpt',
        },
        query: {
          title: '서비스 이용약관',
        },
      })
      analytics.presetLogEvent('footer_termsofservice')
    }

    return {
      images,
      analytics,
      onSnsHover,
      onSnsBlur,
      getSnsIconColor,
      moveFaqServiceAgreement,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  padding: 0 40px;
  border-top: 1px solid $f-gray-20;
  min-width: 784px;

  a {
    cursor: pointer;
    color: $primary-black;

    &:hover {
      text-decoration: underline;
    }
  }

  .section {
    @include font_v2('ko', 12px, 400);

    display: flex;
    align-items: center;
    padding: 16px 0;
    color: $f-gray-45;
    border-bottom: 1px solid $f-gray-20;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $f-primary-black;
      }

      &:active {
        color: $f-primary-black;
      }
    }

    .item {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    .pointer {
      cursor: pointer;

      &:hover {
        color: $f-primary-black;
      }

      &:active {
        color: $f-primary-black;
      }
    }

    &.firstSection {
      align-items: baseline;
      padding: 40px 0;

      .logo {
        display: block;
        width: 120px;
        margin-right: auto;
      }

      ul {
        min-width: 160px;

        li {
          margin-bottom: 8px;
          font-size: 14px;

          &.title {
            @include font_v2('ko', 14px, 700);
            margin-bottom: 12px;
            color: $f-primary-black;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .snsBtn {
            cursor: pointer;
            margin-right: 16px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.secondSection {
      .copyright {
        @include font_v2('en', 14px, 400);

        margin-right: auto;
        color: $f-primary-black;
      }
    }

    &.thirdSection {
      .dot {
        width: 2px;
        position: relative;
        display: inline-block;
        margin: 0 8px;
        &:before {
          content: '';
          width: 2px;
          height: 2px;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          border-radius: 50%;
          background-color: $f-gray-45;
        }
      }
      .address {
        display: flex;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &.responsive {
    min-width: 335px;
  }
}

@media (max-width: 768px) {
  .container {
    &.responsive {
      .section {
        display: block;
        padding: 16px 0;
        &.firstSection {
          align-items: baseline;
          padding: 40px 0;
          .logo {
            width: 122px;
            margin: 0 0 40px;
          }
          ul {
            margin: 0 0 24px;
            &:last-child {
              margin: 0;
            }
          }
        }

        &.secondSection {
          display: flex;
          .item {
            margin: 0 24px 0 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }

        &.thirdSection {
          display: flex;
          flex-wrap: wrap;
          .address {
            width: 100%;
            > span {
              &:first-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
