<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="caret-icon"
      :class="[`icon-caret--direction--${direction}`]"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.92186 7.23178C5.44211 7.63157 5.44211 8.36843 5.92187 8.76822L9.35982 11.6332C10.0111 12.176 11 11.7128 11 10.865L11 5.13504C11 4.2872 10.0111 3.82405 9.35982 4.36682L5.92186 7.23178Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconCaret',
  props: {
    direction: {
      type: String as PropType<'up' | 'down' | 'left' | 'right'>,
      default: 'left',
    },
    color: {
      type: String,
      default: colors['f-gray-75'],
    },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
  methods: {},
})
</script>
<style scoped>
.caret-icon {
  transform-origin: 50% 50%;
}

.icon-caret--direction--up {
  transform: rotate(90deg);
}

.icon-caret--direction--right {
  transform: rotate(180deg);
}

.icon-caret--direction--down {
  transform: rotate(270deg);
}

.icon-caret--direction--left {
  transform: rotate(360deg);
}
</style>
