import clipboardy from 'clipboardy'

import { ApiBaseResponse } from '@/interfaces/api'

interface IClipboardResult extends Required<ApiBaseResponse> {}

export async function clipboard(
  text: string,
): Promise<Pick<IClipboardResult, 'success' | 'message'>> {
  try {
    await clipboardy.write(text)

    return {
      success: true,
      message: '복사되었습니다.',
    }
  } catch {
    return {
      success: false,
      message: '클립보드 기능을 사용할 수 없습니다.',
    }
  }
}
