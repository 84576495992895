import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["icon-button-delete", {
      'icon-button-delete--hover': _ctx.hover,
    }]),
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("circle", {
      cx: "12",
      cy: "12",
      r: "9",
      fill: _ctx.circleColor
    }, null, 8, _hoisted_2),
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9.13807 8.19526C8.87772 7.93491 8.45561 7.93491 8.19526 8.19526C7.93491 8.45561 7.93491 8.87772 8.19526 9.13807L11.0573 12.0001L8.19548 14.8619C7.93513 15.1223 7.93513 15.5444 8.19548 15.8047C8.45583 16.0651 8.87794 16.0651 9.13829 15.8047L12.0001 12.9429L14.8619 15.8047C15.1223 16.0651 15.5444 16.0651 15.8047 15.8047C16.0651 15.5444 16.0651 15.1223 15.8047 14.8619L12.9429 12.0001L15.805 9.13807C16.0653 8.87772 16.0653 8.45561 15.805 8.19526C15.5446 7.93491 15.1225 7.93491 14.8621 8.19526L12.0001 11.0573L9.13807 8.19526Z",
      fill: "white"
    }, null, -1))
  ], 10, _hoisted_1))
}