import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_AlertModal = _resolveComponent("AlertModal")!

  return (_ctx.cancellable)
    ? (_openBlock(), _createBlock(_component_ConfirmModal, {
        key: 0,
        isShow: _ctx.state.isShow,
        title: _ctx.title,
        message: _ctx.message,
        confirmButtonText: _ctx.confirmButtonText,
        cancelButtonText: _ctx.cancelButtonText,
        onConfirm: _ctx.onConfirmEvent,
        onCancel: _ctx.onCancelEvent
      }, null, 8, ["isShow", "title", "message", "confirmButtonText", "cancelButtonText", "onConfirm", "onCancel"]))
    : (_openBlock(), _createBlock(_component_AlertModal, {
        key: 1,
        isShow: _ctx.state.isShow,
        title: _ctx.title,
        message: _ctx.message,
        buttonText: _ctx.confirmButtonText,
        onConfirm: _ctx.onConfirmEvent
      }, null, 8, ["isShow", "title", "message", "buttonText", "onConfirm"]))
}