import { defineStore } from 'pinia'

import { GLOBAL_LOADING } from '@/store/keys'

export const useGlobalLoadingStore = defineStore(GLOBAL_LOADING, {
  state: () => ({
    count: 0,
  }),
  getters: {
    isLoading({ count }) {
      return count > 0
    },
  },
  actions: {
    start() {
      this.count++
    },
    finish(delayMs = 0) {
      if (this.count) {
        if (!delayMs) {
          this.count--
        } else {
          setTimeout(() => {
            this.count--
          }, delayMs)
        }
      }
    },
    finishAll(delayMs = 0) {
      if (!delayMs) {
        this.count = 0
      } else {
        setTimeout(() => {
          this.count = 0
        }, delayMs)
      }
    },
    async wrap<T>(
      cb: () => T,
      opt: {
        enabled?: boolean
        delayMs?: number
      } = {},
    ): Promise<T> {
      opt.enabled ??= true
      opt.delayMs ??= 500

      try {
        if (opt.enabled) {
          this.start()
        }
        return await cb()
      } finally {
        if (opt.enabled) {
          this.finish(opt.delayMs)
        }
      }
    },
  },
})
