import { Plugin } from 'vue'

import colors from '@/assets/scss/base/colors.module.scss'

export const colorPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.$colors = colors
    app.config.globalProperties.$colorsV2 = colors
  },
}

export { colors }

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $colors: typeof colors
    $colorsV2: typeof colors
  }
}
