import { datadogLogs as logs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { checkIgnoreError } from '@pkg/shared/dist/error-tracking/ignore-error'
import { Plugin } from 'vue'

import { version as PKG_VERSION } from '../../package.json'

import { GIT_REF, IS_SERVE, MODE, NODE_ENV } from '@/env'

export const datadog = datadogRum
export const datadogLogs = logs

export const datadogPlugin: Plugin = {
  install: (app) => {
    const isEnabled = (() => {
      switch (MODE) {
        case 'production':
        case 'staging':
          return !IS_SERVE
        default:
          return false
      }
    })()

    app.config.globalProperties.datadog = datadog
    app.config.globalProperties.datadogLogs = logs

    if (isEnabled) {
      const clientToken = 'pube230aa744d849f4b1ef465085cab9b5c'
      const version = `web@${PKG_VERSION}#${GIT_REF}`

      datadog.init({
        applicationId: 'edeaeb0e-a20f-4b9a-ba9b-baca4373ba87',
        clientToken,
        site: 'datadoghq.com',
        service: 'fastfive',
        env: getDatadogEnv(),
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
          /https:\/\/.*\.slowfive\.com/,
          /https:\/\/.*\.fastfive\.co.kr/,
        ],
        version,
        beforeSend(event) {
          if (event.type === 'error' && checkIgnoreError(event.error)) {
            return false
          }

          return true
        },
      })

      datadogLogs.init({
        clientToken,
        site: 'datadoghq.com',
        service: 'fastfive',
        env: getDatadogEnv(),
        telemetrySampleRate: 100,
        version,
      })

      datadog.setGlobalContextProperty('node_env', NODE_ENV)
      datadogLogs.setGlobalContextProperty('node_env', NODE_ENV)

      datadog.setGlobalContextProperty('repo', 'cms-user-webapp')
      datadogLogs.setGlobalContextProperty('repo', 'cms-user-webapp')

      datadog.startSessionReplayRecording()
    }
  },
}

export const getDatadogEnv = (mode = MODE) => {
  switch (mode) {
    case 'development':
      return 'dev'

    case 'staging':
      return 'staging'

    default:
      return 'product'
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    datadog: typeof datadog
    datadogLogs: typeof logs
  }
}
