<template>
  <div
    class="ff-scroller"
    :class="{
      [`scroller--type--${scrollerType}`]: true,
    }"
    :style="cssVars"
  >
    <div
      class="scroller--container"
      @click="onClick"
    >
      <component
        class="scroller--icon"
        v-bind="iconComponent.attr"
        :is="iconComponent.is"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

import IcnChevron from '@/components/svg/icon/IcnChevron.vue'

import colors from '@/assets/scss/base/colors.module.scss'
import {
  ScrollerDirection,
  ScrollerType,
} from '@/interfaces/components/common/slider/scroller'
import { withPx } from '@/libs/css'

export default defineComponent({
  name: 'Scroller',
  components: {
    IcnChevron,
  },
  props: {
    size: {
      type: Number,
      default: 36,
    },
    type: {
      type: String as PropType<ScrollerType>,
      default: 'background-blur',
    },
    direction: {
      type: String as PropType<ScrollerDirection>,
      default: 'left',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const scrollerType = computed(() => {
      const defaultType = 'background-blur'

      const knownTypes = [
        'background-blur',
        'dropshadow',
        'transparant',
      ] as ScrollerType[]

      return knownTypes.includes(props.type) ? props.type : defaultType
    })

    const iconSize = computed(() => props.size / 1.5)

    const cssVars = computed(() => {
      return {
        '--scroller-size': withPx(props.size) || null,
        '--scroller-icon-size': withPx(iconSize.value) || null,
      }
    })

    const iconComponent = computed(() => {
      const icon = {
        is: 'IcnChevron',
        attr: {
          width: iconSize.value,
          height: iconSize.value,
          direction: props.direction,
          color: colors['white'],
        },
      }

      switch (props.type) {
        case 'dropshadow':
          icon.attr.color = colors['primary-black']
          break
        case 'transparant':
          icon.attr.color = colors['primary-black']
          break
      }

      return icon
    })

    function onClick() {
      emit('click')
    }

    return {
      scrollerType,
      iconSize,
      cssVars,
      iconComponent,
      onClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.ff-scroller {
  --scroller-size: 36px;
  --scroller-icon-size: 24px;

  display: inline-block;

  .scroller--container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--scroller-size);
    height: var(--scroller-size);
    border-radius: 50%;
    cursor: pointer;

    .scroller--icon {
      width: var(--scroller-icon-size);
      height: var(--scroller-icon-size);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &.scroller--type--background-blur {
    .scroller--container {
      background-color: rgba($primary-black, 0.3);
      backdrop-filter: blur(8px);
    }
  }

  &.scroller--type--dropshadow {
    .scroller--container {
      background-color: $white;
      box-shadow: 0px 0px 8px rgba($black, 0.04);
    }
  }

  &.scroller--type--transparant {
    .scroller--container {
      background-color: transparent;
    }
  }
}
</style>
