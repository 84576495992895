import { Plugin } from 'vue'

import { clipboard } from '@/libs/clipboard'

export const clipboardPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.$clipboard = clipboard
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $clipboard: typeof clipboard
  }
}
