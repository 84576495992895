import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M6.02812 2.94437C6.18841 2.78386 6.4058 2.69369 6.63248 2.69369H9.36752C9.5942 2.69369 9.8116 2.78386 9.97189 2.94437C10.1322 3.10487 10.2222 3.32256 10.2222 3.54955V4.40542H5.77778V3.54955C5.77778 3.32256 5.86783 3.10487 6.02812 2.94437ZM4.75214 4.40542V3.54955C4.75214 3.05018 4.95025 2.57126 5.30288 2.21815C5.65551 1.86504 6.13378 1.66667 6.63248 1.66667H9.36752C9.86622 1.66667 10.3445 1.86504 10.6971 2.21815C11.0498 2.57126 11.2479 3.05018 11.2479 3.54955V4.40542H12.7863C13.8248 4.40542 14.6667 5.24842 14.6667 6.2883V12.4505C14.6667 13.4904 13.8248 14.3333 12.7863 14.3333H3.21369C2.1752 14.3333 1.33334 13.4904 1.33334 12.4505V6.2883C1.33334 5.24842 2.1752 4.40542 3.21369 4.40542H4.75214ZM7.99999 7.82878C8.28322 7.82878 8.51281 8.05716 8.51281 8.33889V8.34569C8.51281 8.62742 8.28322 8.8558 7.99999 8.8558C7.71677 8.8558 7.48717 8.62742 7.48717 8.34569V8.33889C7.48717 8.05716 7.71677 7.82878 7.99999 7.82878ZM3.42621 8.67569C3.2324 8.55816 2.98014 8.6202 2.86276 8.81426C2.74538 9.00833 2.80734 9.26093 3.00114 9.37847C4.54689 10.3159 6.25925 10.8073 8 10.8073C9.74075 10.8073 11.4531 10.3159 12.9989 9.37847C13.1927 9.26093 13.2546 9.00833 13.1372 8.81426C13.0199 8.6202 12.7676 8.55816 12.5738 8.67569C11.1503 9.53898 9.58412 9.98564 8 9.98564C6.41588 9.98564 4.84967 9.53898 3.42621 8.67569Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}