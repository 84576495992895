import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Suspense as _Suspense } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AccountPopup = _resolveComponent("AccountPopup")!
  const _component_SelectBox = _resolveComponent("SelectBox")!
  const _component_InputComponent = _resolveComponent("InputComponent")!
  const _component_button_component = _resolveComponent("button-component")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_invitation_container = _resolveComponent("invitation-container")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_router_link, {
      to: { name: _ctx.hasProfile ? 'Home' : 'SignIn' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.logo),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:logo')))
        }, [
          _createElementVNode("img", {
            src: _ctx.images.logo,
            alt: "logo"
          }, null, 8, _hoisted_1)
        ], 2)
      ]),
      _: 1
    }, 8, ["to"]),
    (!_ctx.noMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.headerMenu)
        }, [
          (_ctx.hasProfile && _ctx.sessionStore.isLogin)
            ? (_openBlock(), _createBlock(_component_SelectBox, {
                key: 0,
                open: _ctx.state.isShowProfile,
                "onUpdate:open": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.isShowProfile) = $event)),
                  _ctx.onProfileMenuClick
                ],
                class: _normalizeClass(_ctx.$style.item),
                popupPosition: "right"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.$style.label, _ctx.$style.ellipsis])
                  }, _toDisplayString(_ctx.memberGroup ? _ctx.memberGroup.name : '소속된 그룹이 없습니다'), 3)
                ]),
                popup: _withCtx(({ onInsideClick }) => [
                  _createVNode(_component_AccountPopup, {
                    profileThumbnail: _ctx.user?.imageUrl,
                    username: _ctx.user?.name,
                    occupation: _ctx.user?.occupation,
                    email: _ctx.user?.email,
                    memberGroups: _ctx.memberGroups,
                    selectedMemberGroupId: _ctx.selectedMemberGroupId,
                    onClick: onInsideClick,
                    "onClick:logout": _ctx.onLogoutClick,
                    "onClick:myPage": _ctx.onMyPageClick,
                    "onClick:resetPassword": _ctx.onResetPasswordClick,
                    "onChange:memberGroup": _ctx.onMemberGroupChange,
                    "onClick:invite": _ctx.onInvitationClick
                  }, null, 8, ["profileThumbnail", "username", "occupation", "email", "memberGroups", "selectedMemberGroupId", "onClick", "onClick:logout", "onClick:myPage", "onClick:resetPassword", "onChange:memberGroup", "onClick:invite"])
                ]),
                _: 1
              }, 8, ["open", "class", "onUpdate:open"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      isShow: _ctx.state.isShowInviteModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.invitation)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.invitationCard)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.title)
            }, "초대 링크 입력", 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.description)
            }, " 관리 멤버에게 받은 초대 링크를 복사하여 아래 입력해 주세요. ", 2),
            _createVNode(_component_InputComponent, {
              modelValue: _ctx.state.invitationCode,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.invitationCode) = $event)),
              class: _normalizeClass(_ctx.$style.inputInvitationCode),
              placeholder: "https://fastfive.app.link/invitation?code=abcde23456",
              onValidate: _cache[3] || (_cache[3] = (vaild) => (_ctx.state.validInvitationCode = vaild)),
              validator: _ctx.invitationCodeValidator
            }, null, 8, ["modelValue", "class", "validator"]),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.buttonQuestionInvitationCode),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.moveFaqInvitationCode && _ctx.moveFaqInvitationCode(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.images.iconQuestion,
                alt: ""
              }, null, 8, _hoisted_2),
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "유효하지 않은 초대링크라고 뜨나요?", -1))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.buttonArea)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.buttonBottom)
              }, [
                _createVNode(_component_button_component, {
                  text: "취소",
                  type: "outline",
                  onSubmit: _ctx.onInvitationCancel,
                  fontSize: 15,
                  height: 39
                }, null, 8, ["onSubmit"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.buttonBottom)
              }, [
                _createVNode(_component_button_component, {
                  text: "다음",
                  "is-disabled": !_ctx.state.validInvitationCode,
                  onSubmit: _ctx.onInvitationAccept,
                  fontSize: 15,
                  height: 39
                }, null, 8, ["is-disabled", "onSubmit"])
              ], 2)
            ], 2)
          ], 2)
        ], 2)
      ]),
      _: 1
    }, 8, ["isShow"]),
    (_ctx.state.isShowJoinGroupModal)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          "is-show": _ctx.state.isShowJoinGroupModal
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _createVNode(_component_invitation_container, {
                  "invitation-code": _ctx.state.invitationCode,
                  rejectedModalTitle: "소속/회사 정보가 다르군요!",
                  onComplete: _ctx.onJoinGroupComplete,
                  onInvalid: _cache[5] || (_cache[5] = 
            () => {
              _ctx.state.isShowJoinGroupModal = false
              _ctx.state.isShowInviteModal = true
            }
          )
                }, null, 8, ["invitation-code", "onComplete"])
              ]),
              _: 1
            }))
          ]),
          _: 1
        }, 8, ["is-show"]))
      : _createCommentVNode("", true)
  ], 2))
}