<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.focused]: state.isFocused,
      [$style.clickable]: clickable,
      [$style.readonly]: readonly,
      [$style.hasBorder]: border,
    }"
    @click="(event) => clickable && $emit('click', event)"
  >
    <div
      :class="$style.box"
      :style="boxInnerStyles"
    >
      <!-- input 이벤트 발생했을때 값을 바로 변경하기위해 v-model 사용 안함 -->
      <input
        :value="state.value"
        ref="inputEl"
        type="text"
        :placeholder="placeholder"
        :readonly="readonly"
        :maxlength="maxlength"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keypress.enter="onSearch"
        @keypress="onKeyPress"
      />

      <div
        :class="$style.btn"
        @click.stop="onSearch"
      >
        <IcnSearch />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue'

import IcnSearch from '@/components/svg/icon/IcnSearch.vue'

export default defineComponent({
  name: 'SearchBar',
  components: {
    IcnSearch,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 0,
    },
    boxInnerStyles: null,
    border: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: null,
    input: null,
    keypress: null,
    'update:modelValue': (value: string) => typeof value === 'string',
    search: (value: string) => typeof value === 'string',
  },
  setup(props, { emit }) {
    const inputEl = ref<HTMLInputElement>()

    const state = reactive({
      value: '',
      isFocused: false,
    })

    watch(
      () => props.modelValue,
      () => {
        state.value = props.modelValue
      },
    )

    onBeforeMount(() => {
      state.value = props.modelValue
    })

    function focus() {
      inputEl.value?.focus()
    }

    function onFocus(event) {
      if (props.readonly) {
        event.preventDefault()
        return
      }

      state.isFocused = true
    }

    function onBlur() {
      state.isFocused = false
    }

    function onInput(event) {
      state.value = event?.target?.value || ''

      emit('input', event)
      emit('update:modelValue', state.value)
    }

    function onSearch(event: Event) {
      if (props.readonly) {
        if (props.clickable && event?.type === 'click') {
          emit('click', event)
        }
        return
      }

      emit('search', state.value)
    }

    function onKeyPress(event: Event) {
      const el = event.target as HTMLInputElement

      emit('keypress', event)

      if (props.maxlength && el.value.length > props.maxlength) {
        event.preventDefault()
      }
    }

    return {
      inputEl,
      state,

      // methods
      focus,
      onFocus,
      onBlur,
      onInput,
      onSearch,
      onKeyPress,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  .box {
    display: flex;
    padding: calc(10px - 2px) calc(16px - 2px);
    border-radius: 6px;
    background: $white;
    border: 1px solid $white;

    input {
      flex: 1;
      border: 0;
      padding: 0;
      outline: none;
      background: inherit;
      color: $primary-black;
      width: 100%;

      @include font_ko(15px, 400);

      &::placeholder {
        color: rgba($color: $primary-black, $alpha: 0.25);
      }
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &.focused {
    .box {
      background: $grey-07;
    }
  }

  &.readonly {
    .box {
      .btn {
        cursor: default;
      }
    }
  }

  &.hasBorder {
    .box {
      border-color: $f-gray-25;
    }
  }

  &.clickable {
    .box {
      cursor: pointer;

      input {
        cursor: inherit;
      }

      .btn {
        cursor: inherit;
      }
    }
  }
}
</style>
