import { defineStore } from 'pinia'

import { IS_SERVE } from '@/env'
import { NETWORK_ERROR } from '@/store/keys'

interface NetworkErrorState {
  error: any
}

export const useNetworkError = defineStore(NETWORK_ERROR, {
  state: (): NetworkErrorState => ({
    error: null,
  }),
  getters: {
    state(state) {
      return state
    },
  },
  actions: {
    throwError(error: unknown) {
      if (IS_SERVE) {
        console.error(error)
      }

      this.error = error
    },
    clear() {
      this.error = null
    },
  },
})
