import RemoteStore, {
  AppConfigCollection,
  AppConfigSchema,
} from '@fastfive-dev/fastfive-remote-store-sdk'
import { MS } from '@pkg/shared/dist/time/constants'
import { defineStore } from 'pinia'

import { version as PKG_VERSION } from '../../../package.json'

import { IS_SERVE, IS_TEST, MODE } from '@/env'
import { fromKST } from '@/libs/date'
import { DATE_OF_IGNORING_UPDATE } from '@/libs/storage/local-keys'
import { DATE_OF_TRYED_UPDATE } from '@/libs/storage/session-keys'
import { getDatadogEnv } from '@/plugins/datadog'
import { firebaseConfig } from '@/plugins/firebase'
import { forceReload } from '@/router/helper'
import { APP_CONFIG } from '@/store/keys'
import { useGlobalLoadingStore } from '@/store/modules/global-loading'
import { useGlobalModalStore } from '@/store/modules/global-modal'
import { useSessionStore } from '@/store/modules/session'

const isEnabled = (() => {
  if (/yeti/i.test(navigator.userAgent)) return false
  if (IS_SERVE) return false
  if (IS_TEST) return false
  if (MODE === 'development') return false

  return true
})()

let remoteStore: RemoteStore | null

export const useAppConfigStore = defineStore(APP_CONFIG, {
  state: () => ({
    connection: null as null | { close: () => void },
    lastData: null as AppConfigSchema | null,
    /**
     * 현재 점검 중인지
     */
    inProgressSystemMaintenance: false,
    schedulerId: 0 as ReturnType<typeof setInterval> | 0,
    updateInfo: AppConfigCollection.UpdateType.NONE,
  }),
  getters: {
    /**
     * 점검 중 백도어를 허용할지
     */
    isAllowedBackdoorDuringSystemMaintenance(state): boolean {
      return state.lastData?.['system_maintenance:allowed_backdoor'] ?? false
    },
    /**
     * 점검 사유
     */
    systemMaintenanceReason(state): string {
      return (
        state.lastData?.['system_maintenance:reason'] ??
        '원활한 서비스 제공을 위하여 시스템 점검 진행중입니다.\n점검중에는 서비스 접속이 되지 않으니 양해 부탁 드립니다.\n이용에 불편을 드려 대단히 죄송합니다.'
      )
    },
  },
  actions: {
    next(payload: AppConfigSchema | null = null) {
      this.lastData = payload
    },

    enableScheduler() {
      this.disableScheduler()
      this.schedulerId = setInterval(async () => {
        await this.checkSystemMaintenance()
      }, MS['1_MIN'])
    },

    disableScheduler() {
      clearInterval(this.schedulerId)
    },

    async connect<T extends () => any>(cb: T): Promise<ReturnType<T>> {
      if (!isEnabled) {
        return cb()
      }

      await new Promise<void>((resolve) => {
        let isResolved = false

        const exit = () => {
          if (!isResolved) {
            resolve()
          }

          isResolved = true
        }

        if (this.connection) {
          return exit()
        }

        try {
          remoteStore ??= new RemoteStore({
            firebaseOptions: firebaseConfig,
            // firestoreEmulator: {
            //   host: '127.0.0.1',
            //   port: 8080,
            // },
          })

          const { unsubscribeAppConfig } = remoteStore.subscribeAppConfig(
            {
              platform: 'web',
              env: getDatadogEnv(),
              version: PKG_VERSION,
            },
            (changedData) => {
              this.next(changedData)
              exit()
            },
          )

          this.connection = {
            close: () => {
              unsubscribeAppConfig()
              this.connection = null
            },
          }

          /**
           * 연결이 오래 걸릴 경우를 위한 방어 코드
           */
          setTimeout(() => {
            exit()
          }, 2000)
        } catch {
          this.connection?.close()
          exit()
        }
      })

      return cb()
    },

    checkUpdate() {
      return this.connect(() => {
        this.updateInfo = AppConfigCollection.checkUpdate(
          this.lastData,
          PKG_VERSION,
        )

        return this.updateInfo
      })
    },

    showUpdateModal() {
      const today = fromKST().format('YYYY-MM-DD')
      const globalModalStore = useGlobalModalStore()

      if (sessionStorage.getItem(DATE_OF_TRYED_UPDATE)) {
        return
      }

      switch (this.updateInfo) {
        case AppConfigCollection.UpdateType.REQUIRED: {
          globalModalStore.open({
            message:
              '번거로우시겠지만 정상적인 서비스 이용을 위해 필수로 업데이트 진행 부탁드립니다.',
            cancellable: false,
            confirmButtonText: '확인',
            onConfirm() {
              sessionStorage.setItem(DATE_OF_TRYED_UPDATE, today)
              forceReload()
            },
          })
          return
        }

        case AppConfigCollection.UpdateType.OPTIONAL: {
          if (localStorage.getItem(DATE_OF_IGNORING_UPDATE) === today) {
            return
          }

          globalModalStore.open({
            message:
              '보다 편리하고 즐거운 서비스 사용을 위해 업데이트 부탁드려요.',
            cancellable: true,
            confirmButtonText: '예',
            cancelButtonText: '아니요',
            onConfirm() {
              sessionStorage.setItem(DATE_OF_TRYED_UPDATE, today)
              forceReload()
            },
            onCancel(close) {
              localStorage.setItem(DATE_OF_IGNORING_UPDATE, today)
              close?.()
            },
          })
          return
        }
      }
    },

    checkSystemMaintenance() {
      return this.connect(() => {
        const sessionStore = useSessionStore()
        const globalLoadingStore = useGlobalLoadingStore()

        const prevInProgressSystemMaintenance = this.inProgressSystemMaintenance

        if (
          this.isAllowedBackdoorDuringSystemMaintenance &&
          (!sessionStore.user || sessionStore.isInsider)
        ) {
          this.inProgressSystemMaintenance = false
        } else {
          this.inProgressSystemMaintenance =
            AppConfigCollection.checkSystemMaintenance(this.lastData)
        }

        if (
          prevInProgressSystemMaintenance &&
          !this.inProgressSystemMaintenance
        ) {
          sessionStore.throttledInitializeSession()
        }

        switch (true) {
          // 점검 모드가 풀릴 때
          case prevInProgressSystemMaintenance &&
            !this.inProgressSystemMaintenance: {
            sessionStore.throttledInitializeSession()
            break
          }

          // 점검 모드로 전환될 때
          case !prevInProgressSystemMaintenance &&
            this.inProgressSystemMaintenance: {
            globalLoadingStore.finishAll(1000)
            break
          }
        }

        return this.inProgressSystemMaintenance
      })
    },
  },
})
