import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { OccupationListApiResponse } from '@/interfaces/api/occupations'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createOccupationService = (client: AxiosCacheInstance) => {
  return {
    async getList() {
      return await axiosHandler<OccupationListApiResponse>(() => {
        return client.get(`/mobile/occupations`)
      })
    },
  }
}
