import { REDIRECT_URL } from '@/libs/storage/session-keys'
import { parseAppLink } from '@/plugins/applink'
import router from '@/router'

export const setRedirectUrl = (redirectUrl: string) => {
  if (!redirectUrl) {
    return
  }

  if (redirectUrl.startsWith(window.location.origin)) {
    const [pathname, param = ''] = redirectUrl
      .replace(window.location.origin, '')
      .split('?')
    const [search] = param.split('#')
    const appLinkRoute = parseAppLink(search).route

    if (appLinkRoute) {
      redirectUrl = router.resolve(appLinkRoute).href
    }
  }

  sessionStorage.setItem(REDIRECT_URL, redirectUrl)
}

export const getRedirectUrl = (keep = false) => {
  const redirectUrl = sessionStorage.getItem(REDIRECT_URL)

  if (!keep) {
    removeRedirectUrl()
  }

  return redirectUrl
}

export const removeRedirectUrl = () => {
  sessionStorage.removeItem(REDIRECT_URL)
}
