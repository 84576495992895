"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIgnoreError = exports.ignoreErrorMessages = exports.ignoreErrorNames = void 0;
exports.ignoreErrorNames = [
    /ChunkLoadError/,
    /IndexedDbTransactionError/,
    //
];
exports.ignoreErrorMessages = [
    /Unexpected token '<'/,
    /ResizeObserver loop limit exceeded/,
    /ResizeObserver loop completed with undelivered notifications./,
    /Request failed with status code 4[0-9]{2}/,
    /Loading chunk [0-9]+ failed/,
    /Loading CSS chunk [0-9]+ failed/,
    /Network Error/,
    /Installations: Could not process request\. Application offline\. \(installations\/app-offline\)\./,
    /FIRESTORE \(.+\) INTERNAL ASSERTION FAILED: Unexpected state/,
    /null is not an object \(evaluating 'localStorage\.setItem'\)/,
    /Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing\./,
    //
];
function checkIgnoreError(error) {
    return (((error === null || error === void 0 ? void 0 : error.name) && exports.ignoreErrorNames.some((reg) => reg.test(error.name))) ||
        ((error === null || error === void 0 ? void 0 : error.message) &&
            exports.ignoreErrorMessages.some((reg) => reg.test(error.message))));
}
exports.checkIgnoreError = checkIgnoreError;
