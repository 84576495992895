import _ from 'lodash'

export const buildField = (field) => {
  if (_.isString(field)) {
    return field
  }

  if (_.isArray(field)) {
    return _.join(
      _.map(field, (subField) => buildField(subField)),
      ',',
    )
  }

  if (_.isObject(field as any)) {
    const { name, fields } = field
    return `${name}(${buildField(fields)})`
  }
}
