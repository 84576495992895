import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.$style.container,
      {
        [_ctx.$style[`lang--${_ctx.lang}`]]: true,
        [_ctx.$style[`size--small`]]: _ctx.size === 'small',
        [_ctx.$style[`size--large`]]: _ctx.size === 'large',
        [_ctx.$style.clickable]: _ctx.isClickable,
      },
    ]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onContainerClick && _ctx.onContainerClick(...args))),
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", { palette: _ctx.palette })
  ], 34))
}