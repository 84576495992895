import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["icon-chevron", [`icon-chevron--direction--${_ctx.direction}`]]),
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_openBlock(), _createElementBlock("svg", {
      width: _ctx.width,
      height: _ctx.height,
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M5.22781 8.23431C5.53155 7.92189 6.02401 7.92189 6.32775 8.23431L12 14.0686L17.6723 8.23431C17.976 7.92189 18.4685 7.92189 18.7722 8.23431C19.0759 8.54673 19.0759 9.05326 18.7722 9.36568L12.55 15.7657C12.2462 16.0781 11.7538 16.0781 11.45 15.7657L5.22781 9.36568C4.92406 9.05327 4.92406 8.54673 5.22781 8.23431Z",
        fill: _ctx.color
      }, null, 8, _hoisted_2)
    ], 8, _hoisted_1))
  ], 6))
}