import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { BannerGetListApiResponse } from '@/interfaces/api/banners'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createBannerService = (client: AxiosCacheInstance) => {
  return {
    async getList() {
      return await axiosHandler<BannerGetListApiResponse>(() => {
        return client.get(`/mobile/banners`)
      })
    },
  }
}
