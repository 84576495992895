import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.containerClasses),
    type: _ctx.type,
    disabled: _ctx.disabled,
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
    onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
    onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args))),
    onClick: _cache[4] || (_cache[4] = (ev) => _ctx.onClick(ev))
  }, [
    _renderSlot(_ctx.$slots, "default", {
      isHover: _ctx.state.isHover,
      isPress: _ctx.state.isPress,
      isDisabled: _ctx.disabled,
      cssVars: _ctx.cssVars
    })
  ], 42, _hoisted_1))
}