import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ApiBaseResponse } from '@/interfaces/api'
import {
  AuthSignUpApiPayload,
  AuthSignUpApiResponse,
} from '@/interfaces/api/auth'
import { AuthEmailExistApiResponse } from '@/interfaces/api/auth/email'
import { Phone } from '@/interfaces/api/auth/phone'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createService = (client: AxiosCacheInstance) => {
  return {
    async signIn({ application, email, password }) {
      return client.post('/auth/signin', {
        application,
        email,
        password,
      })
    },
    async signOut() {
      return client.patch('/auth/signout')
    },
    async emailAuthentication(email) {
      return client.post('/auth/emailAuthentication', {
        email,
      })
    },
    async codeAuthentication(email, authenticationCode) {
      return client.post('/auth/codeAuthentication', {
        email,
        authenticationCode,
      })
    },
    async signUp(payload: AuthSignUpApiPayload) {
      return await axiosHandler<AuthSignUpApiResponse>(() => {
        return client.post(`/auth/signup`, {
          ...payload,
          application: payload?.application ?? 'web',
        })
      })
    },
    async resetPassword(
      email,
      contactNo,
      authenticationCode,
      password,
      application,
    ) {
      return client.patch('/auth/resetPassword', {
        email,
        contactNo,
        authenticationCode,
        password,
        application,
      })
    },

    async emailExist(email: string) {
      return await axiosHandler<AuthEmailExistApiResponse>(() => {
        return client.get(`/auth/email/${email}/existence`)
      })
    },

    async smsAuthentication(phone: string) {
      return await axiosHandler<ApiBaseResponse>(() => {
        return client.post(`/auth/sms`, {
          contactNo: phone,
        })
      })
    },

    async userSmsAuthentication(phone: string) {
      return await axiosHandler<ApiBaseResponse>(() => {
        return client.post(`/mobile/user/sms`, {
          contactNo: phone,
        })
      })
    },
    async getEmailExistenceList(phone: string) {
      return await axiosHandler<Phone.API.GetEmailExistenceList.Response>(
        () => {
          return client.get(`/auth/emails?contactNo=${phone}`)
        },
      )
    },
    async checkExistenceUser(phone: string, email: string) {
      return await axiosHandler<AuthEmailExistApiResponse>(() => {
        return client.get(`/auth/user/existence`, {
          params: {
            contactNo: phone,
            email: email,
          },
        })
      })
    },

    async verificationAuthenticationCode(
      payload: Phone.API.VerificationAuthenticationCode.Request,
    ) {
      return await axiosHandler<ApiBaseResponse>(() => {
        return client.post(`/auth/sms/verification`, payload)
      })
    },

    async userVerificationAuthenticationCode(
      payload: Phone.API.VerificationAuthenticationCode.Request,
    ) {
      return await axiosHandler<ApiBaseResponse>(() => {
        return client.post(`/mobile/user/sms/verification`, payload)
      })
    },
  }
}
