import { Plugin } from 'vue'

import { $texts } from '@/libs/texts'

export const textsPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.$texts = $texts
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $texts: typeof $texts
  }
}
