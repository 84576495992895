<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32555_65225)">
      <rect
        x="2"
        y="4"
        width="20"
        height="18"
        rx="3"
        fill="#FEE2D5"
      />
      <path
        d="M12 10V17"
        stroke="#FC6E2E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8.97266 11.75L15.0348 15.25"
        stroke="#FC6E2E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8.96875 15.25L15.0309 11.75"
        stroke="#FC6E2E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7 3V4.5"
        stroke="#FEE2D5"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M17 3V4.5"
        stroke="#FEE2D5"
        stroke-width="2"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_32555_65225">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconChristmasReservation',
  props: {
    color: {
      type: String,
      default: null,
    },
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
