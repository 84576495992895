<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 18"
    :fill="bgColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5792 7.26222V6.00312H24.6414V4H25.9377V6.00312H28V7.26222H25.9377V9.26534H24.6414V7.26222H22.5792ZM7.03322 5.75869H8.56518V13.8856H7.03322V5.75869ZM1.59088 7.22383V9.27273H2.88716C3.55886 9.27273 3.85347 9.13538 4.04202 8.91789C4.207 8.73475 4.30127 8.50582 4.30127 8.24256C4.30127 7.97929 4.207 7.76181 4.04202 7.57867C3.85347 7.36118 3.55886 7.22383 2.88716 7.22383H1.59088ZM5.39721 9.6848C4.94941 10.2571 4.24235 10.6234 3.08749 10.6234H1.59088V13.8856H0V5.87315H3.08749C4.24235 5.87315 4.94941 6.23944 5.39721 6.81176C5.71539 7.21238 5.89216 7.70458 5.89216 8.24256C5.89216 8.78054 5.71539 9.28418 5.39721 9.6848ZM14.2297 8.33413V11.9626C14.0176 12.2259 13.5462 12.6494 12.8981 12.6494C12.6034 12.6494 12.2735 12.5578 12.0378 12.306C11.861 12.1114 11.755 11.8596 11.755 11.356V8.33413H10.223V11.2759C10.223 12.1 10.4233 12.6952 10.7887 13.1302C11.2247 13.6567 11.9317 14.0001 12.7095 14.0001C13.3341 14.0001 13.9115 13.7712 14.3475 13.3934V13.8856H15.7616V8.33413H14.2297ZM19.3051 14.0001C18.4919 14.0001 17.6553 13.7597 16.9718 13.1988L17.7142 12.2259C18.1031 12.5121 18.7041 12.741 19.2815 12.741C19.6939 12.741 20.0121 12.5464 20.0121 12.2717C20.0121 11.9323 19.5552 11.7798 19.0125 11.5988C18.2193 11.3341 17.2428 11.0084 17.2428 9.94807C17.2428 8.88356 18.3034 8.21966 19.4347 8.21966C20.1771 8.21966 20.9195 8.49438 21.3437 8.82632L20.6602 9.83361C20.3539 9.63902 19.965 9.47877 19.5525 9.47877C19.1283 9.47877 18.7748 9.60468 18.7748 9.89084C18.7748 10.1468 19.1594 10.2708 19.642 10.4264C20.4543 10.6882 21.5441 11.0396 21.5441 12.2602C21.5441 13.382 20.4246 14.0001 19.3051 14.0001Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors.module.scss'

export default defineComponent({
  name: 'ImgBadgePlus',
  props: {
    bgColor: {
      type: String,
      default: 'none',
    },
    color: {
      type: String,
      default: colors['white'],
    },
    width: {
      type: Number,
      default: 28,
    },
    height: {
      type: Number,
      default: 18,
    },
  },
})
</script>
