
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from 'vue'

import IconSearch from '@/components/v2/icon/IconSearch.vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

const __default__ = defineComponent({
  components: {
    IconSearch,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: Number,
    width: {
      type: String,
      default: '376px',
    },
    maxWidth: {
      type: String,
      default: '376px',
    },
    backgroundColor: {
      type: String,
      default: colors['f-gray-15'],
    },
  },
  emits: {
    focus: null,
    blur: null,
    input: null,
    keypress: null,
    'update:modelValue': (value: string) => typeof value === 'string',
    enter: (value: string) => typeof value === 'string',
  },
  setup(props, { emit }) {
    const inputEl = ref<HTMLInputElement>()

    const state = reactive({
      inputValue: '',
      isFocused: false,
    })

    const cssVars = computed(() => {
      return {
        width: props.width,
        maxWidth: props.maxWidth,
        backgroundColor: props.backgroundColor,
      }
    })

    watch(
      () => props.modelValue,
      () => {
        state.inputValue = props.modelValue
      },
    )

    onBeforeMount(() => {
      state.inputValue = props.modelValue
    })

    /**
     * @public
     */
    function focus() {
      inputEl.value?.focus()
    }

    function onInput(ev: Event) {
      const target = ev.target as HTMLInputElement | null

      state.inputValue = target?.value || ''

      emit('input', ev)
      emit('update:modelValue', state.inputValue)
    }

    function onFocus(ev: Event) {
      if (props.readonly || props.disabled) {
        ev.preventDefault()
        return
      }

      emit('focus', ev)

      state.isFocused = true
    }

    function onBlur(ev: Event) {
      emit('blur', ev)

      state.isFocused = false
    }

    function onEnter() {
      if (props.readonly || props.disabled) {
        return
      }

      emit('enter', state.inputValue)
    }

    function onKeyPress(ev: Event) {
      emit('keypress', ev)
    }

    return {
      inputEl,
      state,
      cssVars,
      focus,
      onInput,
      onFocus,
      onBlur,
      onEnter,
      onKeyPress,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6e5d1791": (_ctx.cssVars.backgroundColor),
  "70f5c982": (_ctx.cssVars.width),
  "95dd5cf4": (_ctx.cssVars.maxWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__