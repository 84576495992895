import { AxiosCacheInstance } from 'axios-cache-interceptor'

import {
  BranchGuideGetOneApiResponse,
  BranchGuideGetPictogramApiResponse,
} from '@/interfaces/api/branchs/guides'
import { axiosHandler } from '@/libs/api/axios-handler'
import { failResponse } from '@/libs/api/custom-responses'

export const createBranchGuidesService = (client: AxiosCacheInstance) => {
  return {
    /**
     * 지점 이용 가이드 조회
     */
    async getOne(branchId: number) {
      if (!branchId) {
        return failResponse<BranchGuideGetOneApiResponse>('잘못된 요청입니다.')
      }

      return await axiosHandler<BranchGuideGetOneApiResponse>(() => {
        return client.get(`/mobile/branches/${branchId}/guides`)
      })
    },

    async getPictogram(branchId: number) {
      if (!branchId) {
        return failResponse<BranchGuideGetPictogramApiResponse>(
          '잘못된 요청입니다.',
        )
      }

      return await axiosHandler<BranchGuideGetPictogramApiResponse>(() => {
        return client.get(`/mobile/branches/${branchId}/guides/pictogram`)
      })
    },
  }
}
