import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { WORKANYWHERE_ACCESS_KEY } from '@/env'
import { WorkAnywhere } from '@/interfaces/api/workAnywhere'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createWorkAnywhereService = (client: AxiosCacheInstance) => {
  return {
    async getToken() {
      return axiosHandler<WorkAnywhere.API.GetToken.Response>(() => {
        return client.post(`/auth/token/enterpass`, {
          accessKey: WORKANYWHERE_ACCESS_KEY,
          expiresIn: 3000000,
        })
      })
    },
    async getServiceField(
      payload: WorkAnywhere.API.GetInquiryField.Request,
      token: string,
    ) {
      return axiosHandler<WorkAnywhere.API.GetInquiryField.Response>(() => {
        return client.post(`/inquiries/categories`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      })
    },
    async createInquiry(
      payload: WorkAnywhere.API.CreateInquiry.Request,
      token: string,
    ) {
      return axiosHandler<WorkAnywhere.API.CreateInquiry.Response>(() => {
        return client.post(`/inquiries`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      })
    },
    async uploadFiles(form, token: string) {
      return axiosHandler<WorkAnywhere.API.UploadFiles.Response>(() => {
        return client.post(`/inquiries/file`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'attachment',
            Authorization: `Bearer ${token}`,
          },
        })
      })
    },
  }
}
