<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2172 4.24118C13.5222 3.61557 14.1336 3.23131 14.5283 3.03823C14.7327 2.93788 14.9519 3.04268 14.9993 3.26371C15.0916 3.68988 15.1685 4.40377 14.8641 5.02938C14.5591 5.65499 13.9477 6.03924 13.553 6.23232C13.3485 6.33267 13.1294 6.22788 13.082 6.00685C12.9897 5.58067 12.9128 4.86678 13.2172 4.24118Z"
      fill="#A5A5A5"
    />
    <path
      d="M14.416 4.81181C14.969 4.385 15.6893 4.31006 16.1296 4.31006C16.3577 4.31006 16.507 4.49996 16.4506 4.71972C16.3423 5.14208 16.0918 5.81596 15.5387 6.24214C14.9857 6.66895 14.2654 6.74389 13.8252 6.74389C13.5971 6.74389 13.4477 6.55399 13.5041 6.33423C13.6124 5.91187 13.863 5.23799 14.416 4.81181Z"
      fill="#C1C1C1"
    />
    <path
      d="M12.7188 4.49891C12.7663 4.56369 12.8483 4.59354 12.9265 4.57385C13.5135 4.4265 14.0742 4.89269 14.03 5.49162C14.0242 5.57165 14.0678 5.64659 14.1408 5.68153C14.6862 5.94257 14.8137 6.65709 14.3907 7.08708C14.3343 7.14424 14.319 7.22998 14.3523 7.30302C14.6009 7.85051 14.235 8.47866 13.632 8.53899C13.5519 8.54725 13.4846 8.60314 13.4628 8.67999C13.2981 9.25797 12.6105 9.50567 12.1094 9.16841C12.0428 9.12332 11.955 9.12332 11.8883 9.16841C11.3872 9.5063 10.6996 9.25797 10.5349 8.67999C10.5131 8.60314 10.4458 8.54725 10.3657 8.53899C9.76273 8.47866 9.39682 7.85051 9.64546 7.30302C9.67878 7.22998 9.6634 7.14424 9.60701 7.08708C9.18471 6.65646 9.31159 5.94257 9.85693 5.68153C9.92934 5.64659 9.97356 5.57165 9.96779 5.49162C9.92294 4.89269 10.4837 4.4265 11.0713 4.57385C11.1495 4.59354 11.2321 4.56369 11.2789 4.49891C11.6333 4.01176 12.3651 4.01176 12.7188 4.49891Z"
      fill="#FC6E2E"
    />
    <path
      d="M17.3086 7.63525H6.31785C5.81096 7.63525 5.3784 7.99792 5.2951 8.49332L4.1743 15.1228C4.05831 15.8082 4 16.5017 4 17.1966V19.1848H19.6251V17.1966C19.6251 16.5017 19.5668 15.8082 19.4508 15.1228L18.33 8.49332C18.2461 7.99792 17.8135 7.63525 17.3073 7.63525H17.3086Z"
      fill="#FFD2BD"
    />
    <path
      d="M6.88899 10.6693L7.47598 11.4384C7.90212 11.9967 8.72494 12.0596 9.23183 11.5725L9.88546 10.9449C10.0309 10.8046 10.2072 10.6972 10.4032 10.6426C11.1421 10.4381 11.8111 10.983 11.8111 11.683V13.3445C11.8111 14.1194 12.4449 14.7469 13.2261 14.7469C14.0079 14.7469 14.641 14.1187 14.641 13.3445V12.7811C14.641 11.2682 15.8784 10.0418 17.4048 10.0418H18.591L18.3296 8.49332C18.2456 7.99792 17.8131 7.63525 17.3068 7.63525H6.31545C5.80857 7.63525 5.37601 7.99792 5.2927 8.49332L5.03125 10.0418H5.61696C6.1168 10.0418 6.58716 10.2742 6.88899 10.6693Z"
      fill="white"
    />
    <rect
      x="2"
      y="18"
      width="20"
      height="3"
      rx="1.5"
      fill="#1F1F1F"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconChristmasBenefit',
  props: {
    color: {
      type: String,
      default: colors['f-primary-white'],
    },
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    type: {
      type: String,
      default: 'off',
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
