import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ff-scroller", {
      [`scroller--type--${_ctx.scrollerType}`]: true,
    }]),
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("div", {
      class: "scroller--container",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent.is), _mergeProps({ class: "scroller--icon" }, _ctx.iconComponent.attr), null, 16))
    ])
  ], 6))
}