<template>
  <div :class="$style.container">
    <transition>
      <template v-if="isShow">
        <div
          :class="$style.dimmed"
          @click="() => hideModal()"
        />
      </template>
    </transition>

    <transition>
      <template v-if="isShow">
        <div :class="$style.box">
          <Header
            :class="$style.boxHeader"
            noMenu
            @click:logo="logGnbHome"
          />

          <div :class="$style.boxBody">
            <div :class="[$style.section, $style.searchBarSection]">
              <SearchBar
                v-model="searchText"
                ref="benefitSearchBarRef"
                :class="$style.searchBar"
                placeholder="필요한 베네핏을 검색해보세요!"
                :maxlength="50"
                :boxInnerStyles="{
                  background: colors['grey-07'],
                }"
                @input="(event) => throttleGetAutocompleteList(event)"
                @search="(val) => goToSearchPage(val)"
              />

              <div :class="$style.closeBtn">
                <Button
                  text="취소"
                  :fontSize="14"
                  :height="42"
                  fontColor="primary-black"
                  color="white"
                  @click="() => hideModal()"
                />
              </div>
            </div>

            <template v-if="searchText">
              <div :class="$style.section">
                <div :class="[$style.sectionBody, $style.searchList]">
                  <template
                    v-for="(row, key) in state.autocompleteList"
                    :key="key"
                  >
                    <SearchHistory
                      :class="$style.searchItem"
                      :keyword="row"
                      :search="searchText"
                      :onClick="() => goToSearchPage(row)"
                    />
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-if="state.recommendTagList.length">
                <div :class="$style.section">
                  <div :class="$style.sectionTitle">인기검색어</div>

                  <div :class="[$style.sectionBody, $style.tagList]">
                    <template
                      v-for="(row, key) in state.recommendTagList"
                      :key="key"
                    >
                      <Tag
                        :class="$style.tagItem"
                        :theme="row.theme"
                        :onClick="row.onClick"
                      >
                        {{ row.name }}
                      </Tag>
                    </template>
                  </div>
                </div>
              </template>

              <template v-if="state.historyList.length">
                <div :class="$style.section">
                  <div :class="$style.sectionTitle">최근검색어</div>

                  <div :class="[$style.sectionBody, $style.searchList]">
                    <template
                      v-for="(row, key) in state.historyList"
                      :key="key"
                    >
                      <SearchHistory
                        :class="$style.searchItem"
                        :keyword="row.keyword"
                        :onClick="() => goToSearchPage(row.keyword)"
                        :onDelete="
                          () => deleteBenefitSearchHistory(row.keyword)
                        "
                      />
                    </template>
                  </div>
                </div>
              </template>

              <template v-if="state.categoryList.length">
                <div :class="$style.section">
                  <div :class="$style.sectionTitle">카테고리</div>

                  <div :class="$style.sectionBody">
                    <TagSlider :list="state.categoryList" />
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import {
  computed,
  defineComponent,
  nextTick,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Button from '@/components/common/Button.vue'
import SearchBar from '@/components/common/search/SearchBar.vue'
import SearchHistory from '@/components/common/search/SearchHistory.vue'
import Tag from '@/components/common/tag/Tag.vue'
import TagSlider from '@/components/common/tag/TagSlider.vue'
import Header from '@/containers/layout/Header.vue'

import { benefitCategoryApi, benefitSearchKeywordApi } from '@/api'
import colors from '@/assets/scss/base/colors.module.scss'
import { useNetworkError } from '@/hooks/network-error'
import { BenefitListItem } from '@/interfaces/api/benefits'
import { BenefitSearchHistory } from '@/interfaces/api/benefits/search-keyword'
import { TagSlideItem } from '@/interfaces/components/common/tag'
import { useAnalytics } from '@/plugins/firebase'
import { useToast } from '@/plugins/toast'
import { useBenefitSearchModalStore } from '@/store/modules/benefit-search-modal'
import { useGlobalStore } from '@/store/modules/global'
import { useKeepAliveStore } from '@/store/modules/keep-alive'

export default defineComponent({
  name: 'BenefitSearchModal',
  components: {
    Header,
    SearchBar,
    Button,
    Tag,
    TagSlider,
    SearchHistory,
  },
  setup() {
    const benefitSearchBarRef = ref()
    const route = useRoute()
    const router = useRouter()
    const analytics = useAnalytics()
    const networkError = useNetworkError()
    const toast = useToast()
    const keepAliveStore = useKeepAliveStore()
    const modalStore = useBenefitSearchModalStore()
    const globalStore = useGlobalStore()

    const state = reactive({
      recommendTagList: [] as TagSlideItem[],
      historyList: [] as BenefitSearchHistory[],
      categoryList: [] as TagSlideItem[],
      autocompleteList: [] as string[],
    })

    const searchText = computed({
      get: () => modalStore.searchText,
      set: (val: string) => modalStore.setSearchText(val),
    })

    const isShow = computed(() => modalStore.isShow)

    const throttleGetAutocompleteList = _.throttle(getAutocompleteList, 500)

    watch(
      () => isShow.value,
      (isShow) => {
        if (isShow) {
          showModal()
          return
        }

        hideModal()
      },
    )

    watch(
      () => route.path,
      () => {
        hideModal()
      },
    )

    onMounted(() => {
      if (isShow.value) {
        initialize()
        showModal()
        return
      }
    })

    onBeforeUnmount(() => {
      hideModal()
    })

    onActivated(() => {
      if (isShow.value) {
        initialize()
      }
    })

    onDeactivated(() => {
      hideModal()
    })

    async function initialize() {
      return Promise.all([
        getRecommendList(),
        getHistoryList(),
        getCategoryList(),
      ])
    }

    async function showModal() {
      await initialize()
      modalStore.showModal()
      globalStore.enableScrollBar(false)

      await nextTick()

      benefitSearchBarRef.value?.focus()
    }

    async function hideModal() {
      modalStore.hideModal()
      globalStore.enableScrollBar()

      await nextTick()

      searchText.value = ''
    }

    function onBenefitPlusClick(item: BenefitListItem) {
      if (item.id) {
        router.push({
          name: 'BenefitDetail',
          params: {
            benefitId: item.id,
          },
        })
        return
      }
    }

    async function getRecommendList() {
      try {
        const res = await benefitSearchKeywordApi.getRecommendList()

        if (res.success) {
          const keywords = res?.keywords ?? []

          state.recommendTagList = keywords.map((row) => ({
            name: row.keyword,
            theme: 'orange',
            onClick() {
              analytics.presetLogEvent('benefits_search_popularword', {
                popularword_id: row.id,
              })

              goToSearchPage(row.keyword)
            },
          }))
        } else if (res.message) {
          toast.info(res.message)
        }
      } catch (error) {
        networkError.throwError(error)
      }
    }

    async function getHistoryList() {
      try {
        const res = await benefitSearchKeywordApi.getHistoryList()

        if (res.success) {
          const historyList = res?.history ?? []

          state.historyList = historyList
        } else if (res.message) {
          toast.info(res.message)
        }
      } catch (error) {
        networkError.throwError(error)
      }
    }

    async function getAutocompleteList(event) {
      const inputValue: string = event?.target?.value || searchText.value

      try {
        if (!inputValue) {
          return
        }

        const res =
          await benefitSearchKeywordApi.getAutocompleteList(inputValue)

        if (res.success) {
          const autocompleteList = res?.keywords ?? []

          state.autocompleteList = autocompleteList.slice(0, 10)
        } else if (res.message) {
          toast.info(res.message)
        }
      } catch (error) {
        networkError.throwError(error)
      }
    }

    async function getCategoryList() {
      try {
        const res = await benefitCategoryApi.getList({
          depth: 2,
          isPublic: true,
        })

        if (res.success) {
          const benefitCategories = res.benefitCategories ?? []

          state.categoryList = benefitCategories.map((item) => {
            return {
              item,
              name: item.title,
              onClick() {
                if (!item.parentId) return

                analytics.presetLogEvent('benefits_category', {
                  category_id: item.id,
                })

                keepAliveStore.delete('BenefitListByCategory')

                router.push({
                  name: 'BenefitListByCategory',
                  params: {
                    mainCategoryId: item.parentId,
                    middleCategoryId: item.id,
                  },
                })

                hideModal()
              },
            }
          })
        } else if (res.message) {
          toast.info(res.message)
        }
      } catch (error) {
        networkError.throwError(error)
      }
    }

    async function deleteBenefitSearchHistory(keyword: string) {
      try {
        if (!keyword) {
          return
        }

        const res = await benefitSearchKeywordApi.delete(keyword)

        if (res.success) {
          state.historyList = state.historyList.filter((row) => {
            return row.keyword !== keyword
          })
        } else if (res.message) {
          toast.info(res.message)
        }
      } catch (error) {
        networkError.throwError(error)
      }
    }

    function goToSearchPage(nextSearchText?: string) {
      keepAliveStore.delete('BenefitSearch')

      let tab: string | undefined
      if (route.name === 'BenefitSearch') {
        const currentText = route.query.text
        const currentTab = route.query.tab as string
        if (nextSearchText && nextSearchText === currentText) {
          tab = currentTab || undefined
        }
      }

      router.push({
        name: 'BenefitSearch',
        query: {
          text: nextSearchText || undefined,
          tab,
        },
      })

      hideModal()
    }

    function logGnbHome() {
      if (route.name === 'Home') return
      analytics.presetLogEvent('GNB_home')
    }

    return {
      colors,
      benefitSearchBarRef,
      state,
      searchText,
      isShow,
      throttleGetAutocompleteList,
      hideModal,
      onBenefitPlusClick,
      deleteBenefitSearchHistory,
      goToSearchPage,
      logGnbHome,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;

  .dimmed {
    width: 100vw;
    height: 100vh;
    background-color: $f-trans-black-70;
    transition: opacity 100ms ease-out;
    cursor: pointer;

    &:global(.v-enter-from),
    &:global(.v-leave-to) {
      opacity: 0;
    }

    &:global(.v-leave-from),
    &:global(.v-enter-to) {
      opacity: 1;
    }
  }

  .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: auto;
    background: $white;
    box-shadow: -1px 0px 6px rgba($black, 0.1);
    transition: opacity 100ms ease-out;

    &:global(.v-enter-from),
    &:global(.v-leave-to) {
      opacity: 0;
    }

    &:global(.v-leave-from),
    &:global(.v-enter-to) {
      opacity: 1;
    }

    .boxHeader {
      background: $white;
    }

    .boxBody {
      width: 736px;
      margin: 0 auto;
      padding: 20px 0 16px;
    }
  }

  .section {
    margin-bottom: 32px;

    .sectionTitle {
      @include font_ko(16px, 700);
      color: $primary-black;
      margin-bottom: 12px;
    }

    // .sectionBody {}

    .tagList {
      margin-bottom: -8px;

      .tagItem {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .searchList {
      margin-bottom: -8px;

      .searchItem {
        margin-bottom: 8px;
      }
    }

    &.searchBarSection {
      display: flex;
      align-items: center;

      .searchBar {
        flex: 1;
      }

      .closeBtn {
        margin-left: 16px;
      }
    }
  }
}
</style>
