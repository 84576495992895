import { ApiBaseResponse } from '@/interfaces/api'

export const baseResponse = (payload: Partial<ApiBaseResponse> = {}) => {
  return {
    ...payload,
    success: payload?.success ?? false,
  }
}

export const successResponse = <Data>(data: Data = {} as Data) => {
  return {
    ...baseResponse({ success: true }),
    ...data,
  }
}

export const failResponse = <Response = ApiBaseResponse>(
  message?: string,
): Partial<Response> => {
  return {
    ...baseResponse({ success: false }),
    message,
  } as any
}
