import { withKeys as _withKeys, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder", "disabled", "readonly", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSearch = _resolveComponent("IconSearch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.wrapper)
    }, [
      _createElementVNode("input", {
        ref: "inputEl",
        type: "text",
        value: _ctx.state.inputValue,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        maxlength: _ctx.maxlength,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onKeypress: [
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
          _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onKeyPress && _ctx.onKeyPress(...args)))
        ]
      }, null, 40, _hoisted_1),
      _createVNode(_component_IconSearch, {
        stroke: _ctx.$colorsV2['f-gray-45']
      }, null, 8, ["stroke"])
    ], 2)
  ], 2))
}