<template>
  <div class="invitation">
    <div class="invitation-card">
      <div class="title">소속/회사</div>
      <div class="description">아래 소속 그룹으로 승인 요청을 하시겠어요?</div>
      <div class="group">
        <div class="group-inner-scroll">
          {{ memberGroup?.name ?? '(정보 없음)' }}
        </div>
      </div>
      <div class="button-area">
        <div class="button-bottom">
          <button-component
            text="다음에"
            type="outline"
            @submit="onReject"
            :fontSize="15"
            :height="39"
          />
        </div>
        <div class="button-bottom">
          <button-component
            text="승인 요청"
            :is-disabled="!memberGroup"
            @submit="onAccept"
            :fontSize="15"
            :height="39"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/common/Button'

export default {
  components: {
    ButtonComponent,
  },
  props: {
    memberGroup: Object,
  },
  emits: ['accept', 'reject'],
  setup(props, { emit }) {
    return {
      onAccept() {
        emit('accept')
      },
      onReject() {
        emit('reject')
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.invitation {
  width: 372px;

  &-card {
    background-color: $white;
    padding: 40px 40px 20px;
    text-align: center;
    border: 1px solid $primary-black;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    .title {
      @include font_ko(20px, 400);
      color: $primary-black;
      padding-bottom: 12px;
    }
    .description {
      @include font_ko(14px, 400);
      color: $grey-01;
    }
    .group {
      width: 292px;
      min-height: 156px;
      max-height: 224px;
      padding: 20px 16px;
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $grey-07;
      text-align: center;
      @include font_ko(18px, 400);
      border-radius: 6px;
      .group-inner-scroll {
        max-height: 184px;
        overflow-y: auto;
      }
    }
    .button-area {
      display: flex;
      justify-content: center;
      .button-bottom {
        width: 100px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
