import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.clickable]: _ctx.isClickable,
    }),
    style: _normalizeStyle(_ctx.containerStyles),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onContainerClick && _ctx.onContainerClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.img),
      style: _normalizeStyle(_ctx.innerStyles),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onContainerClick && _ctx.onContainerClick(...args)))
    }, [
      (_ctx.border)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.border)
          }, null, 2))
        : _createCommentVNode("", true)
    ], 6)
  ], 6))
}