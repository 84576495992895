export const createService = (client) => {
  return {
    async uploadFiles(form) {
      return await client.post(`/file/public`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  }
}
