import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ApiBaseResponse } from '@/interfaces/api'
import {
  BenefitSearchKeywordGetAutocompleteListApiResponse,
  BenefitSearchKeywordGetHistoryListApiResponse,
  BenefitSearchKeywordGetRecommendListApiResponse,
} from '@/interfaces/api/benefits/search-keyword'
import { axiosHandler } from '@/libs/api/axios-handler'
import { failResponse } from '@/libs/api/custom-responses'

export const createBenefitSearchKeywordService = (
  client: AxiosCacheInstance,
) => {
  return {
    /**
     * 검색어 자동완성
     */
    getAutocompleteList(searchText: string) {
      return axiosHandler<BenefitSearchKeywordGetAutocompleteListApiResponse>(
        () => {
          return client.get('/mobile/benefit/search/keyword/autocomplete', {
            params: {
              searchText,
            },
          })
        },
      )
    },

    /**
     * 추천 검색어 조회
     */
    getRecommendList() {
      return axiosHandler<BenefitSearchKeywordGetRecommendListApiResponse>(
        () => {
          return client.get('/mobile/benefit/search/keyword/recommend')
        },
      )
    },

    /**
     * 검색 히스토리 조회
     */
    getHistoryList() {
      return axiosHandler<BenefitSearchKeywordGetHistoryListApiResponse>(() => {
        return client.get('/mobile/benefit/search/history')
      })
    },

    /**
     * 검색 히스토리 삭제
     */
    async delete(keyword: string) {
      if (!keyword) {
        return failResponse()
      }

      return axiosHandler<ApiBaseResponse>(() => {
        return client.delete('/mobile/benefit/search/history', {
          params: {
            keyword,
          },
        })
      })
    },
  }
}
