import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.contents)
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.$style.image),
        src: _ctx.images.error
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title)
      }, "멤버 전용 서비스", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.subtitle)
      }, _cache[0] || (_cache[0] = [
        _createTextVNode(" 패스트파이브 멤버를 위한"),
        _createElementVNode("br", null, null, -1),
        _createTextVNode("전용 서비스입니다. ")
      ]), 2)
    ], 2)
  ], 2))
}