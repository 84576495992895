import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM8.31463 8.79932C8.27063 9.0678 8.12101 9.23458 7.82178 9.23458C7.48735 9.23458 7.30253 9.01492 7.30253 8.71797V8.64068C7.30253 8.13627 7.57536 7.80271 8.12541 7.5017C8.67107 7.19661 8.85589 6.97288 8.85589 6.58237C8.85589 6.13898 8.48185 5.82983 7.94059 5.82983C7.45215 5.82983 7.12651 6.05356 6.9857 6.46441C6.88889 6.71661 6.72607 6.81831 6.48405 6.81831C6.18042 6.81831 6 6.64746 6 6.37492C6 6.2 6.0396 6.04949 6.12321 5.89492C6.39604 5.34169 7.08251 5 7.989 5C9.18152 5 10 5.62644 10 6.54576C10 7.16814 9.66557 7.59525 9.06711 7.92475C8.49065 8.2339 8.34983 8.42915 8.31463 8.79932ZM8.46865 10.4224C8.46865 10.7437 8.18702 11 7.83058 11C7.47415 11 7.18812 10.7437 7.18812 10.4224C7.18812 10.101 7.47415 9.84881 7.83058 9.84881C8.18702 9.84881 8.46865 10.101 8.46865 10.4224Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}