<template>
  <button
    :class="containerClasses"
    :type="type"
    :disabled="disabled"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @click="(ev) => onClick(ev)"
  >
    <slot
      :isHover="state.isHover"
      :isPress="state.isPress"
      :isDisabled="disabled"
      :cssVars="cssVars"
    />
  </button>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  reactive,
  useCssModule,
} from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'
import { ButtonV2 } from '@/interfaces/components/v2/button'
import { withPx } from '@/libs/css'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<ButtonV2.Type>,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    frame: {
      type: String as PropType<ButtonV2.Frame>,
      default: 'fixed',
    },
    size: {
      type: String as PropType<ButtonV2.Size>,
      default: 'medium',
    },
    /**
     * border-radius의 값입니다.
     *
     * frame이 icon이 아닐때만 동작합니다.
     */
    radius: {
      type: String,
      default: '12px',
    },
    theme: {
      type: String as PropType<ButtonV2.Theme>,
      default: 'primary-black',
    },
    unuseAutoblur: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const $style = useCssModule()

    const state = reactive({
      isHover: false,
      isPress: false,
    })

    const containerClasses = computed(() => {
      const classes = [$style.container]

      switch (props.frame) {
        case 'icon': {
          classes.push($style['frame--icon'])
          break
        }

        default: {
          classes.push($style[`frame--${props.frame}`])
          break
        }
      }

      switch (props.size) {
        case 'large': {
          classes.push($style['size--large'])
          break
        }
      }

      return classes
    })

    const cssVars = computed(() => {
      // 기본 설정 : primary-black
      const scope = {
        borderRadius: props.radius,
        bgColor: colors['f-primary-black'],
        color: colors['f-primary-white'],
        borderWidth: withPx(0),
        borderColor: 'transparent',
        overlayColor: 'transparent',
      }

      // 기본 이외
      switch (props.theme) {
        case 'gray-90': {
          scope.bgColor = colors['f-gray-90']
          break
        }

        case 'gray-25': {
          scope.bgColor = colors['f-gray-25']
          scope.color = colors['f-gray-50']
          break
        }

        case 'secondary-extension-orange': {
          scope.bgColor = colors['f-secondary-extention-orange-10']
          scope.color = colors['f-secondary-orange']
          break
        }

        case 'transparent': {
          scope.bgColor = 'transparent'
          scope.color = colors['f-primary-black']
          break
        }

        case 'border-gray-35': {
          scope.bgColor = colors['transparent']
          scope.color = colors['f-primary-black']
          scope.borderWidth = withPx(1)
          scope.borderColor = colors['f-gray-35']
          break
        }

        case 'primary-white': {
          scope.bgColor = colors['f-primary-white']
          scope.color = colors['f-primary-black']
          break
        }
      }

      switch (props.frame) {
        case 'icon': {
          scope.borderRadius = '50%'
          break
        }
      }

      if (state.isHover) {
        switch (props.theme) {
          case 'secondary-extension-orange': {
            scope.overlayColor = colors['f-trans-orange-10']
            break
          }

          case 'transparent':
          case 'primary-white':
          case 'border-gray-35': {
            scope.overlayColor = colors['f-trans-black-03']
            break
          }

          default: {
            scope.overlayColor = colors['f-trans-white-10']
            break
          }
        }
      }

      if (state.isPress) {
        switch (props.theme) {
          case 'secondary-extension-orange': {
            scope.overlayColor = colors['f-trans-orange-20']
            break
          }

          case 'transparent':
          case 'primary-white':
          case 'border-gray-35': {
            scope.overlayColor = colors['f-trans-black-05']
            break
          }

          default: {
            scope.overlayColor = colors['f-trans-white-20']
            break
          }
        }
      }

      if (props.disabled) {
        switch (props.theme) {
          case 'transparent': {
            scope.bgColor = 'transparent'
            scope.color = colors['f-gray-30']
            scope.overlayColor = 'transparent'
            break
          }

          case 'border-gray-35': {
            scope.bgColor = 'transparent'
            scope.color = colors['f-gray-30']
            scope.overlayColor = 'transparent'
            scope.borderColor = colors['f-gray-30']
            break
          }

          default: {
            scope.bgColor = colors['f-gray-30']
            scope.color = colors['f-primary-white']
            scope.overlayColor = 'transparent'
            break
          }
        }
      }

      return scope
    })

    function onMouseEnter() {
      state.isHover = true
    }

    function onMouseLeave() {
      state.isHover = false
      state.isPress = false
    }

    function onMouseDown() {
      state.isPress = true
    }

    function onMouseUp() {
      state.isPress = false
    }

    function onClick(ev: MouseEvent) {
      const el = ev.target as HTMLButtonElement | null

      if (!props.unuseAutoblur) {
        el?.blur()
      }

      emit('click', ev)
    }

    return {
      state,
      containerClasses,
      cssVars,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onMouseUp,
      onClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  @include font_v2('ko', 12px, 500);

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 16px;
  border: none;
  background: transparent;
  border-radius: v-bind('cssVars.borderRadius');
  // border-style: solid;
  // border-width: v-bind('cssVars.borderWidth');
  // border-color: v-bind('cssVars.borderColor');
  box-shadow: inset 0 0 0 v-bind('cssVars.borderWidth')
    v-bind('cssVars.borderColor');
  background-color: v-bind('cssVars.bgColor');
  color: v-bind('cssVars.color');

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: v-bind('cssVars.borderRadius');
    background-color: v-bind('cssVars.overlayColor');
  }

  &.frame--fixed {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &.frame--icon {
    padding: 0;
    width: 28px;
    height: 28px;

    &.size--large {
      padding: 0;
      width: 48px;
      height: 48px;
    }
  }

  &.size--large {
    @include font_v2('ko', 15px, 500);

    padding: 12px 24px;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}
</style>
