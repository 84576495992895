<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.7718 10.8171C16.1002 10.4522 16.0707 9.89015 15.7058 9.56174C15.3409 9.23333 14.7788 9.26291 14.4504 9.62781L11.0404 13.4167L9.03391 11.639C8.66646 11.3134 8.10467 11.3474 7.77913 11.7149C7.45358 12.0823 7.48755 12.6441 7.855 12.9696L10.5217 15.3322C10.8871 15.656 11.4452 15.6244 11.7718 15.2615L15.7718 10.8171Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors.module.scss'

export default defineComponent({
  name: 'IcnRecomm',
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: colors['point1'],
    },
  },
})
</script>
