<template>
  <slot :onInsideClick="onInsideClick" />
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, reactive } from 'vue'

export default defineComponent({
  emits: {
    'click:inside': () => true,
    'click:outside': () => true,
  },
  setup(props, { emit }) {
    const state = reactive({
      isInside: false,
    })

    onMounted(() => {
      window.addEventListener('click', onGlobalClick)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', onGlobalClick)
    })

    function onInsideClick() {
      state.isInside = true

      emit('click:inside')
    }

    function onGlobalClick() {
      if (!state.isInside) {
        emit('click:outside')
      }

      state.isInside = false
    }

    return {
      onInsideClick,
    }
  },
})
</script>
