<template>
  <div :class="$style.container">
    <header-container
      v-if="isLogin"
      :class="$style.header"
      @click:logo="logGnbHome"
      @click:logout="() => analytics.presetLogEvent('account_logout')"
      @click:my-page="() => analytics.presetLogEvent('account_myaccountupdate')"
      @click:reset-password="
        () => analytics.presetLogEvent('account_resetpassword')
      "
      @click:invitation="
        () => analytics.presetLogEvent('account_enter_invitationlink')
      "
      @click:profile-menu="(isShowProfile) => logGnbAccount(isShowProfile)"
      @change:member-group="
        () => analytics.presetLogEvent('account_selectgroup')
      "
    />

    <div
      v-if="isShowNagivationMenu"
      :class="$style.sideContainer"
    >
      <navigation-menu-component
        :class="$style.navigation"
        @click:router-link="(routeName) => logLnb(routeName)"
      />
    </div>

    <div :class="$style.mainContainer">
      <template v-if="networkError?.state?.error">
        <NetworkError />
      </template>
      <template v-else-if="isDisallowedRouteForGuestGroup">
        <GuestGuide />
      </template>
      <template v-else>
        <router-view v-slot="{ Component, route }">
          <keep-alive :include="keepAliveComponentNames">
            <suspense>
              <component
                :is="Component"
                :key="route.meta.usePathKey ? route.path : undefined"
              />
            </suspense>
          </keep-alive>
        </router-view>
      </template>

      <div :class="$style.footerContainer">
        <footer-component
          v-show="isShowFooter"
          :class="$style.footer"
        />
      </div>

      <transition name="fade">
        <Toast
          v-if="toast.show"
          :type="toast.type"
          :message="toast.message"
        />
      </transition>

      <BenefitSearchModal v-if="isLogin" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onErrorCaptured,
  watch,
} from 'vue'
import { useRoute } from 'vue-router'

import Toast from '@/components/common/Toast.vue'
import BenefitSearchModal from '@/containers/benefits/BenefitSearchModal.vue'
import FooterComponent from '@/containers/layout/Footer.vue'
import HeaderContainer from '@/containers/layout/Header.vue'
import NavigationMenuComponent from '@/containers/layout/NavigationMenu.vue'
import GuestGuide from '@/views/error/GuestGuide.vue'
import NetworkError from '@/views/error/NetworkError.vue'

import { useNetworkError } from '@/hooks/network-error'
import { useAnalytics } from '@/plugins/firebase'
import { useToast } from '@/plugins/toast'
import { useKeepAliveStore } from '@/store/modules/keep-alive'
import { useSessionStore } from '@/store/modules/session'

export default {
  components: {
    NetworkError,
    GuestGuide,
    HeaderContainer,
    FooterComponent,
    NavigationMenuComponent,
    BenefitSearchModal,
    Toast,
  },
  setup() {
    const route = useRoute()
    const analytics = useAnalytics()
    const keepAliveStore = useKeepAliveStore()
    const networkError = useNetworkError()
    const toast = useToast()
    const sessionStore = useSessionStore()

    const keepAliveComponentNames = computed(() => {
      return keepAliveStore.includedComponentNames
    })

    const memberGroup = computed(() => sessionStore.memberGroup)

    const isLogin = computed(() => sessionStore.isLogin)

    const isDisallowedRouteForGuestGroup = computed(() => {
      if (!route.meta.disallowGuestGroup) {
        return false
      }

      return !sessionStore.isLogin || sessionStore.isGuestOrLeaver
    })

    const isShowFooter = computed(() => {
      return !route.meta.hideFooter
    })

    const isShowNagivationMenu = computed(() => {
      return isLogin.value && !route.meta.hideNagivationMenu
    })

    watch(
      () => route.path,
      (path, prevPath) => {
        if (path && path !== prevPath) {
          networkError.clear()
        }
      },
    )

    watch(
      () => memberGroup.value?.id,
      (id, prevId) => {
        if (id && id !== prevId) {
          networkError.clear()
        }
      },
    )

    onBeforeMount(() => {
      // 최초 로드할때 401 오류 발생시 `src/api/index.js`에서 로그인으로 router.push 중
      // networkError 상태는 전역으로 사용 중이라 초기화가 필요
      networkError.clear()
    })

    onBeforeUnmount(() => {
      networkError.clear()
    })

    onErrorCaptured((error) => {
      networkError.throwError(error)
      return true
    })

    function logGnbHome() {
      if (route.name === 'Home') return
      analytics.presetLogEvent('GNB_home')
    }

    function logGnbAccount(isShowProfile) {
      isShowProfile && analytics.presetLogEvent('GNB_account')
    }

    function logLnb(routeName) {
      if (route.name === routeName) return

      switch (routeName) {
        case 'Reserve': {
          analytics.presetLogEvent('LNB_reservation')
          break
        }
        case 'ReservationHistory': {
          analytics.presetLogEvent('LNB_reservation_history')
          break
        }
        case 'Member': {
          analytics.presetLogEvent('LNB_groupmanagement_membermanagement')
          break
        }
        case 'Benefits': {
          analytics.presetLogEvent('LNB_benefits')
          break
        }
        case 'GroupCredit': {
          analytics.presetLogEvent('LNB_groupmanagement_creditsmanagement')
          break
        }
        case 'CreateInquiry': {
          analytics.presetLogEvent('LNB_contact')
          break
        }
      }
    }

    return {
      networkError,
      analytics,
      keepAliveComponentNames,
      isDisallowedRouteForGuestGroup,
      isShowFooter,
      isShowNagivationMenu,
      isLogin,
      toast,
      logGnbHome,
      logGnbAccount,
      logLnb,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  min-width: 1024px;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;

  .header {
    position: sticky;
    top: 0;
    width: 100%;
  }

  .sideContainer {
    position: sticky;
    top: var(--header-height);
    float: left;
    height: calc(100vh - var(--header-height));
    background-color: $grey-bg;

    .navigation {
      position: relative;
    }
  }

  .mainContainer {
    flex: 1;
    display: flex;
    padding: var(--main-padding-top) 40px 0;
    flex-direction: column;
    width: calc(100% - var(--side-width));
    position: relative;

    .footerContainer {
      margin: auto -40px 0;

      .footer {
        margin-top: 100px;
      }
    }
  }
}
</style>
