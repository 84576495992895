<template>
  <div :class="$style.container">
    <div :class="$style.contents">
      <img
        :class="$style.image"
        :src="images.error"
      />
      <div :class="$style.title">멤버 전용 서비스</div>

      <div :class="$style.subtitle">
        패스트파이브 멤버를 위한<br />전용 서비스입니다.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'GuestGuide',
  setup() {
    const images = ref({
      error: require('@/assets/images/v2/error/emptystate_memberonly.svg'),
    })

    return {
      images,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  white-space: pre-line;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contents {
    text-align: center;

    .image {
      display: block;
      margin: 0 auto 32px;
      width: 140px;
    }

    .title {
      @include font_v2('ko', 22px, 700);
      color: $f-primary-black;
    }

    .subtitle {
      @include font_v2('ko', 15px, 400);
      color: $f-gray-50;
      margin-top: 8px;
    }

    .btn {
      margin: 24px auto 0;
    }
  }
}
</style>
