
import { PropType, computed, defineComponent, reactive } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'
import { BadgeV2 } from '@/interfaces/components/v2/badge'

const colorMap = {
  'f-gray-10': {
    text: colors['f-gray-45'],
    bg: colors['f-gray-10'],
  },
  'f-gray-15': {
    text: colors['f-gray-45'],
    bg: colors['f-gray-15'],
  },
  'f-gray-40': {
    text: colors['f-primary-white'],
    bg: colors['f-gray-40'],
  },
  'f-trans-black-03': {
    text: colors['f-gray-45'],
    bg: colors['f-trans-black-03'],
  },
  'f-primary-black': {
    text: colors['f-primary-white'],
    bg: colors['f-primary-black'],
  },
  'f-secondary-extention-orange-10': {
    text: colors['f-secondary-orange'],
    bg: colors['f-secondary-extention-orange-10'],
  },
  'f-trans-orange-10': {
    text: colors['f-secondary-orange'],
    bg: colors['f-trans-orange-10'],
  },
  'f-warning-red-10': {
    text: colors['f-warning-red-100'],
    bg: colors['f-warning-red-10'],
  },
  'f-trans-green-10': {
    text: colors['f-secondary-green'],
    bg: colors['f-trans-green-10'],
  },
}

const __default__ = defineComponent({
  props: {
    lang: {
      type: String as PropType<'ko' | 'en'>,
      default: 'ko',
    },
    size: {
      type: String as PropType<BadgeV2.Size>,
      default: 'medium',
    },
    color: {
      type: String as PropType<BadgeV2.Color>,
      default: 'f-gray-15',
    },
    hoverColor: {
      type: String as PropType<BadgeV2.Color>,
    },
    activeColor: {
      type: String as PropType<BadgeV2.Color>,
    },
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * example: '12px'
     */
    radius: String,
    onClick: Function,
  },
  setup(props) {
    const state = reactive({
      isHover: false,
    })

    const isClickable = computed(() => typeof props.onClick === 'function')

    const palette = computed(() => {
      const color = colorMap[props.color] ?? colorMap['f-gray-15']

      if (props.active) {
        return (props.activeColor && colorMap[props.activeColor]) || color
      }

      if (state.isHover) {
        return (props.hoverColor && colorMap[props.hoverColor]) || color
      }

      return color
    })

    const cssVars = computed(() => {
      const borderRadius = props.size === 'large' ? '14px' : '12px'

      return {
        borderRadius: props.radius || borderRadius,
      }
    })

    function onContainerClick(ev) {
      if (isClickable.value) {
        props.onClick?.(ev)
      }
    }

    function onMouseEnter() {
      state.isHover = true
    }

    function onMouseLeave() {
      state.isHover = false
    }

    return {
      isClickable,
      palette,
      cssVars,
      onContainerClick,
      onMouseEnter,
      onMouseLeave,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "634a049e": (_ctx.cssVars.borderRadius),
  "7a0082a8": (_ctx.palette.bg),
  "0212f9d4": (_ctx.palette.text)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__