<template>
  <div :class="$style.container">
    <div :class="$style.wrapper">
      <input
        ref="inputEl"
        type="text"
        :value="state.inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keypress.enter="onEnter"
        @keypress="onKeyPress"
      />

      <IconSearch :stroke="$colorsV2['f-gray-45']" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from 'vue'

import IconSearch from '@/components/v2/icon/IconSearch.vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  components: {
    IconSearch,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: Number,
    width: {
      type: String,
      default: '376px',
    },
    maxWidth: {
      type: String,
      default: '376px',
    },
    backgroundColor: {
      type: String,
      default: colors['f-gray-15'],
    },
  },
  emits: {
    focus: null,
    blur: null,
    input: null,
    keypress: null,
    'update:modelValue': (value: string) => typeof value === 'string',
    enter: (value: string) => typeof value === 'string',
  },
  setup(props, { emit }) {
    const inputEl = ref<HTMLInputElement>()

    const state = reactive({
      inputValue: '',
      isFocused: false,
    })

    const cssVars = computed(() => {
      return {
        width: props.width,
        maxWidth: props.maxWidth,
        backgroundColor: props.backgroundColor,
      }
    })

    watch(
      () => props.modelValue,
      () => {
        state.inputValue = props.modelValue
      },
    )

    onBeforeMount(() => {
      state.inputValue = props.modelValue
    })

    /**
     * @public
     */
    function focus() {
      inputEl.value?.focus()
    }

    function onInput(ev: Event) {
      const target = ev.target as HTMLInputElement | null

      state.inputValue = target?.value || ''

      emit('input', ev)
      emit('update:modelValue', state.inputValue)
    }

    function onFocus(ev: Event) {
      if (props.readonly || props.disabled) {
        ev.preventDefault()
        return
      }

      emit('focus', ev)

      state.isFocused = true
    }

    function onBlur(ev: Event) {
      emit('blur', ev)

      state.isFocused = false
    }

    function onEnter() {
      if (props.readonly || props.disabled) {
        return
      }

      emit('enter', state.inputValue)
    }

    function onKeyPress(ev: Event) {
      emit('keypress', ev)
    }

    return {
      inputEl,
      state,
      cssVars,
      focus,
      onInput,
      onFocus,
      onBlur,
      onEnter,
      onKeyPress,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  background-color: v-bind('cssVars.backgroundColor');
  border-radius: 12px;
  padding: 10px 16px;
  width: v-bind('cssVars.width');
  max-width: v-bind('cssVars.maxWidth');

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    input {
      @include font_v2('ko', 16px, 400);

      display: flex;
      flex: 1;
      border: 0;
      padding: 0;
      outline: none;
      background: transparent;
      color: $f-primary-black;
      width: 100%;
      margin-right: 8px;

      &::placeholder {
        color: $f-gray-45;
      }
    }
  }
}
</style>
