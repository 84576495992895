import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.contents)
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.$style.image),
        src: _ctx.images.error
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title)
      }, " 링크가 잘못되었거나 페이지가 삭제되었을 수 있습니다. ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.subtitle)
      }, "링크가 올바른지 확인해보세요.", 2)
    ], 2)
  ], 2))
}