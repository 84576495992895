
import {
  PropType,
  StyleValue,
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  watch,
} from 'vue'

import DropdownList from '@/components/v2/dropdown/DropdownList.vue'
import IconCaret from '@/components/v2/icon/IconCaret.vue'
import ClickArea from '@/components/v2/interaction/ClickArea.vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'
import { DropdownV2 } from '@/interfaces/components/v2/dropdown'

const colorMap = {
  'gray-15': {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-primary-white'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-primary-white'],
    bgColor: colors['f-gray-15'],
    'bgColor:disabled': colors['f-gray-30'],
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
  transparent: {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-primary-white'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-primary-white'],
    bgColor: 'transparent',
    'bgColor:disabled': colors['f-gray-30'],
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
  white: {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-primary-white'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-primary-white'],
    bgColor: colors['f-primary-white'],
    'bgColor:disabled': colors['f-gray-30'],
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
  line: {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-gray-40'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-gray-35'],
    bgColor: 'transparent',
    'bgColor:disabled': 'transparent',
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
}

const __default__ = defineComponent({
  components: {
    IconCaret,
    DropdownList,
    ClickArea,
  },
  props: {
    theme: {
      type: String as PropType<DropdownV2.SelectBox.Theme>,
      default: 'gray-15',
    },
    options: {
      type: Array as PropType<DropdownV2.List.Option[]>,
      default: () => [],
    },
    modelValue: {
      type: null as unknown as PropType<DropdownV2.List.ItemOption['value']>,
      default: null,
    },
    placeholder: {
      type: String,
      default: '선택해 주세요',
    },
    open: {
      type: Boolean,
      default: false,
    },
    popupPosition: {
      type: String as PropType<DropdownV2.SelectBox.PopupPosition>,
      default: 'left',
    },
    borderRadius: {
      type: String,
      default: '24px',
    },
    dropdownListWidth: {
      type: String,
    },
    wrapperStyles: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * 이 옵션이 true인 경우 내부 상태를 사용하지 않고 modelValue만 사용하게 됩니다.
     */
    onlyModelValue: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchInputPlaceholder: {
      type: String,
      default: '',
    },
    searchValue: {
      type: String,
      default: '',
    },
    noticeText: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': (_: DropdownV2.List.ItemOption['value']) => true,
    'update:open': (val: boolean) => typeof val === 'boolean',
    'update:searchValue': (val: string) => typeof val === 'string',
    select: (val: DropdownV2.List.ItemOption) => !!val,
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpen: false,
      isHover: false,
      isPress: false,
      selectedValue: null as DropdownV2.List.ItemOption['value'],
    })

    const cssVars = computed(() => {
      const palette = colorMap[props.theme] ?? colorMap['gray-15']

      const scope = {
        textColor: palette.textColor,
        caretColor: palette.caretColor,
        bgColor: palette.bgColor,
        overlayColor: palette.overlayColor,
        borderRadius: props.borderRadius,
        popupLeft: '0',
        popupRight: 'auto',
      }

      if (props.disabled) {
        scope.textColor = palette['textColor:disabled']
        scope.caretColor = palette['caretColor:disabled']
        scope.bgColor = palette['bgColor:disabled']
      } else {
        if (state.isHover) {
          scope.overlayColor = palette['overlayColor:hover']
        }

        if (state.isPress || state.isOpen) {
          scope.overlayColor = palette['overlayColor:press']
        }
      }

      switch (props.popupPosition) {
        case 'center': {
          scope.popupLeft = 'auto'
          scope.popupRight = 'auto'
          break
        }

        case 'right': {
          scope.popupLeft = 'auto'
          scope.popupRight = '0'
          break
        }
      }

      return scope
    })

    const selectedOption = computed(() => {
      const option = props.options.find((row) => {
        return row.value === state.selectedValue
      })

      return option ?? null
    })

    const isClickable = computed(() => !props.disabled && !props.readonly)

    const computedWrapperStyles = computed<StyleValue>(() => {
      const scope: StyleValue = []

      if (props.theme === 'line') {
        scope.push({
          padding: '10px 16px',
          borderWidth: '1px',
          borderColor: colors['f-gray-15'],
          borderStyle: 'solid',
          minWidth: '110px',
        })
      }

      if (props.wrapperStyles) {
        scope.push(props.wrapperStyles)
      }

      return scope
    })

    watch(
      () => props.modelValue,
      (value) => {
        state.selectedValue = value
      },
    )

    watch(
      () => props.open,
      (value) => {
        state.isOpen = value
      },
    )

    watch(
      () => state.selectedValue,
      (value, prevValue) => {
        if (value !== prevValue) {
          emit('update:modelValue', value)
        }
      },
    )

    watch(
      () => state.isOpen,
      (value, prevValue) => {
        if (value !== prevValue) {
          emit('update:open', value)
        }
      },
    )

    onBeforeMount(() => {
      state.selectedValue = props.modelValue
    })

    /**
     * @public
     */
    function close() {
      if (state.isOpen) {
        state.isOpen = false
      }
    }

    function onClick() {
      if (!isClickable.value) {
        return
      }

      state.isOpen = !state.isOpen
    }

    function onMouseEnter() {
      state.isHover = true
    }

    function onMouseLeave() {
      state.isHover = false
      state.isPress = false
    }

    function onMouseUp() {
      state.isPress = false
    }

    function onMouseDown() {
      state.isPress = true
    }

    function onSelect(option: DropdownV2.List.ItemOption) {
      if (!props.onlyModelValue) {
        state.selectedValue = option.value
      }

      state.isOpen = false

      emit('select', option)
    }

    function onOutsideClick() {
      state.isOpen = false
    }

    return {
      state,
      cssVars,
      selectedOption,
      isClickable,
      computedWrapperStyles,
      close,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onMouseDown,
      onSelect,
      onOutsideClick,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7b785613": (_ctx.cssVars.bgColor),
  "ee4125ee": (_ctx.cssVars.borderRadius),
  "15b944eb": (_ctx.cssVars.textColor),
  "47f29e1e": (_ctx.cssVars.overlayColor),
  "30606588": (_ctx.cssVars.popupLeft),
  "47faa68a": (_ctx.cssVars.popupRight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__