export const createService = (client) => {
  return {
    async getNewsfeeds(pagination, indexId) {
      const params = {
        page: pagination.page || 1,
      }
      if (pagination.size) params.size = pagination.size
      if (indexId) params.indexId = indexId
      return client.get(`/mobile/communityNewsfeeds`, {
        params,
      })
    },
    async getNewsfeed(id) {
      return client.get(`/mobile/communityNewsfeeds/${id}`, {})
    },
    async removeNewsfeed(id) {
      return client.delete(`/mobile/communityNewsfeeds/${id}`)
    },
    async addNewsfeed(payload) {
      return client.post(`/mobile/communityNewsfeeds`, payload)
    },
    async editNewsfeed(id, payload) {
      return client.put(`/mobile/communityNewsfeeds/${id}`, payload)
    },
    async addReaction(id) {
      return client.patch(`/mobile/communityNewsfeeds/${id}/react`)
    },
    async cancelReaction(id) {
      return client.patch(`/mobile/communityNewsfeeds/${id}/cancelReaction`)
    },
    async getReplies(feedId, indexId) {
      const params = {}
      if (indexId) params.indexId = indexId
      return client.get(`/mobile/communityNewsfeeds/${feedId}/replies`, {
        params,
      })
    },
    async addReply(feedId, contents) {
      return client.post(`/mobile/communityNewsfeeds/${feedId}/replies`, {
        contents,
      })
    },
    async removeReply(feedId, replyId) {
      return client.delete(
        `/mobile/communityNewsfeeds/${feedId}/replies/${replyId}`,
      )
    },
  }
}
