import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { ApiBaseResponse } from '@/interfaces/api'
import {
  QuoteCreateApiPayload,
  QuoteGetDetailApiResponse,
} from '@/interfaces/api/quotes'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createQuoteService = (client: AxiosCacheInstance) => {
  return {
    async getQuote() {
      return await axiosHandler<QuoteGetDetailApiResponse>(() => {
        return client.get(`/mobile/quotes`)
      })
    },
    async createQuote(payload: QuoteCreateApiPayload) {
      return await axiosHandler<ApiBaseResponse>(() => {
        return client.post(`/mobile/quotes`, payload)
      })
    },
  }
}
