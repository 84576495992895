<template>
  <invitation-component
    @accept="onAccept"
    @reject="onReject"
    :memberGroup="state.memberGroup"
  />
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'

import InvitationComponent from '@/components/user/Invitation.vue'

import { memberRoles as memberRolesApi } from '@/api'
import { MemberGroupLegacyApi } from '@/api/member-groups'
import { useGlobalModal } from '@/hooks/global-modal'

export default defineComponent({
  components: {
    InvitationComponent,
  },
  props: {
    invitationCode: String,
    rejectedModalTitle: {
      type: String,
      default: '승인 요청이 취소되었습니다.',
    },
  },
  emits: ['complete', 'invalid'],
  async setup(props, { emit }) {
    const globalModal = useGlobalModal()

    const state = reactive({
      memberGroup: null as any, // API interface 추가 필요
    })

    const refinedInvitationCode = computed(() => {
      const matches = props.invitationCode?.match(
        /^(?:https?:\/\/fastfive\.app\.link\/invitation\?(?:.+&)?code=)?([abcdefghjklmnpqrstuvwxyz23456789]{10,})(?:&.+)?$/,
      )
      return matches?.[1]
    })

    try {
      ;({
        data: { memberGroup: state.memberGroup },
      } = await MemberGroupLegacyApi.getCompanyViaInvitationCode(
        refinedInvitationCode.value,
      ))
    } catch (error) {
      switch (error?.response?.data?.errorCode) {
        case 'EXPIRED_INVITATION_CODE':
          {
            openInvalidAlert({
              message: `만료된 초대링크입니다.\n관리 멤버에게 초대링크를 다시 요청 해 주세요.`,
            })
          }
          break

        default: {
          openInvalidAlert({
            message:
              error?.response?.status === 400
                ? `유효하지 않은 초대링크입니다.\n초대링크를 다시 확인해 주세요.`
                : error.message,
          })
        }
      }
    }

    function openInvalidAlert({
      title,
      message,
    }: {
      title?: string
      message: string
    }) {
      if (!message) return

      globalModal.open({
        title,
        message,
        onConfirm() {
          emit('invalid')
        },
      })
    }

    function onAccept() {
      const memberGroupName = state.memberGroup?.name

      if (!memberGroupName) {
        return
      }

      globalModal.open({
        title: '소속 확인',
        message: `${memberGroupName} 본인의 소속이 맞습니까?\n확인을 누르시면 소속이 확정됩니다.`,
        cancellable: true,
        confirmButtonText: '확인',
        cancelButtonText: '뒤로 가기',
        onConfirm: (close) => {
          close?.()

          memberRolesApi
            .joinMemberGroup(refinedInvitationCode.value)
            .then(() => {
              globalModal.open({
                title: '소속이 승인되었습니다!',
                message: `${memberGroupName} 멤버가 되신 것을 환영합니다.\n소속변경이 필요하신 경우\n관리자에게 문의해 주세요.`,
                onConfirm() {
                  emit('complete', true)
                },
              })
            })
            .catch((error) => {
              switch (error?.response?.data?.errorCode) {
                case 'ALREADY_GROUP_MEMBER':
                  {
                    openInvalidAlert({
                      message: '이미 소속되어 있는 그룹입니다.',
                    })
                  }
                  break
                case 'EXISTING_MEMBER_ROLE':
                  {
                    openInvalidAlert({
                      message: '이미 승인 신청이 완료되었어요.',
                    })
                  }
                  break
                case 'EXPIRED_INVITATION_CODE':
                  {
                    openInvalidAlert({
                      message: `만료된 초대링크입니다.\n관리 멤버에게 초대링크를 다시 요청 해 주세요.`,
                    })
                  }
                  break
                default: {
                  openInvalidAlert({
                    title: '오류',
                    message: error.response?.data?.message ?? error.message,
                  })
                }
              }
            })
        },
      })
    }

    function onReject() {
      globalModal.open({
        title: props.rejectedModalTitle,
        message: '관리 멤버에게 초대 링크를 다시 요청해 주세요.',
        onConfirm() {
          emit('complete', false)
        },
      })
    }

    return {
      state,
      onAccept,
      onReject,
    }
  },
})
</script>
