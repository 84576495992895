import { defineStore } from 'pinia'

import { GLOBAL_MODAL } from '@/store/keys'

interface State {
  isShow: boolean
  title: string
  message: string
  cancellable: boolean
  confirmButtonText: string
  cancelButtonText: string
  onConfirm: ((close?: () => void) => void) | undefined
  onCancel: ((close?: () => void) => void) | undefined
}

const getInitialState = (): State => ({
  isShow: false,
  title: '',
  message: '',
  cancellable: false,
  confirmButtonText: '확인',
  cancelButtonText: '취소',
  onConfirm: undefined,
  onCancel: undefined,
})

export const useGlobalModalStore = defineStore(GLOBAL_MODAL, {
  state: () => getInitialState(),
  actions: {
    open(nextState: Partial<Omit<State, 'isShow'>>) {
      const initialState = getInitialState()
      this.isShow = true
      this.title = nextState.title ?? initialState.title
      this.message = nextState.message ?? initialState.message
      this.cancellable = nextState.cancellable ?? initialState.cancellable
      this.confirmButtonText =
        nextState.confirmButtonText ?? initialState.confirmButtonText
      this.cancelButtonText =
        nextState.cancelButtonText ?? initialState.cancelButtonText
      this.onConfirm = nextState.onConfirm ?? initialState.onConfirm
      this.onCancel = nextState.onCancel ?? initialState.onCancel
    },
    close() {
      this.$reset()
    },
  },
})
