<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.clickable]: isClickable,
    }"
    :style="containerStyles"
    @click="onContainerClick"
  >
    <div
      :class="$style.img"
      :style="innerStyles"
      @click="onContainerClick"
    >
      <template v-if="border">
        <div :class="$style.border" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

import { ThumbnailV2 } from '@/interfaces/components/v2/thumbnail'

const images = {
  profilePlaceholder: require('@/assets/images/v2/thumbnail/profile_placeholder.png'),
  sqaure: require('@/assets/images/v2/thumbnail/sqaure.png'),
}

export default defineComponent({
  props: {
    src: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    /**
     * ratio = width / height
     */
    ratio: {
      type: Number,
      default: 1,
    },
    border: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ThumbnailV2.Size>,
      default: 'medium',
    },
    radius: {
      type: String,
      default: '0',
    },
    imageName: {
      type: String as PropType<ThumbnailV2.ImageName>,
      default: 'profilePlaceholder',
    },
    onClick: Function,
  },
  setup(props) {
    const isClickable = computed(() => typeof props.onClick === 'function')

    const backgroundImage = computed(() => {
      if (props.src) {
        return `url(${props.src})`
      }

      const defaultImage = images[props.imageName] ?? images.profilePlaceholder

      if (defaultImage) {
        return `url(${defaultImage})`
      }

      return 'none'
    })

    const paddingBottom = computed(() => {
      const height = 100 / props.ratio || 100

      return `${height}%`
    })

    const containerStyles = computed(() => {
      return {
        borderRadius: props.radius,
      }
    })

    const innerStyles = computed(() => {
      return {
        backgroundImage: backgroundImage.value,
        paddingBottom: paddingBottom.value,
        borderRadius: props.radius,
      }
    })

    const cssVars = computed(() => {
      const scope = {
        width: '100px',
        height: '100px',
      }

      if (props.size === 'small') {
        scope.width = '64px'
        scope.height = '64px'
      }

      return scope
    })

    function onContainerClick() {
      if (isClickable.value) {
        props.onClick?.()
      }
    }

    return {
      isClickable,
      containerStyles,
      innerStyles,
      cssVars,
      onContainerClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: inline-block;
  position: relative;
  width: v-bind('cssVars.width');
  height: v-bind('cssVars.height');
  background: $f-primary-white;

  .img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 0;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    .border {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $f-trans-black-05;
      border-radius: inherit;
      box-sizing: border-box;
    }
  }

  &.clickable {
    cursor: pointer;
  }
}
</style>
