<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7015 10.6056C17.7015 13.966 14.9751 16.6901 11.6119 16.6901C8.24877 16.6901 5.52239 13.966 5.52239 10.6056C5.52239 7.24525 8.24877 4.52113 11.6119 4.52113C14.9751 4.52113 17.7015 7.24525 17.7015 10.6056ZM15.8467 16.9266C14.636 17.738 13.1793 18.2113 11.6119 18.2113C7.40798 18.2113 4 14.8061 4 10.6056C4 6.40516 7.40798 3 11.6119 3C15.8159 3 19.2239 6.40516 19.2239 10.6056C19.2239 12.6902 18.3845 14.5789 17.0251 15.9528L20.7771 19.7016C21.0743 19.9987 21.0743 20.4802 20.7771 20.7772C20.4798 21.0743 19.9978 21.0743 19.7006 20.7772L15.8467 16.9266Z"
      :fill="stroke"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconSearch',
  props: {
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
