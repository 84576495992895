<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.125 4.125C9.91789 4.125 9.75 4.29289 9.75 4.5V10.5938C9.75 10.9044 9.49816 11.1563 9.1875 11.1563C8.87684 11.1563 8.625 10.9044 8.625 10.5938V4.5C8.625 3.67157 9.29657 3 10.125 3H19.5C20.3284 3 21 3.67157 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H12.9375C12.6268 21 12.375 20.7482 12.375 20.4375C12.375 20.1268 12.6268 19.875 12.9375 19.875H19.5C19.7071 19.875 19.875 19.7071 19.875 19.5V4.5C19.875 4.29289 19.7071 4.125 19.5 4.125H10.125Z"
      fill="#FFAE88"
    />
    <path
      d="M9.5 4V11L13 14V20H20.2921L20 4H9.5Z"
      fill="#FFAE88"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9375 6.75C13.2482 6.75 13.5 7.00184 13.5 7.3125V7.32188C13.5 7.63254 13.2482 7.88438 12.9375 7.88438C12.6268 7.88438 12.375 7.63254 12.375 7.32188V7.3125C12.375 7.00184 12.6268 6.75 12.9375 6.75Z"
      fill="#1F1F1F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6875 6.75C16.9982 6.75 17.25 7.00184 17.25 7.3125V7.32188C17.25 7.63254 16.9982 7.88438 16.6875 7.88438C16.3768 7.88438 16.125 7.63254 16.125 7.32188V7.3125C16.125 7.00184 16.3768 6.75 16.6875 6.75Z"
      fill="#1F1F1F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6875 10.5C16.9982 10.5 17.25 10.7518 17.25 11.0625V11.0719C17.25 11.3825 16.9982 11.6344 16.6875 11.6344C16.3768 11.6344 16.125 11.3825 16.125 11.0719V11.0625C16.125 10.7518 16.3768 10.5 16.6875 10.5Z"
      fill="#1F1F1F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6875 14.25C16.9982 14.25 17.25 14.5018 17.25 14.8125V14.8219C17.25 15.1325 16.9982 15.3844 16.6875 15.3844C16.3768 15.3844 16.125 15.1325 16.125 14.8219V14.8125C16.125 14.5018 16.3768 14.25 16.6875 14.25Z"
      fill="#1F1F1F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.31296 10.1543C7.86079 9.716 8.63922 9.716 9.18705 10.1543L12.937 13.1543C13.2929 13.4389 13.5 13.8699 13.5 14.3256V21H4.5C3.67157 21 3 20.3284 3 19.5V14.3256C3 13.8699 3.20713 13.4389 3.56296 13.1543L7.31296 10.1543Z"
      fill="#FFD2BD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.28125 17.625C6.28125 17.3143 6.53309 17.0625 6.84375 17.0625H9.65625C9.96691 17.0625 10.2188 17.3143 10.2188 17.625C10.2188 17.9357 9.96691 18.1875 9.65625 18.1875H6.84375C6.53309 18.1875 6.28125 17.9357 6.28125 17.625Z"
      fill="#1F1F1F"
    />
    <path
      d="M3 14L7.64396 10.4617C8.00195 10.189 8.49805 10.189 8.85604 10.4617L13.5 14"
      stroke="#FC6E2E"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.29661 1.63057C6.58359 1.46488 6.95054 1.56321 7.11623 1.85018C7.28191 2.13716 7.18359 2.50411 6.89661 2.6698L6.49721 2.90039L6.89661 3.13098C7.18359 3.29667 7.28191 3.66362 7.11623 3.9506C6.95054 4.23758 6.58359 4.3359 6.29661 4.17021L5.90295 3.94293L5.90295 4.4C5.90295 4.73137 5.63432 5 5.30295 5C4.97158 5 4.70295 4.73137 4.70295 4.4L4.70295 3.93631L4.29854 4.1698C4.01156 4.33548 3.64461 4.23716 3.47892 3.95018C3.31324 3.66321 3.41156 3.29625 3.69854 3.13057L4.09721 2.90039L3.69854 2.67022C3.41156 2.50453 3.31324 2.13758 3.47892 1.8506C3.64461 1.56362 4.01156 1.4653 4.29854 1.63098L4.70295 1.86447L4.70295 1.4C4.70295 1.06863 4.97158 0.8 5.30295 0.8C5.63432 0.8 5.90295 1.06863 5.90295 1.4L5.90295 1.85785L6.29661 1.63057Z"
      fill="#C1C1C1"
    />
    <circle
      cx="6.5"
      cy="7.5"
      r="0.5"
      fill="#C1C1C1"
    />
    <circle
      cx="2.75"
      cy="6.75"
      r="0.75"
      fill="#C1C1C1"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconChristmasGroup',
  props: {
    color: {
      type: String,
      default: colors['f-primary-black'],
    },
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    type: {
      type: String,
      default: 'off',
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
