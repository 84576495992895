import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2.81483 2.66667H13.1852C14.0034 2.66667 14.6667 3.3489 14.6667 4.19048V11.8095C14.6667 12.6511 14.0034 13.3333 13.1852 13.3333H2.81483C1.99663 13.3333 1.33334 12.6511 1.33334 11.8095V4.19048C1.33334 3.3489 1.99663 2.66667 2.81483 2.66667ZM3.58142 6.23255C3.34166 6.09554 3.03623 6.17884 2.89923 6.4186C2.76222 6.65836 2.84552 6.96379 3.08528 7.10079L6.75967 9.20044C7.52825 9.63963 8.47178 9.63963 9.24036 9.20044L12.9148 7.10079C13.1545 6.96379 13.2378 6.65836 13.1008 6.4186C12.9638 6.17884 12.6584 6.09554 12.4186 6.23255L8.74422 8.3322C8.28307 8.59571 7.71695 8.59571 7.25581 8.3322L3.58142 6.23255Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}