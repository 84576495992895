export const $texts = {
  DENIED_ACCESS_TO_GUEST_AND_LEAVER:
    '패스트파이브 멤버를 위한 전용 서비스입니다.',
  DENIED_ACCESS_TO_NOT_MEMBER:
    '패스트파이브 멤버 웹 로그인이 필요한 서비스입니다. 로그인 화면으로 이동합니다.',
  DENIED_ACCESS_TO_OTHER_SERVICE:
    '패스트파이브 입주 멤버만 확인하실 수 있습니다.',
  SERVER_ERROR_OCCURRED: '서버 오류가 발생하였습니다.',
  SERVER_ERROR_OCCURRED_PLEASE_RETRY:
    '서버 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
} as const
