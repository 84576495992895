<template>
  <svg
    class="icon-button-delete"
    :class="{
      'icon-button-delete--hover': hover,
    }"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      :fill="circleColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.13807 8.19526C8.87772 7.93491 8.45561 7.93491 8.19526 8.19526C7.93491 8.45561 7.93491 8.87772 8.19526 9.13807L11.0573 12.0001L8.19548 14.8619C7.93513 15.1223 7.93513 15.5444 8.19548 15.8047C8.45583 16.0651 8.87794 16.0651 9.13829 15.8047L12.0001 12.9429L14.8619 15.8047C15.1223 16.0651 15.5444 16.0651 15.8047 15.8047C16.0651 15.5444 16.0651 15.1223 15.8047 14.8619L12.9429 12.0001L15.805 9.13807C16.0653 8.87772 16.0653 8.45561 15.805 8.19526C15.5446 7.93491 15.1225 7.93491 14.8621 8.19526L12.0001 11.0573L9.13807 8.19526Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors.module.scss'

export default defineComponent({
  name: 'IconButtonDelete',
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props) {
    const circleColor = computed(() => {
      if (props.hover) {
        return colors['grey-05']
      }

      return colors['grey-06']
    })

    return {
      circleColor,
    }
  },
})
</script>

<style lang="scss" scoped>
.icon-button-delete {
  cursor: pointer;

  circle {
    fill: $grey-06;
  }

  &:hover,
  &.icon-button-delete--hover {
    circle {
      fill: $grey-05;
    }
  }
}
</style>
