import { Plugin, inject, reactive } from 'vue'

import { ToastState } from '@/interfaces/components/common/toast'

export const toastSymbol = Symbol()

export const toastPlugin: Plugin = {
  install: (app) => {
    const state = reactive<ToastState>({
      type: 'info',
      message: '',
      timer: 0,
    })

    app.provide(toastSymbol, state)
  },
}

export const useToast = () => {
  const state = inject<ToastState>(toastSymbol)

  return {
    get show() {
      return !!state?.message
    },
    get type() {
      return state?.type ?? 'info'
    },
    get message() {
      return state?.message ?? ''
    },
    hide,
    info,
  }

  function hide() {
    if (!state) return

    window.clearTimeout(state.timer)
    state.message = ''
  }

  function info(message: string) {
    if (!state) return

    window.clearTimeout(state.timer)
    state.type = 'info'
    state.message = message

    state.timer = window.setTimeout(() => {
      hide()
    }, 2000)
  }
}
