import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lottie = _resolveComponent("Lottie")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.inline]: _ctx.inline,
    }),
    onClick: _cache[0] || (_cache[0] = (ev) => _ctx.onContainerClick(ev))
  }, [
    _createVNode(_component_Lottie, {
      class: _normalizeClass(_ctx.$style.loadingImg),
      src: _ctx.loadingImg,
      width: _ctx.size,
      height: _ctx.size,
      autoplay: "",
      loop: ""
    }, null, 8, ["class", "src", "width", "height"])
  ], 2))
}