import { Plugin } from 'vue'

import { useGlobalModal } from '@/hooks/global-modal'
import { useGlobalWriting } from '@/hooks/global-writing'
import { $texts } from '@/libs/texts'
import { parseAppLink } from '@/plugins/applink'
import router from '@/router'
import { useGlobalStore } from '@/store/modules/global'
import { useMemberGroupStore } from '@/store/modules/member-group'
import { useSessionStore } from '@/store/modules/session'

export class NavigationGuard {
  constructor() {
    return NavigationGuard
  }

  static vuePlugin: Plugin = {
    install() {
      NavigationGuard.init()
    },
  }

  static get route() {
    return router.currentRoute.value
  }

  static init() {
    NavigationGuard.guardGlobalWriting()
    NavigationGuard.guardApplink()
    NavigationGuard.guardGuestGroup()
    NavigationGuard.guardMemberRole()
    NavigationGuard.guardServiceTypes()
  }

  private static onInitializedOrChangeMemberGroup(
    cb: (nextMemberGroupId: number | null) => void,
  ) {
    const globalStore = useGlobalStore()
    const sessionStore = useSessionStore()

    let prevMemberGroupId: number | null = null

    globalStore.$subscribe((_, { isInitialized }) => {
      const sessionStore = useSessionStore()

      if (isInitialized) {
        prevMemberGroupId = sessionStore.selectedMemberGroupId
        cb(sessionStore.selectedMemberGroupId)
      }
    })

    sessionStore.$subscribe((_, { memberGroup }) => {
      const nextMemberGroupId = memberGroup?.id ?? null

      if (prevMemberGroupId && prevMemberGroupId !== nextMemberGroupId) {
        cb(nextMemberGroupId)
      }

      prevMemberGroupId = nextMemberGroupId
    })
  }

  private static guardGlobalWriting() {
    router.beforeEach((to, from, next) => {
      const globalWriting = useGlobalWriting()
      const globalModal = useGlobalModal()

      if (globalWriting.writing) {
        globalModal.open({
          message: '작성중인 내용이 삭제됩니다. \n계속하시겠습니까?',
          cancellable: true,
          onConfirm: () => {
            next()
            globalWriting.writeFinish()
          },
          onCancel: () => {
            next(false)
          },
        })
        return
      }

      next()
    })
  }

  private static guardApplink() {
    router.beforeEach((to, from, next) => {
      const link = parseAppLink(to.fullPath)

      if (link.route) {
        next(link.route)
        return
      }

      next()
    })
  }

  private static guardGuestGroup() {
    NavigationGuard.onInitializedOrChangeMemberGroup(() => {
      const sessionStore = useSessionStore()
      const globalModal = useGlobalModal()
      const { route } = NavigationGuard
      const isEnabled = sessionStore.isLogin && sessionStore.isGuestOrLeaver

      const disallowGuestGroup = route.meta.disallowGuestGroup

      if (
        !isEnabled ||
        !disallowGuestGroup ||
        typeof disallowGuestGroup === 'boolean'
      ) {
        return
      }

      router.replace(disallowGuestGroup)

      if (!globalModal.isShow) {
        globalModal.open({
          message: $texts.DENIED_ACCESS_TO_GUEST_AND_LEAVER,
        })
      }
    })

    router.beforeEach((to, from, next) => {
      const sessionStore = useSessionStore()
      const globalStore = useGlobalStore()
      const globalModal = useGlobalModal()
      const isEnabled = sessionStore.isLogin && sessionStore.isGuestOrLeaver
      const disallowGuestGroup = to.meta.disallowGuestGroup

      if (!isEnabled || !disallowGuestGroup || !globalStore.isInitialized) {
        next()
        return
      }

      if (disallowGuestGroup == true) {
        next(false)
      } else {
        next(disallowGuestGroup)
      }

      if (!globalModal.isShow) {
        globalModal.open({
          message: $texts.DENIED_ACCESS_TO_GUEST_AND_LEAVER,
        })
      }
    })
  }

  /**
   * MemberRole을 체크하고 권한이 없으면 404 리다이렉트
   */
  private static guardMemberRole() {
    NavigationGuard.onInitializedOrChangeMemberGroup((nextMemberGroupId) => {
      const sessionStore = useSessionStore()
      const { route } = NavigationGuard
      const allowedMemberRoles = route.meta.allowedMemberRoles ?? []
      const memberRole = sessionStore.memberRoleForGroup ?? 'guest'

      if (
        !nextMemberGroupId ||
        allowedMemberRoles.length === 0 ||
        allowedMemberRoles.includes(memberRole)
      ) {
        return
      }

      router.replace({
        name: 'NotFound',
        params: {
          pathMatch: route.path.substring(1).split('/'),
        },
        query: route.query,
        hash: route.hash,
      })
    })

    router.beforeEach((to, from, next) => {
      const sessionStore = useSessionStore()
      const globalStore = useGlobalStore()
      const allowedMemberRoles = to.meta.allowedMemberRoles ?? []
      const memberRole = sessionStore.memberRoleForGroup ?? 'guest'

      if (
        !globalStore.isInitialized ||
        !sessionStore.selectedMemberGroupId ||
        allowedMemberRoles.length === 0 ||
        allowedMemberRoles.includes(memberRole)
      ) {
        next()
        return
      }

      next({
        name: 'NotFound',
        params: {
          pathMatch: to.path.substring(1).split('/'),
        },
        query: to.query,
        hash: to.hash,
      })
    })
  }

  private static guardServiceTypes() {
    NavigationGuard.onInitializedOrChangeMemberGroup(
      async (nextMemberGroupId) => {
        const globalModal = useGlobalModal()
        const memberGroupStore = useMemberGroupStore()
        const { route } = NavigationGuard
        const disallowServiceTypes = route.meta.disallowServiceTypes

        if (!nextMemberGroupId || !disallowServiceTypes) {
          return
        }

        const result = await (async () => {
          try {
            await memberGroupStore.load(nextMemberGroupId)
          } catch {
            return false
          }

          return disallowServiceTypes?.(memberGroupStore.serviceTypes) || false
        })()

        if (!result) {
          return
        }

        router.replace(result.route)

        globalModal.open({
          message: result.message || $texts.DENIED_ACCESS_TO_OTHER_SERVICE,
        })
      },
    )

    router.beforeEach(async (to, from, next) => {
      const sessionStore = useSessionStore()
      const globalModal = useGlobalModal()
      const globalStore = useGlobalStore()
      const memberGroupStore = useMemberGroupStore()
      const nextMemberGroupId = sessionStore.selectedMemberGroupId
      const disallowServiceTypes = to.meta.disallowServiceTypes

      if (
        !globalStore.isInitialized ||
        !nextMemberGroupId ||
        !disallowServiceTypes
      ) {
        next()
        return
      }

      const result = await (async () => {
        try {
          await memberGroupStore.load(nextMemberGroupId)
        } catch {
          return false
        }

        return disallowServiceTypes?.(memberGroupStore.serviceTypes) || false
      })()

      if (!result) {
        next()
        return
      }

      next(result.route)

      globalModal.open({
        message: result.message || $texts.DENIED_ACCESS_TO_OTHER_SERVICE,
      })
    })
  }
}
