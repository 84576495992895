import { Plugin } from 'vue'

import { hexToRGBA, withPx } from '@/libs/css'
import { numberFormat, numberFormatWithWBR } from '@/libs/number'

export const globalUtilsPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.withPx = withPx
    app.config.globalProperties.hexToRGBA = hexToRGBA
    app.config.globalProperties.numberFormat = numberFormat
    app.config.globalProperties.numberFormatWithWBR = numberFormatWithWBR
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    withPx: typeof withPx
    hexToRGBA: typeof hexToRGBA
    numberFormat: typeof numberFormat
    numberFormatWithWBR: typeof numberFormatWithWBR
  }
}
