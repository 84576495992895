export const createService = (client) => {
  return {
    // TODO: 브라우저 버전 얻어와야함
    // async getVersion(version) {
    //   return client.get('/mobile/version', {
    //     params: {
    //       version,
    //       osType: Platform.OS,
    //     },
    //   });
    // },
  }
}
