import { defineStore } from 'pinia'

import { GLOBAL_WRITING } from '@/store/keys'

export const useGlobalWritingStore = defineStore(GLOBAL_WRITING, {
  state: () => ({
    writing: false,
  }),
  actions: {
    writeStart() {
      this.writing = true
    },
    writeFinish() {
      this.writing = false
    },
  },
})
