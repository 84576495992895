import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      class: _normalizeClass(["caret-icon", [`icon-caret--direction--${_ctx.direction}`]]),
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.92186 7.23178C5.44211 7.63157 5.44211 8.36843 5.92187 8.76822L9.35982 11.6332C10.0111 12.176 11 11.7128 11 10.865L11 5.13504C11 4.2872 10.0111 3.82405 9.35982 4.36682L5.92186 7.23178Z",
      fill: _ctx.color
    }, null, 10, _hoisted_2)
  ], 8, _hoisted_1))
}