import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { Inquiry } from '@/interfaces/api/inquiry'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createInquiryService = (client: AxiosCacheInstance) => {
  return {
    async getServiceField(payload: Inquiry.API.GetInquiryField.Request) {
      return axiosHandler<Inquiry.API.GetInquiryField.Response>(() => {
        return client.post(`/mobile/inquiries/categories`, payload)
      })
    },
    async createInquiry(payload: Inquiry.API.CreateInquiry.Request) {
      return axiosHandler<Inquiry.API.CreateInquiry.Response>(() => {
        return client.post(`/mobile/inquiries`, payload)
      })
    },
    async uploadFiles(form) {
      return axiosHandler<Inquiry.API.UploadFiles.Response>(() => {
        return client.post(`/file/salesforce`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'attachment',
          },
        })
      })
    },
    async getInquiryList() {
      return axiosHandler<Inquiry.API.GetInquiryList.Response>(() => {
        return client.get(`/mobile/inquiries?ServiceType=FastFive`)
      })
    },
    async getInquiryDetail(CaseId: string) {
      return axiosHandler<Inquiry.API.GetInquiryDetail.Response>(() => {
        return client.get(`/mobile/inquiries/${CaseId}`)
      })
    },
    async createInquiryComment(
      CaseId: string,
      payload: Inquiry.API.CreateInquiryComment.Request,
    ) {
      return axiosHandler<Inquiry.API.CreateInquiryComment.Response>(() => {
        return client.post(`/mobile/inquiries/${CaseId}/comments`, payload)
      })
    },
  }
}
