<template>
  <div :class="$style.container">
    <router-link :to="{ name: hasProfile ? 'Home' : 'SignIn' }">
      <div
        :class="$style.logo"
        @click="$emit('click:logo')"
      >
        <img
          :src="images.logo"
          alt="logo"
        />
      </div>
    </router-link>

    <div
      v-if="!noMenu"
      :class="$style.headerMenu"
    >
      <template v-if="hasProfile && sessionStore.isLogin">
        <SelectBox
          v-model:open="state.isShowProfile"
          :class="$style.item"
          popupPosition="right"
          @update:open="onProfileMenuClick"
        >
          <template v-slot>
            <div :class="[$style.label, $style.ellipsis]">
              {{ memberGroup ? memberGroup.name : '소속된 그룹이 없습니다' }}
            </div>
          </template>
          <template v-slot:popup="{ onInsideClick }">
            <AccountPopup
              :profileThumbnail="user?.imageUrl"
              :username="user?.name"
              :occupation="user?.occupation"
              :email="user?.email"
              :memberGroups="memberGroups"
              :selectedMemberGroupId="selectedMemberGroupId"
              @click="onInsideClick"
              @click:logout="onLogoutClick"
              @click:my-page="onMyPageClick"
              @click:reset-password="onResetPasswordClick"
              @change:member-group="onMemberGroupChange"
              @click:invite="onInvitationClick"
            />
          </template>
        </SelectBox>
      </template>
    </div>

    <Modal :isShow="state.isShowInviteModal">
      <div :class="$style.invitation">
        <div :class="$style.invitationCard">
          <div :class="$style.title">초대 링크 입력</div>
          <div :class="$style.description">
            관리 멤버에게 받은 초대 링크를 복사하여 아래 입력해 주세요.
          </div>
          <InputComponent
            v-model="state.invitationCode"
            :class="$style.inputInvitationCode"
            placeholder="https://fastfive.app.link/invitation?code=abcde23456"
            @validate="(vaild) => (state.validInvitationCode = vaild)"
            :validator="invitationCodeValidator"
          />
          <span
            :class="$style.buttonQuestionInvitationCode"
            @click="moveFaqInvitationCode"
          >
            <img
              :src="images.iconQuestion"
              alt=""
            />
            <span>유효하지 않은 초대링크라고 뜨나요?</span>
          </span>
          <div :class="$style.buttonArea">
            <div :class="$style.buttonBottom">
              <button-component
                text="취소"
                type="outline"
                @submit="onInvitationCancel"
                :fontSize="15"
                :height="39"
              />
            </div>
            <div :class="$style.buttonBottom">
              <button-component
                text="다음"
                :is-disabled="!state.validInvitationCode"
                @submit="onInvitationAccept"
                :fontSize="15"
                :height="39"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal
      v-if="state.isShowJoinGroupModal"
      :is-show="state.isShowJoinGroupModal"
    >
      <suspense>
        <invitation-container
          :invitation-code="state.invitationCode"
          rejectedModalTitle="소속/회사 정보가 다르군요!"
          @complete="onJoinGroupComplete"
          @invalid="
            () => {
              state.isShowJoinGroupModal = false
              state.isShowInviteModal = true
            }
          "
        />
      </suspense>
    </Modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import ButtonComponent from '@/components/common/Button.vue'
import InputComponent from '@/components/common/Input.vue'
import Modal from '@/components/common/Modal/index.vue'
import AccountPopup from '@/components/v2/dropdown/AccountPopup.vue'
import SelectBox from '@/components/v2/dropdown/SelectBox.vue'
import InvitationContainer from '@/containers/user/Invitation.vue'

import { onPopstateEvent } from '@/hooks/on-popstate-event'
import { SessionStore } from '@/interfaces/store/session'
import { useSessionStore } from '@/store/modules/session'

const images = {
  logo: require('@/assets/images/logo/logo_fastfive.png'),
  iconQuestion: require('@/assets/images/icon/icon_question.png'),
}

export default defineComponent({
  components: {
    InputComponent,
    Modal,
    ButtonComponent,
    InvitationContainer,
    SelectBox,
    AccountPopup,
  },
  props: {
    hasProfile: {
      type: Boolean,
      default: true,
    },
    noMenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'click:logo': null,
    'click:logout': null,
    'click:my-page': null,
    'click:reset-password': null,
    'click:invitation': null,
    'click:profile-menu': (isShowProfile) => {
      if (typeof isShowProfile === 'boolean') {
        return true
      }
      console.warn('Invalid click:profile-menu event payload!')
      return false
    },
    'change:member-group': null,
  },
  setup(props, { emit }) {
    const router = useRouter()
    const sessionStore = useSessionStore()

    const state = reactive({
      invitationCode: '',
      validInvitationCode: false,
      isShowProfile: false,
      isShowInviteModal: false,
      isShowJoinGroupModal: false,
    })

    const user = computed(() => sessionStore?.user ?? null)

    const selectedBranch = computed(() => sessionStore.selectedBranch)

    const selectedBranchId = computed(() => selectedBranch.value?.id)

    const memberGroup = computed(() => sessionStore?.memberGroup ?? null)

    const memberGroups = computed(() => sessionStore?.memberGroups ?? [])

    const selectedMemberGroupId = computed(() => {
      return memberGroup.value?.id
    })

    onPopstateEvent(() => {
      state.invitationCode = ''
      state.isShowInviteModal = false
      state.isShowJoinGroupModal = false
      state.isShowProfile = false
      state.validInvitationCode = false
    })

    function invitationCodeValidator(v) {
      return !!v?.match(
        /^(?:https?:\/\/fastfive\.app\.link\/invitation\?(?:.+&)?code=)?([abcdefghjklmnpqrstuvwxyz23456789]{10,})(?:&.+)?$/,
      )
    }

    async function onLogoutClick() {
      state.isShowProfile = false

      emit('click:logout')

      try {
        await sessionStore.signOut()
      } finally {
        await router.replace({
          name: 'SignIn',
        })
      }
    }

    function onMyPageClick() {
      state.isShowProfile = false

      emit('click:my-page')

      router.push({
        name: 'MyPage',
      })
    }

    function onResetPasswordClick() {
      state.isShowProfile = false

      emit('click:reset-password')

      router.push({
        name: 'ResetPassword',
      })
    }

    function onMemberGroupChange(
      group: SessionStore.State.MemberGroupListItem,
    ) {
      emit('change:member-group')

      sessionStore
        .selectDefaultMemberGroupSetting({
          memberGroupId: group.memberGroupId,
          branchId: selectedBranchId.value,
        })
        .catch(() => {
          // 일반적으로 세션 만료로 인한 오류가 발생
          // 500번대 에러일 수도 있지만 대응하지 않음
        })
        .finally(() => {
          state.isShowProfile = false
        })
    }

    function onInvitationClick() {
      state.isShowProfile = false
      state.isShowInviteModal = true

      emit('click:invitation')
    }

    function onInvitationCancel() {
      state.invitationCode = ''
      state.isShowInviteModal = false
    }

    function onInvitationAccept() {
      state.isShowInviteModal = false
      state.isShowJoinGroupModal = true
    }

    function onJoinGroupComplete(isJoined) {
      if (isJoined) {
        sessionStore
          .initializeSession()
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            state.invitationCode = ''
            state.isShowJoinGroupModal = false
          })
      } else {
        state.invitationCode = ''
        state.isShowJoinGroupModal = false
      }
    }

    function onProfileMenuClick() {
      emit('click:profile-menu', state.isShowProfile)
    }

    function moveFaqInvitationCode() {
      const routeData = router.resolve({
        name: 'FaqDetail',
        params: {
          KnowledgeId: 'Ka05g000001ixqD',
        },
        query: {
          title: '초대 링크 입력 시, 유효하지 않은 초대 링크라고 뜹니다.',
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      images,
      sessionStore,
      state,
      user,
      selectedBranch,
      selectedBranchId,
      memberGroup,
      memberGroups,
      selectedMemberGroupId,
      invitationCodeValidator,
      onLogoutClick,
      onMyPageClick,
      onResetPasswordClick,
      onMemberGroupChange,
      onInvitationClick,
      onInvitationCancel,
      onInvitationAccept,
      onJoinGroupComplete,
      onProfileMenuClick,
      moveFaqInvitationCode,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  height: var(--header-height);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $f-trans-gray-10;
  backdrop-filter: blur(12px);
  border-bottom: 1px solid $f-gray-20;
  z-index: 101;

  .logo {
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 120px;
    }
  }

  .headerMenu {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .item {
      margin-right: 8px;

      .label {
        min-width: 0;

        &.ellipsis {
          max-width: 216px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .icon {
        margin-right: 4px;
      }
    }
  }
}

.invitation {
  width: 372px;

  .invitationCard {
    background-color: $white;
    padding: 40px 40px 20px;
    text-align: center;
    border: 1px solid $primary-black;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .title {
      @include font_ko(20px, 400);
      color: $primary-black;
      padding-bottom: 12px;
    }

    .description {
      @include font_ko(14px, 400);
      color: $grey-01;
    }

    .inputInvitationCode {
      margin-top: 52px;
    }

    .buttonQuestionInvitationCode {
      margin-top: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include font_ko(12px, 400);
      color: $point2;
      text-decoration: none;

      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      span {
        padding-bottom: 2px;
      }
    }

    .buttonArea {
      margin-top: 54px;
      display: flex;
      justify-content: center;

      .buttonBottom {
        width: 100px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
