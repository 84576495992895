<template>
  <div
    class="icon-chevron"
    :class="[`icon-chevron--direction--${direction}`]"
    :style="styles"
  >
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.22781 8.23431C5.53155 7.92189 6.02401 7.92189 6.32775 8.23431L12 14.0686L17.6723 8.23431C17.976 7.92189 18.4685 7.92189 18.7722 8.23431C19.0759 8.54673 19.0759 9.05326 18.7722 9.36568L12.55 15.7657C12.2462 16.0781 11.7538 16.0781 11.45 15.7657L5.22781 9.36568C4.92406 9.05327 4.92406 8.54673 5.22781 8.23431Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors.module.scss'
import { withPx } from '@/libs/css'

export default defineComponent({
  name: 'IcnChevron',
  props: {
    color: {
      type: String,
      default: colors['grey-04'],
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    direction: {
      type: String as PropType<'up' | 'down' | 'left' | 'right'>,
      default: 'down',
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        width: withPx(props.width),
        height: withPx(props.height),
      }
    })

    return {
      styles,
    }
  },
})
</script>

<style lang="scss" scoped>
.icon-chevron {
  display: inline-block;

  &.icon-chevron--direction--up {
    svg {
      transform: rotate(180deg);
    }
  }

  &.icon-chevron--direction--left {
    svg {
      transform: rotate(90deg);
    }
  }

  &.icon-chevron--direction--right {
    svg {
      transform: rotate(-90deg);
    }
  }
}
</style>
