import { IBranch } from '@/interfaces/api/branch'
import { BadgeV2 } from '@/interfaces/components/v2/badge'
import { fromKST } from '@/libs/date'

export namespace Notice {
  /**
   * 게시 상태. (1: 예약, 5: 게시, 9: 종료)
   */
  export enum PublishStatus {
    RESERVED = 1,
    PUBLISHED = 5,
    FINISHED = 9,
  }

  /**
   * 중요도
   */
  export enum Priority {
    CRITICAL = 'critical',
    ALERT = 'alert',
    GENERAL = 'general',
  }

  export type ParsedNoticeListItem = (
    | IBranch.NoticeListItem
    | IBranch.NoticeDetailItem
  ) & {
    _: {
      date: string
      badgeColor: BadgeV2.Color
      badgeText: string
    }
  }

  export const getPublishStatusOption = (
    publishStatus: PublishStatus,
    fallbackPublishStatus: PublishStatus | null = null,
  ): {
    id: PublishStatus
    label: string
  } => {
    switch (publishStatus) {
      case PublishStatus.RESERVED:
        return {
          id: PublishStatus.RESERVED,
          label: '예약',
        }

      case PublishStatus.PUBLISHED:
        return {
          id: PublishStatus.PUBLISHED,
          label: '게시',
        }

      case PublishStatus.FINISHED:
        return {
          id: PublishStatus.FINISHED,
          label: '종료',
        }

      default: {
        if (fallbackPublishStatus === null) {
          throw new Error(
            `정의되지 않은 게시 상태입니다. publishStatus=${publishStatus}`,
          )
        }

        return getPublishStatusOption(fallbackPublishStatus)
      }
    }
  }

  export const getPriorityOption = (
    priority: Priority,
    fallbackPriority: Priority | null = null,
  ): {
    id: Priority
    label: string
    badgeColor: BadgeV2.Color
  } => {
    switch (priority) {
      case Priority.CRITICAL:
        return {
          id: Priority.CRITICAL,
          label: '긴급',
          badgeColor: 'f-warning-red-10',
        }

      case Priority.ALERT:
        return {
          id: Priority.ALERT,
          label: '주의',
          badgeColor: 'f-secondary-extention-orange-10',
        }

      case Priority.GENERAL:
        return {
          id: Priority.GENERAL,
          label: '일반',
          badgeColor: 'f-trans-black-03',
        }

      default: {
        if (fallbackPriority === null) {
          throw new Error(`정의되지 않은 게시 상태입니다. priority=${priority}`)
        }

        return getPriorityOption(fallbackPriority)
      }
    }
  }

  export const parseNoticeListItem = (
    item: IBranch.NoticeListItem | IBranch.NoticeDetailItem,
  ): ParsedNoticeListItem => {
    const date = fromKST(item.startAt).format('YYYY년 M월 D일')
    const option = getPriorityOption(item.priority, Priority.GENERAL)
    const badgeColor = option.badgeColor
    const badgeText = option.label

    return {
      ...item,
      _: {
        date,
        badgeColor,
        badgeText,
      },
    }
  }
}
