<template>
  <div ref="rootEl">
    <slot />
  </div>
</template>

<script lang="ts">
import SimpleBar from 'simplebar'
import { SimpleBarOptions } from 'simplebar-core'
import { PropType, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'

export default defineComponent({
  props: {
    option: {
      type: null as unknown as PropType<SimpleBarOptions>,
    },
  },
  emits: {
    scroll: (ev: Event) => !!ev,
  },
  setup(props, { emit }) {
    const simpleBar = ref<SimpleBar>()
    const rootEl = ref<HTMLDivElement>()
    const scrollEl = ref<HTMLElement>()

    onMounted(() => {
      if (rootEl.value) {
        simpleBar.value = new SimpleBar(rootEl.value, props.option)
        scrollEl.value = simpleBar.value.getScrollElement() ?? undefined
        scrollEl.value?.addEventListener('scroll', onScroll)
      }
    })

    onBeforeUnmount(() => {
      scrollEl.value?.removeEventListener('scroll', onScroll)
      simpleBar.value?.unMount()
    })

    function onScroll(ev: Event) {
      emit('scroll', ev)
    }

    return {
      simpleBar,
      rootEl,
      scrollEl,
    }
  },
})
</script>
