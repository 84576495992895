import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { BenefitRegionGetListApiResponse } from '@/interfaces/api/benefits/region'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createBenefitRegionService = (client: AxiosCacheInstance) => {
  return {
    async getList() {
      return await axiosHandler<BenefitRegionGetListApiResponse>(() => {
        return client.get(`/mobile/benefit/region`)
      })
    },
  }
}
