import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IcnRecomm = _resolveComponent("IcnRecomm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container),
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createVNode(_component_IcnRecomm, {
      class: _normalizeClass(_ctx.$style.icon),
      color: _ctx.colors.white
    }, null, 8, ["class", "color"]),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.message)
    }, _toDisplayString(_ctx.message), 3)
  ], 6))
}