import { createApp } from 'vue'

import { usePageTimeTracker } from './hooks/use-page-viewtime-tracker'

import App from '@/App.vue'
import { IS_SERVE } from '@/env'
import { applinkPlugin } from '@/plugins/applink'
import { clipboardPlugin } from '@/plugins/clipboard'
import { colorPlugin } from '@/plugins/color'
import { datadog, datadogPlugin } from '@/plugins/datadog'
import { dayjsPlugin } from '@/plugins/dayjs'
import firebasePlugin, { analytics } from '@/plugins/firebase'
import { globalLoadingPlugin } from '@/plugins/global-loading'
import { globalModalPlugin } from '@/plugins/global-modal'
import { globalUtilsPlugin } from '@/plugins/globalUtils'
import '@/plugins/simplebar'
import '@/plugins/tailwind'
import { textsPlugin } from '@/plugins/texts'
import { toastPlugin } from '@/plugins/toast'
import '@/registerServiceWorker'
import router from '@/router'
import { NavigationGuard } from '@/router/navigation-guard'
import store from '@/store'

/**
 * 페이지 머문 시간 측정 hook 불러오기
 */
const { initializePageTimeTracker, cleanupPageTimeTracker } =
  usePageTimeTracker(router)
initializePageTimeTracker()
const app = createApp(App)

app
  .use(store)
  .use(datadogPlugin)
  .use(applinkPlugin)
  .use(firebasePlugin)
  .use(dayjsPlugin)
  .use(globalUtilsPlugin)
  .use(toastPlugin)
  .use(colorPlugin)
  .use(clipboardPlugin)
  .use(router)
  .use(globalModalPlugin)
  .use(globalLoadingPlugin)
  .use(textsPlugin)
  .use(NavigationGuard.vuePlugin)
  .mount('#app')

app.config.errorHandler = (err, instance, info) => {
  if (IS_SERVE) {
    console.log('instance', instance)
    console.log('info', info)
    console.error('err', err)
    cleanupPageTimeTracker()
  } else {
    datadog.addError(err)
  }
}
