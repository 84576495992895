export const createService = (client) => {
  return {
    async getSetting() {
      return client.get('/mobile/user/memberSetting')
    },
    async setPush(isPushAgreed) {
      return client.put('/mobile/user/memberSetting/push', {
        isPushAgreed,
      })
    },
    async setMarketing(isMarketingAgreed) {
      return client.put('/mobile/user/memberSetting/marketing', {
        isMarketingAgreed,
      })
    },
  }
}
