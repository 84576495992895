<template>
  <div
    class="ff-tag"
    :class="{
      [`tag--theme--${tabTheme}`]: true,
      'tag--selected': selected,
      'tag--clickable': isClickable,
    }"
  >
    <div
      class="tag--container"
      @click="() => isClickable && onClick()"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

import { TagTheme } from '@/interfaces/components/common/tag'

export default defineComponent({
  name: 'Tag',
  props: {
    theme: {
      type: String as PropType<TagTheme>,
      default: 'light',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    onClick: Function,
  },
  setup(props) {
    const tabTheme = computed(() => {
      const defaultTheme = 'light'
      const themes = ['light', 'grey', 'orange'] as TagTheme[]

      return themes.includes(props.theme) ? props.theme : defaultTheme
    })

    const isClickable = computed(() => typeof props.onClick === 'function')

    return {
      tabTheme,
      isClickable,
    }
  },
})
</script>

<style lang="scss" scoped>
.ff-tag {
  display: inline-block;

  .tag--container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    color: $grey-02;
    border-radius: 18.5px;
    @include font_ko(14px, 400);

    &:hover {
      opacity: 0.8;
    }
  }

  &.tag--clickable {
    .tag--container {
      cursor: pointer;
    }
  }

  &.tag--theme--light {
    .tag--container {
      background-color: $grey-07;
    }
  }

  &.tag--theme--grey {
    .tag--container {
      background-color: $grey-06;
    }
  }

  &.tag--theme--orange {
    .tag--container {
      background-color: $orange100;
      color: $point1;
    }
  }

  &:active,
  &.tag--selected {
    .tag--container {
      background-color: $primary-black;
      color: $white;

      &:hover {
        opacity: 1;
      }
    }

    &.tag--theme--orange {
      .tag--container {
        background-color: $point1;
        color: $white;
      }
    }
  }
}
</style>
