<template>
  <button
    class="button"
    :class="{
      [type]: !!type,
      'button--inline': inline,
    }"
    :disabled="isDisabled"
    :style="cssVars"
    @click="$emit('submit')"
  >
    <template v-if="$slots.pre">
      <span class="button--contents button--pre">
        <slot
          name="pre"
          v-bind="slotScope"
        />
      </span>
    </template>
    <span class="button--contents">
      <slot v-bind="slotScope">{{ text }}</slot>
    </span>
    <template v-if="$slots.post">
      <span class="button--contents button--post">
        <slot
          name="post"
          v-bind="slotScope"
        />
      </span>
    </template>
  </button>
</template>

<script>
import _ from 'lodash'

import colors from '@/assets/scss/base/colors.module.scss'
import { hexToRGBA } from '@/libs/css'

export default {
  props: {
    inputType: {
      type: String,
      validator: (v) => _.includes(['button', 'submit', 'reset'], v),
      default: 'button',
    },
    type: {
      type: String,
      validator: (v) => _.includes(['fill', 'outline', 'transparent'], v),
      default: 'fill',
    },
    color: {
      type: String,
      default: 'primary-black',
    },
    fontColor: {
      type: String,
      default: 'white',
    },
    text: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 44,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slotScope() {
      let bgColor = colors[this.color] ?? this.color
      let fontColor = colors[this.fontColor] ?? this.fontColor

      if (this.type === 'transparent') {
        fontColor = bgColor
        bgColor = hexToRGBA(fontColor, 0.1)

        if (this.isDisabled) {
          fontColor = colors['grey-05']
          bgColor = 'transparent'
        }
      }

      return {
        bgColor,
        fontColor,
      }
    },
    cssVars() {
      return {
        '--font-size': `${this.fontSize}px`,
        '--bg-color': this.slotScope.bgColor,
        '--font-color': this.slotScope.fontColor,
        '--padding-bottom': `${this.fontSize > 14 ? 2 : 1}px`,
        height: `${this.height}px`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--bg-color);
  padding: 0 12px var(--padding-bottom) 12px;
  border-radius: 4px;
  border: none;

  .button--contents {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include font_ko(16px, 400);
    font-size: var(--font-size);
    color: var(--font-color);
  }

  &.button--inline {
    display: inline-flex;
    width: auto;
  }

  &.fill {
    &:hover:not(:disabled) {
      opacity: 0.8;
    }
    &:disabled {
      background-color: $grey-05;
      .button--contents {
        opacity: 0.6;
      }
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid var(--bg-color);
    .button--contents {
      color: var(--bg-color);
    }
    &:hover:not(:disabled) {
      background-color: rgba(31, 31, 31, 0.05);
      opacity: 0.8;
    }
    &:disabled {
      border: 1px solid $grey-05;
      .button--contents {
        color: $grey-05;
      }
    }
  }

  &.transparent {
    background-color: transparent;
    border: 0;

    &:hover:not(:disabled) {
      background-color: var(--bg-color);
      opacity: 1;

      .button--contents {
        color: var(--font-color);
      }
    }

    &:disabled {
      background-color: transparent;

      .button--contents {
        color: var(--font-color);
      }
    }
  }
}
</style>
