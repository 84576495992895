import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCaret = _resolveComponent("IconCaret")!
  const _component_DropdownList = _resolveComponent("DropdownList")!
  const _component_ClickArea = _resolveComponent("ClickArea")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.clickable]: _ctx.isClickable,
    })
  }, [
    _createVNode(_component_ClickArea, { "onClick:outside": _ctx.onOutsideClick }, {
      default: _withCtx(({ onInsideClick }) => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.overlay)
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.wrapper),
          style: _normalizeStyle(_ctx.computedWrapperStyles),
          onClick: 
          () => {
            _ctx.onClick()
            onInsideClick()
          }
        ,
          onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
          onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
          onMouseup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args))),
          onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default", {
            slotStyle: _ctx.$style.slotStyle,
            selectedOption: _ctx.selectedOption,
            cssVars: _ctx.cssVars
          }, () => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.slotStyle)
            }, [
              (_ctx.selectedOption)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.selectedOption.label), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
                  ], 64))
            ], 2)
          ]),
          (!_ctx.readonly)
            ? (_openBlock(), _createBlock(_component_IconCaret, {
                key: 0,
                color: _ctx.cssVars.caretColor,
                class: _normalizeClass(_ctx.$style.slotStyle),
                direction: _ctx.state.isOpen ? 'up' : 'down'
              }, null, 8, ["color", "class", "direction"]))
            : _createCommentVNode("", true)
        ], 46, _hoisted_1),
        _createVNode(_Transition, null, {
          default: _withCtx(() => [
            (_ctx.state.isOpen)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.popup),
                  onClick: onInsideClick
                }, [
                  _renderSlot(_ctx.$slots, "popup", {
                    onInsideClick: onInsideClick,
                    onOutsideClick: _ctx.onOutsideClick
                  }, () => [
                    _createVNode(_component_DropdownList, {
                      modelValue: _ctx.state.selectedValue,
                      options: _ctx.options,
                      width: _ctx.dropdownListWidth,
                      searchable: _ctx.searchable,
                      searchInputPlaceholder: _ctx.searchInputPlaceholder,
                      searchValue: _ctx.searchValue,
                      noticeText: _ctx.noticeText,
                      onSelect: _ctx.onSelect,
                      "onUpdate:searchValue": _cache[4] || (_cache[4] = (val) => _ctx.$emit('update:searchValue', val))
                    }, null, 8, ["modelValue", "options", "width", "searchable", "searchInputPlaceholder", "searchValue", "noticeText", "onSelect"])
                  ])
                ], 10, _hoisted_2))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 3
    }, 8, ["onClick:outside"])
  ], 2))
}