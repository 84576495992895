import fastfive from '@fastfive-dev/frontend-library/dist/fastfive'
import dayjs, { ConfigType } from 'dayjs'

import '@/plugins/dayjs'
// 플러그인 import가 누락될 수도 있어서 방어코드 추가
import { Timezone } from '@/plugins/dayjs'

/**
 * dayjs를 한국 시간으로만 사용할 때 사용
 *
 * @see https://day.js.org/docs/en/timezone/converting-to-zone
 */
export function fromKST(date?: ConfigType) {
  // 입력값은 항상 KST. timezone만 바꿔줌
  if (!!date) {
    return dayjs(date).tz(Timezone['Asia/Seoul'], true)
  }

  // 입력값이 없거나 잘못된 경우 디바이스 타임존이 적용되기때문에 offset을 KST로 변경함
  return dayjs(date).tz(Timezone['Asia/Seoul'])
}

/**
 * 입력값을 무조건 UTC라고 판단하고 KST로 변경하는 함수
 *
 * - 입력값이 UTC인경우 사용
 *
 * @see https://day.js.org/docs/en/timezone/converting-to-zone
 */
export function fromUTC(date?: ConfigType) {
  return dayjs.utc(date).tz(Timezone['Asia/Seoul'])
}

/**
 * 생년월일 입력 포맷을 ISO 포맷으로 변환하는 함수
 *
 * 생년월일이 유효하지 않은 경우 null을 반환합니다.
 *
 * - 생년월일 포맷 : YYYY. MM. DD
 * - 반환되는 포맷 : YYYY-MM-DD
 */
export function birthdayFormatToISO(input: string | null | undefined = null) {
  if (!input || typeof input !== 'string') {
    return null
  }

  const [year, month, day] = input.split('. ').map((row) => Number(row))

  const birthday = fromKST(new Date(year, month - 1, day))

  if (!birthday.isValid()) {
    return null
  }

  return birthday.format('YYYY-MM-DD')
}

export const parseTimestamp = fastfive.utils.date.parseTimestamp

export const reservationTimeFormat = ({
  startAt,
  endAt,
  format,
}: {
  startAt: dayjs.Dayjs
  endAt: dayjs.Dayjs
  format:
    | 'H:mm - H:mm'
    | 'A h:mm - A h:mm'
    | 'YYYY.MM.DD dd H:mm - H:mm'
    | 'YYYY. M. D\nH:mm - H:mm'
}) => {
  const separator = ' - '
  const isMidnight = startAt.date() !== endAt.date()

  let [startFormat, endFormat] = format.split(separator)

  if (isMidnight) {
    endFormat = endFormat.replace(/H/g, 'k')
  }

  return [startAt.format(startFormat), endAt.format(endFormat)].join(separator)
}
