import { CACHE } from '@/libs/storage/session-keys'

export class CacheHelper<T = any> {
  static clear() {
    for (let index = 0; index < sessionStorage.length; index++) {
      const storageKey = sessionStorage.key(index)

      if (storageKey?.startsWith(CACHE)) {
        sessionStorage.removeItem(storageKey)
      }
    }
  }

  static get definedKeys() {
    return {
      RESERVE_PAGE_SPACE_CATEGORY_STATE: 'RESERVE_PAGE_SPACE_CATEGORY_STATE',
    }
  }

  getSessionStorageKey(key: ICacheHelper.Key) {
    return `${CACHE}:${key}`
  }

  getItem(key: ICacheHelper.Key): ICacheHelper.CacheData<T> | null {
    try {
      this.removeExpiredData(key)

      const storageKey = this.getSessionStorageKey(key)
      const cacheData = JSON.parse(
        sessionStorage.getItem(storageKey) || 'null',
      ) as ICacheHelper.CacheData<T> | null

      return cacheData
    } catch {
      return null
    }
  }

  setItem(
    key: ICacheHelper.Key,
    value: T,
    /**
     * TTL
     * - 단위 ms
     * - 기본값 300000(5분)
     */
    ttl = 300000,
  ) {
    const storageKey = this.getSessionStorageKey(key)
    const cacheData: ICacheHelper.CacheData<T> = {
      value,
      createdAt: Date.now(),
      ttl,
    }

    sessionStorage.setItem(storageKey, JSON.stringify(cacheData))

    setTimeout(() => {
      this.removeExpiredData(key)
    }, ttl)
  }

  removeItem(key: ICacheHelper.Key) {
    const storageKey = this.getSessionStorageKey(key)
    sessionStorage.removeItem(storageKey)
  }

  removeExpiredData(key: ICacheHelper.Key) {
    const cacheData = this.getItem(key)
    const now = Date.now()

    if (cacheData && now - cacheData.createdAt > cacheData.ttl) {
      this.removeItem(key)
    }
  }
}

export namespace ICacheHelper {
  export type Key = string | number

  export interface CacheData<T = any> {
    value: T
    createdAt: number
    ttl: number
  }
}
