export const EXAMPLE = 'EXAMPLE'

export const MEMBER_GROUP = 'MEMBER_GROUP'

export const GLOBAL = 'GLOBAL'

export const GLOBAL_MODAL = 'GLOBAL_MODAL'

export const NETWORK_ERROR = 'NETWORK_ERROR'

export const GLOBAL_LOADING = 'GLOBAL_LOADING'

export const GLOBAL_WRITING = 'GLOBAL_WRITING'

export const KEEP_ALIVE = 'KEEP_ALIVE'

export const BENEFIT_SEARCH_MODAL = 'BENEFIT_SEARCH_MODAL'

export const BENEFIT_CATEGORY = 'BENEFIT_CATEGORY'

export const SESSION = 'SESSION'

export const APP_CONFIG = 'APP_CONFIG'

export const HOME_POPUP = 'HOME_POPUP'

export const PAYMENT = 'PAYMENT'
