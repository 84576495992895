import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.contents)
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.$style.image),
        src: _ctx.images.error
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title)
      }, "앗! 요청하신 정보를 불러오지 못했습니다.", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.subtitle)
      }, "다시 한번 시도해 주세요.", 2),
      _createVNode(_component_Button, {
        class: _normalizeClass(_ctx.$style.btn),
        frame: "flexable",
        theme: "border-gray-35",
        size: "large",
        onClick: _cache[0] || (_cache[0] = () => _ctx.onRetryClick())
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" 새로고침 ")
        ])),
        _: 1
      }, 8, ["class"])
    ], 2)
  ], 2))
}