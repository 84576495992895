import { AxiosCacheInstance } from 'axios-cache-interceptor'

import { Faq } from '@/interfaces/api/faq'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createFAQService = (client: AxiosCacheInstance) => {
  return {
    async getFAQ(keyword: string) {
      return axiosHandler<Faq.API.GetFAQ.Response>(() => {
        return client.get('/mobile/faq', {
          params: {
            SearchKeyword: keyword,
            ServiceType: 'Fastfive',
          },
        })
      })
    },
    async getCategories(serviceType: string) {
      return axiosHandler<Faq.API.GetCategories.Response>(() => {
        return client.get('/mobile/faq/categories', {
          params: {
            ServiceType: serviceType,
          },
        })
      })
    },
  }
}
