import { onBeforeUnmount, onMounted } from 'vue'

type IPopstateListener = typeof window.addEventListener<'popstate'>

type IParamas = Parameters<IPopstateListener>

export function onPopstateEvent(listener: IParamas[1], options?: IParamas[2]) {
  onMounted(() => {
    window.addEventListener('popstate', listener, options)
  })

  onBeforeUnmount(() => {
    remove()
  })

  function remove() {
    window.removeEventListener('popstate', listener, options)
  }

  return {
    remove,
  }
}
