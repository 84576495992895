import { AxiosCacheInstance } from 'axios-cache-interceptor'

import {
  BenefitGetListApiPayload,
  BenefitGetListApiResponse,
  BenefitGetOneApiResponse,
  BenefitGetPlusListApiPayload,
  BenefitGetRecommendListApiPayload,
  BenefitRequestPrivateCodeApiResponse,
} from '@/interfaces/api/benefits'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createBenefitService = (client: AxiosCacheInstance) => {
  return {
    async getOne(id: number) {
      return await axiosHandler<BenefitGetOneApiResponse>(() => {
        return client.get(`/mobile/benefit/${id}`)
      })
    },

    async getList(payload: BenefitGetListApiPayload) {
      return await axiosHandler<BenefitGetListApiResponse>(() => {
        return client.get(`/mobile/benefit/search`, {
          params: {
            ...payload,
            page: payload.page ?? 1,
            size: payload.size ?? 20,
          },
        })
      })
    },

    async getRecommendList(payload: BenefitGetRecommendListApiPayload) {
      return await axiosHandler<BenefitGetListApiResponse>(() => {
        return client.get('/mobile/benefit/recommend/', {
          params: {
            order: payload.order,
            page: payload.page ?? 1,
            size: payload.size ?? 20,
          },
        })
      })
    },

    async getPlusList(payload: BenefitGetPlusListApiPayload = {}) {
      return await axiosHandler<BenefitGetListApiResponse>(() => {
        return client.get('/mobile/benefit/plus/', {
          params: {
            categoryId: payload?.categoryId,
          },
        })
      })
    },

    async requestPrivateCode(id: number) {
      return await axiosHandler<BenefitRequestPrivateCodeApiResponse>(() => {
        return client.post(`/mobile/benefit/${id}/issueCoupon`)
      })
    },
  }
}
