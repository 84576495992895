<template>
  <div
    class="ff-search-history"
    :class="{
      'search-history-clickable': isClickable,
      'search-history-deleteable': isDeleteable,
    }"
  >
    <div class="search-history--container">
      <div
        class="search-history--keyword"
        @click="() => isClickable && onClick()"
      >
        <template
          v-for="(block, key) in blocks"
          :key="key"
        >
          <template v-if="block.tag === 'b'">
            <b>{{ block.text }}</b>
          </template>
          <template v-else>
            {{ block.text }}
          </template>
        </template>
      </div>

      <template v-if="isDeleteable">
        <div class="search-history--delete">
          <IconButtonDelete @click="onDelete" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import IconButtonDelete from '@/components/svg/icon/IconButtonDelete.vue'

interface TagBlock {
  tag?: string
  text: string
}

export default defineComponent({
  name: 'SearchHistory',
  components: {
    IconButtonDelete,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    search: {
      type: String,
    },
    onClick: Function,
    onDelete: Function,
  },
  setup(props) {
    const isClickable = computed(() => typeof props.onClick === 'function')

    const isDeleteable = computed(() => typeof props.onDelete === 'function')

    const blocks = computed(() => {
      const blocks: TagBlock[] = []
      const keyword = (props.keyword ?? '').trim()
      const searchKeyword = (props.search ?? '').trim()
      const words = keyword.split(searchKeyword)
      const wordLastIndex = words.length - 1

      words.forEach((text, index) => {
        blocks.push({
          text,
        })

        if (index < wordLastIndex && searchKeyword) {
          blocks.push({
            tag: 'b',
            text: searchKeyword,
          })
        }
      })

      return blocks
    })

    return {
      isClickable,
      isDeleteable,
      blocks,
    }
  },
})
</script>

<style lang="scss" scoped>
.ff-search-history {
  .search-history--container {
    display: flex;
    align-items: center;

    .search-history--keyword {
      flex: 1;
      padding: 1px 0 2px;
      color: $grey-02;
      @include font_ko(14px, 400);

      b {
        font-weight: bold;
        color: $primary-black;
      }

      &:hover {
        font-weight: bold;
      }
    }

    .search-history--delete {
      display: none;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  &.search-history-clickable {
    .search-history--container {
      .search-history--keyword {
        cursor: pointer;
      }
    }
  }

  &.search-history-deleteable {
    .search-history--container {
      .search-history--delete {
        display: flex;
      }
    }
  }
}
</style>
