<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.81483 2.66667H13.1852C14.0034 2.66667 14.6667 3.3489 14.6667 4.19048V11.8095C14.6667 12.6511 14.0034 13.3333 13.1852 13.3333H2.81483C1.99663 13.3333 1.33334 12.6511 1.33334 11.8095V4.19048C1.33334 3.3489 1.99663 2.66667 2.81483 2.66667ZM3.58142 6.23255C3.34166 6.09554 3.03623 6.17884 2.89923 6.4186C2.76222 6.65836 2.84552 6.96379 3.08528 7.10079L6.75967 9.20044C7.52825 9.63963 8.47178 9.63963 9.24036 9.20044L12.9148 7.10079C13.1545 6.96379 13.2378 6.65836 13.1008 6.4186C12.9638 6.17884 12.6584 6.09554 12.4186 6.23255L8.74422 8.3322C8.28307 8.59571 7.71695 8.59571 7.25581 8.3322L3.58142 6.23255Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconEmail',
  props: {
    color: {
      type: String,
      default: colors['f-primary-black'],
    },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
})
</script>
