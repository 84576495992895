import { AxiosResponse } from 'axios'

import { ApiBaseResponse } from '@/interfaces/api'
import { datadog } from '@/plugins/datadog'

interface AxiosHandlerCallback<Response> {
  (): Promise<AxiosResponse<Response>>
}

export const axiosHandler = async <Response = ApiBaseResponse>(
  request: AxiosHandlerCallback<Response>,
) => {
  const resultResponse: ApiBaseResponse = {
    success: false,
  }

  try {
    const response = await request()

    return response.data
  } catch (error) {
    if (error.response) {
      const data = error.response?.data ?? {}

      Object.assign(resultResponse, {
        ...data,
        success:
          typeof data.success === 'boolean'
            ? data.success
            : resultResponse.success,
      })
    } else if (error.request) {
      resultResponse.message = '서버로부터 응답이 없습니다'
    } else {
      console.error(error)

      datadog.addError(error)

      throw error
    }
  }

  return resultResponse as any as Response
}
