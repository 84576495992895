import { Plugin } from 'vue'

import { useGlobalModalStore } from '@/store/modules/global-modal'

/**
 * 이 플러그인을 사용하기 위해서는 pinia가 필요합니다.
 */
export const globalModalPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.$modal = useGlobalModalStore()
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $modal: ReturnType<typeof useGlobalModalStore>
  }
}
