import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SearchHistory = _resolveComponent("SearchHistory")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_TagSlider = _resolveComponent("TagSlider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.isShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.dimmed),
              onClick: _cache[0] || (_cache[0] = () => _ctx.hideModal())
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.isShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.box)
            }, [
              _createVNode(_component_Header, {
                class: _normalizeClass(_ctx.$style.boxHeader),
                noMenu: "",
                "onClick:logo": _ctx.logGnbHome
              }, null, 8, ["class", "onClick:logo"]),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.boxBody)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.$style.section, _ctx.$style.searchBarSection])
                }, [
                  _createVNode(_component_SearchBar, {
                    modelValue: _ctx.searchText,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
                    ref: "benefitSearchBarRef",
                    class: _normalizeClass(_ctx.$style.searchBar),
                    placeholder: "필요한 베네핏을 검색해보세요!",
                    maxlength: 50,
                    boxInnerStyles: {
                  background: _ctx.colors['grey-07'],
                },
                    onInput: _cache[2] || (_cache[2] = (event) => _ctx.throttleGetAutocompleteList(event)),
                    onSearch: _cache[3] || (_cache[3] = (val) => _ctx.goToSearchPage(val))
                  }, null, 8, ["modelValue", "class", "boxInnerStyles"]),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.closeBtn)
                  }, [
                    _createVNode(_component_Button, {
                      text: "취소",
                      fontSize: 14,
                      height: 42,
                      fontColor: "primary-black",
                      color: "white",
                      onClick: _cache[4] || (_cache[4] = () => _ctx.hideModal())
                    })
                  ], 2)
                ], 2),
                (_ctx.searchText)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.section)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.$style.sectionBody, _ctx.$style.searchList])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.autocompleteList, (row, key) => {
                          return (_openBlock(), _createBlock(_component_SearchHistory, {
                            key: key,
                            class: _normalizeClass(_ctx.$style.searchItem),
                            keyword: row,
                            search: _ctx.searchText,
                            onClick: () => _ctx.goToSearchPage(row)
                          }, null, 8, ["class", "keyword", "search", "onClick"]))
                        }), 128))
                      ], 2)
                    ], 2))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.state.recommendTagList.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style.section)
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.sectionTitle)
                            }, "인기검색어", 2),
                            _createElementVNode("div", {
                              class: _normalizeClass([_ctx.$style.sectionBody, _ctx.$style.tagList])
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.recommendTagList, (row, key) => {
                                return (_openBlock(), _createBlock(_component_Tag, {
                                  key: key,
                                  class: _normalizeClass(_ctx.$style.tagItem),
                                  theme: row.theme,
                                  onClick: row.onClick
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(row.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["class", "theme", "onClick"]))
                              }), 128))
                            ], 2)
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.state.historyList.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style.section)
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.sectionTitle)
                            }, "최근검색어", 2),
                            _createElementVNode("div", {
                              class: _normalizeClass([_ctx.$style.sectionBody, _ctx.$style.searchList])
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.historyList, (row, key) => {
                                return (_openBlock(), _createBlock(_component_SearchHistory, {
                                  key: key,
                                  class: _normalizeClass(_ctx.$style.searchItem),
                                  keyword: row.keyword,
                                  onClick: () => _ctx.goToSearchPage(row.keyword),
                                  onDelete: 
                          () => _ctx.deleteBenefitSearchHistory(row.keyword)
                        
                                }, null, 8, ["class", "keyword", "onClick", "onDelete"]))
                              }), 128))
                            ], 2)
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.state.categoryList.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass(_ctx.$style.section)
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.sectionTitle)
                            }, "카테고리", 2),
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style.sectionBody)
                            }, [
                              _createVNode(_component_TagSlider, {
                                list: _ctx.state.categoryList
                              }, null, 8, ["list"])
                            ], 2)
                          ], 2))
                        : _createCommentVNode("", true)
                    ], 64))
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}