import { createRouter, createWebHistory } from 'vue-router'

import { analytics } from '@/plugins/firebase'
import { forceReload } from '@/router/helper'
import routes from '@/router/path'
import { useAppConfigStore } from '@/store/modules/app-config'
import { useGlobalStore } from '@/store/modules/global'
import { useSessionStore } from '@/store/modules/session'

/**
 * NavigationGuard는 `src/router/navigation-guard.ts`에서 관리합니다.
 */
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { left: 0, top: 0 }
  },
})

router.afterEach(async (to, from) => {
  const sessionStore = useSessionStore()
  const globalStore = useGlobalStore()
  const appConfigStore = useAppConfigStore()

  globalStore.updateRouteHistory(from)

  if (to.meta.screenName) {
    if (!from.meta.screenName || to.meta.screenName !== from.meta.screenName) {
      analytics.screenView(to.meta.screenName)
    }
  }

  if (sessionStore.isLogin) {
    // route 변경 후 세션을 갱신함
    sessionStore.throttledInitializeSession()
  }

  await appConfigStore.checkUpdate()
  appConfigStore.showUpdateModal()
})

router.onError((reason) => {
  if (reason?.name === 'ChunkLoadError') {
    forceReload()
    return
  }

  console.error(reason)
})

export default router
