// hooks/usePageTimeTracker.js
import { analytics } from '@/plugins/firebase'

export const usePageTimeTracker = (router) => {
  let startTime = Date.now()

  const debugLog = (name, ...args) => {
    console.log(`%c${name}`, 'color: yellow', ...args)
  }

  const extractIdFromRoute = (route) => {
    // 모든 route.params를 확인하여 "id" 키가 포함된 경우 추출
    const idKey = Object.keys(route.params).find((key) =>
      key.toLowerCase().includes('id'),
    )
    return idKey ? route.params[idKey] : null
  }

  const getScreenName = (route) => {
    return typeof route.name === 'string' ? route.name : 'Unknown'
  }
  /**
   * id값이 있을 때만 이벤트 하위 매개변수로 id 값 추가
   */
  const createAnalyticsPayload = (
    screenName: string,
    duration: string,
    detailId?: string | null,
  ): { screen_name: string; duration: string; detailId?: string } => {
    const payload: {
      screen_name: string
      duration: string
      detailId?: string
    } = {
      screen_name: screenName,
      duration: duration,
    }
    if (detailId) {
      payload.detailId = detailId
    }
    return payload
  }
  /**
   * 현재 페이지 정보를 universalTracking에 전송
   */
  const trackCurrentPage = (route) => {
    const screenName = getScreenName(route)
    const detailId = extractIdFromRoute(route)
    const params: {
      ut_tracking_key: string
      ut_text: string
      ut_data?: { detailId: string | null }
    } = {
      ut_tracking_key: 'screen_view',
      ut_text: screenName,
    }

    if (detailId) {
      params.ut_data = { detailId: String(detailId) }
    }

    analytics.universalTracking(params)

    debugLog(`현재 페이지 universalTracking 전송:`, {
      params,
    })
  }

  // 페이지 비활성화 감지: 탭 비활성화 시 머문 시간을 Firebase로 전송
  const handleVisibilityChange = () => {
    if (document.hidden) {
      const timeSpent = (Date.now() - startTime) / 1000
      const duration = timeSpent.toFixed(2)
      const currentRoute = router.currentRoute.value

      const screenName = getScreenName(currentRoute)
      const detailId = extractIdFromRoute(currentRoute)

      debugLog(`페이지에 머문 시간 (비활성화 전) ${screenName}: ${duration}초`)
      analytics.logEvent(
        'page_time_spent',
        createAnalyticsPayload(screenName, duration, detailId),
      )

      // 비활성화 상태에서는 startTime 초기화
      startTime = Date.now()
    } else {
      // 다시 활성화된 경우 시간을 0초부터 다시 시작
      startTime = Date.now()
    }
  }

  // 페이지 이동 시 머문 시간 계산 및 Firebase에 이벤트 전송
  const handleRouteChange = (to, from, next) => {
    if (from.name) {
      const endTime = Date.now()
      const timeSpent = (endTime - startTime) / 1000
      const duration = timeSpent.toFixed(2)

      const fromName = getScreenName(from)
      const detailId = extractIdFromRoute(from)

      debugLog(`페이지 "${fromName}"에 머문 시간: ${duration}초`)
      analytics.logEvent(
        'page_time_spent',
        createAnalyticsPayload(fromName, duration, detailId),
      )
    }
    trackCurrentPage(to)
    // 새 페이지로 이동 시 `startTime` 초기화
    startTime = Date.now()
    next()
  }

  const initializePageTimeTracker = () => {
    // visibilitychange 이벤트 연결
    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Vue Router의 beforeEach 사용
    router.beforeEach((to, from, next) => handleRouteChange(to, from, next))
  }

  const cleanupPageTimeTracker = () => {
    // 이벤트 리스너 제거
    document.removeEventListener('visibilitychange', handleVisibilityChange)
  }

  return {
    initializePageTimeTracker,
    cleanupPageTimeTracker,
  }
}
