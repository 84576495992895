import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ff-tag", {
      [`tag--theme--${_ctx.tabTheme}`]: true,
      'tag--selected': _ctx.selected,
      'tag--clickable': _ctx.isClickable,
    }])
  }, [
    _createElementVNode("div", {
      class: "tag--container",
      onClick: _cache[0] || (_cache[0] = () => _ctx.isClickable && _ctx.onClick())
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}