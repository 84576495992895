import { withKeys as _withKeys, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder", "readonly", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IcnSearch = _resolveComponent("IcnSearch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.focused]: _ctx.state.isFocused,
      [_ctx.$style.clickable]: _ctx.clickable,
      [_ctx.$style.readonly]: _ctx.readonly,
      [_ctx.$style.hasBorder]: _ctx.border,
    }),
    onClick: _cache[6] || (_cache[6] = (event) => _ctx.clickable && _ctx.$emit('click', event))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.box),
      style: _normalizeStyle(_ctx.boxInnerStyles)
    }, [
      _createElementVNode("input", {
        value: _ctx.state.value,
        ref: "inputEl",
        type: "text",
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        maxlength: _ctx.maxlength,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onKeypress: [
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)), ["enter"])),
          _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onKeyPress && _ctx.onKeyPress(...args)))
        ]
      }, null, 40, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.btn),
        onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)), ["stop"]))
      }, [
        _createVNode(_component_IcnSearch)
      ], 2)
    ], 6)
  ], 2))
}