"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MS = void 0;
var MS;
(function (MS) {
    MS[MS["1_SEC"] = 1000] = "1_SEC";
    MS[MS["1_MIN"] = 60000] = "1_MIN";
    MS[MS["1_HOUR"] = 3600000] = "1_HOUR";
    MS[MS["1_DAY"] = 86400000] = "1_DAY";
    MS[MS["1_WEEK"] = 604800000] = "1_WEEK";
    MS[MS["1_MONTH"] = 2592000000] = "1_MONTH";
    MS[MS["1_YEAR"] = 31536000000] = "1_YEAR";
})(MS = exports.MS || (exports.MS = {}));
