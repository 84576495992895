import { Plugin } from 'vue'

import { useGlobalLoadingStore } from '@/store/modules/global-loading'

/**
 * 이 플러그인을 사용하기 위해서는 pinia가 필요합니다.
 */
export const globalLoadingPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.$loading = useGlobalLoadingStore()
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $loading: ReturnType<typeof useGlobalLoadingStore>
  }
}
