<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.clickable]: isClickable,
    }"
  >
    <ClickArea
      v-slot="{ onInsideClick }"
      @click:outside="onOutsideClick"
    >
      <div :class="$style.overlay" />
      <div
        :class="$style.wrapper"
        :style="computedWrapperStyles"
        @click="
          () => {
            onClick()
            onInsideClick()
          }
        "
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @mouseup="onMouseUp"
        @mousedown="onMouseDown"
      >
        <slot
          :slotStyle="$style.slotStyle"
          :selectedOption="selectedOption"
          :cssVars="cssVars"
        >
          <span :class="$style.slotStyle">
            <template v-if="selectedOption">
              {{ selectedOption.label }}
            </template>
            <template v-else>
              {{ placeholder }}
            </template>
          </span>
        </slot>

        <IconCaret
          v-if="!readonly"
          :color="cssVars.caretColor"
          :class="$style.slotStyle"
          :direction="state.isOpen ? 'up' : 'down'"
        />
      </div>

      <transition>
        <template v-if="state.isOpen">
          <div
            :class="$style.popup"
            @click="onInsideClick"
          >
            <slot
              name="popup"
              :onInsideClick="onInsideClick"
              :onOutsideClick="onOutsideClick"
            >
              <DropdownList
                :modelValue="state.selectedValue"
                :options="options"
                :width="dropdownListWidth"
                :searchable="searchable"
                :searchInputPlaceholder="searchInputPlaceholder"
                :searchValue="searchValue"
                :noticeText="noticeText"
                @select="onSelect"
                @update:searchValue="(val) => $emit('update:searchValue', val)"
              />
            </slot>
          </div>
        </template>
      </transition>
    </ClickArea>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  StyleValue,
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  watch,
} from 'vue'

import DropdownList from '@/components/v2/dropdown/DropdownList.vue'
import IconCaret from '@/components/v2/icon/IconCaret.vue'
import ClickArea from '@/components/v2/interaction/ClickArea.vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'
import { DropdownV2 } from '@/interfaces/components/v2/dropdown'

const colorMap = {
  'gray-15': {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-primary-white'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-primary-white'],
    bgColor: colors['f-gray-15'],
    'bgColor:disabled': colors['f-gray-30'],
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
  transparent: {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-primary-white'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-primary-white'],
    bgColor: 'transparent',
    'bgColor:disabled': colors['f-gray-30'],
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
  white: {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-primary-white'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-primary-white'],
    bgColor: colors['f-primary-white'],
    'bgColor:disabled': colors['f-gray-30'],
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
  line: {
    textColor: colors['f-primary-black'],
    'textColor:disabled': colors['f-gray-40'],
    caretColor: colors['f-primary-black'],
    'caretColor:disabled': colors['f-gray-35'],
    bgColor: 'transparent',
    'bgColor:disabled': 'transparent',
    overlayColor: 'transparent',
    'overlayColor:hover': colors['f-trans-black-03'],
    'overlayColor:press': colors['f-trans-black-05'],
  },
}

export default defineComponent({
  components: {
    IconCaret,
    DropdownList,
    ClickArea,
  },
  props: {
    theme: {
      type: String as PropType<DropdownV2.SelectBox.Theme>,
      default: 'gray-15',
    },
    options: {
      type: Array as PropType<DropdownV2.List.Option[]>,
      default: () => [],
    },
    modelValue: {
      type: null as unknown as PropType<DropdownV2.List.ItemOption['value']>,
      default: null,
    },
    placeholder: {
      type: String,
      default: '선택해 주세요',
    },
    open: {
      type: Boolean,
      default: false,
    },
    popupPosition: {
      type: String as PropType<DropdownV2.SelectBox.PopupPosition>,
      default: 'left',
    },
    borderRadius: {
      type: String,
      default: '24px',
    },
    dropdownListWidth: {
      type: String,
    },
    wrapperStyles: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * 이 옵션이 true인 경우 내부 상태를 사용하지 않고 modelValue만 사용하게 됩니다.
     */
    onlyModelValue: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchInputPlaceholder: {
      type: String,
      default: '',
    },
    searchValue: {
      type: String,
      default: '',
    },
    noticeText: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': (_: DropdownV2.List.ItemOption['value']) => true,
    'update:open': (val: boolean) => typeof val === 'boolean',
    'update:searchValue': (val: string) => typeof val === 'string',
    select: (val: DropdownV2.List.ItemOption) => !!val,
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpen: false,
      isHover: false,
      isPress: false,
      selectedValue: null as DropdownV2.List.ItemOption['value'],
    })

    const cssVars = computed(() => {
      const palette = colorMap[props.theme] ?? colorMap['gray-15']

      const scope = {
        textColor: palette.textColor,
        caretColor: palette.caretColor,
        bgColor: palette.bgColor,
        overlayColor: palette.overlayColor,
        borderRadius: props.borderRadius,
        popupLeft: '0',
        popupRight: 'auto',
      }

      if (props.disabled) {
        scope.textColor = palette['textColor:disabled']
        scope.caretColor = palette['caretColor:disabled']
        scope.bgColor = palette['bgColor:disabled']
      } else {
        if (state.isHover) {
          scope.overlayColor = palette['overlayColor:hover']
        }

        if (state.isPress || state.isOpen) {
          scope.overlayColor = palette['overlayColor:press']
        }
      }

      switch (props.popupPosition) {
        case 'center': {
          scope.popupLeft = 'auto'
          scope.popupRight = 'auto'
          break
        }

        case 'right': {
          scope.popupLeft = 'auto'
          scope.popupRight = '0'
          break
        }
      }

      return scope
    })

    const selectedOption = computed(() => {
      const option = props.options.find((row) => {
        return row.value === state.selectedValue
      })

      return option ?? null
    })

    const isClickable = computed(() => !props.disabled && !props.readonly)

    const computedWrapperStyles = computed<StyleValue>(() => {
      const scope: StyleValue = []

      if (props.theme === 'line') {
        scope.push({
          padding: '10px 16px',
          borderWidth: '1px',
          borderColor: colors['f-gray-15'],
          borderStyle: 'solid',
          minWidth: '110px',
        })
      }

      if (props.wrapperStyles) {
        scope.push(props.wrapperStyles)
      }

      return scope
    })

    watch(
      () => props.modelValue,
      (value) => {
        state.selectedValue = value
      },
    )

    watch(
      () => props.open,
      (value) => {
        state.isOpen = value
      },
    )

    watch(
      () => state.selectedValue,
      (value, prevValue) => {
        if (value !== prevValue) {
          emit('update:modelValue', value)
        }
      },
    )

    watch(
      () => state.isOpen,
      (value, prevValue) => {
        if (value !== prevValue) {
          emit('update:open', value)
        }
      },
    )

    onBeforeMount(() => {
      state.selectedValue = props.modelValue
    })

    /**
     * @public
     */
    function close() {
      if (state.isOpen) {
        state.isOpen = false
      }
    }

    function onClick() {
      if (!isClickable.value) {
        return
      }

      state.isOpen = !state.isOpen
    }

    function onMouseEnter() {
      state.isHover = true
    }

    function onMouseLeave() {
      state.isHover = false
      state.isPress = false
    }

    function onMouseUp() {
      state.isPress = false
    }

    function onMouseDown() {
      state.isPress = true
    }

    function onSelect(option: DropdownV2.List.ItemOption) {
      if (!props.onlyModelValue) {
        state.selectedValue = option.value
      }

      state.isOpen = false

      emit('select', option)
    }

    function onOutsideClick() {
      state.isOpen = false
    }

    return {
      state,
      cssVars,
      selectedOption,
      isClickable,
      computedWrapperStyles,
      close,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onMouseDown,
      onSelect,
      onOutsideClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  @include font_v2('ko', 15px, 400);

  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: v-bind('cssVars.bgColor');
  border-radius: v-bind('cssVars.borderRadius');
  color: v-bind('cssVars.textColor');

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    border-radius: v-bind('cssVars.borderRadius');
    background-color: v-bind('cssVars.overlayColor');
  }

  .wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-radius: v-bind('cssVars.borderRadius');

    .slotStyle {
      margin-left: 4px;
      min-width: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .popup {
    position: absolute;
    left: v-bind('cssVars.popupLeft');
    right: v-bind('cssVars.popupRight');
    bottom: -8px;
    transform: translateY(100%);
    transition: opacity 50ms;

    &:global(.v-enter-from),
    &:global(.v-leave-to) {
      opacity: 0;
    }

    &:global(.v-leave-from),
    &:global(.v-enter-to) {
      opacity: 1;
    }
  }

  &.clickable {
    .wrapper {
      cursor: pointer;
    }
  }
}
</style>
