"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearHttpCache = exports.indexedDbStorage = void 0;
const constants_1 = require("../../time/constants");
const axios_cache_interceptor_1 = require("axios-cache-interceptor");
const idb_keyval_1 = require("idb-keyval");
const DB_NAME = 'fastfive-members-http-cache';
const STORE_NAME = 'responses';
const customStore = (0, idb_keyval_1.createStore)(DB_NAME, STORE_NAME);
let clearHttpCacheLastRunTimeMs = 0;
exports.indexedDbStorage = (0, axios_cache_interceptor_1.buildStorage)({
    find(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const value = yield (0, idb_keyval_1.get)(key, customStore);
            if (!value) {
                return;
            }
            return JSON.parse(value);
        });
    },
    set(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            yield (0, idb_keyval_1.set)(key, JSON.stringify(value), customStore);
        });
    },
    remove(key) {
        return __awaiter(this, void 0, void 0, function* () {
            yield (0, idb_keyval_1.del)(key, customStore);
        });
    },
});
const clearHttpCache = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (Date.now() - clearHttpCacheLastRunTimeMs >= constants_1.MS['1_SEC']) {
            clearHttpCacheLastRunTimeMs = Date.now();
            yield (0, idb_keyval_1.clear)(customStore);
        }
    }
    catch (error) {
        console.error(error);
    }
});
exports.clearHttpCache = clearHttpCache;
