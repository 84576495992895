import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/messaging'
import { Plugin } from 'vue'

import { MODE } from '@/env'
import Analytics from '@/plugins/firebase/analytics'
import { getFirebaseConfig } from '@/plugins/firebase/config'

export const firebaseConfig = getFirebaseConfig(MODE)

firebase.initializeApp(firebaseConfig)

export const analytics = new Analytics()

/**
 * 예전에 사용한 방식인데 호환성을 위해 남겨놓음
 */
export const useAnalytics = () => {
  return analytics
}

export const firebasePlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.analytics = analytics
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    analytics: Analytics
  }
}

export default firebasePlugin
