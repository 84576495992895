import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "tw-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFacebook = _resolveComponent("IconFacebook")!
  const _component_IconInstagram = _resolveComponent("IconInstagram")!
  const _component_IconNaver = _resolveComponent("IconNaver")!
  const _component_IconYoutube = _resolveComponent("IconYoutube")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.container, { [_ctx.$style.responsive]: _ctx.isResponsive }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.section, _ctx.$style.firstSection])
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.$style.logo),
        src: _ctx.images.logo,
        alt: "logo"
      }, null, 10, _hoisted_1),
      _createElementVNode("ul", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createElementVNode("li", {
          class: _normalizeClass(_ctx.$style.title)
        }, "비즈니스", 2),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            textContent: '회사 소개',
            href: "https://www.fastfive.co.kr/bottom_officeplatform",
            target: "_blank",
            onClick: _cache[0] || (_cache[0] = () => _ctx.analytics.presetLogEvent('footer_about_us'))
          })
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            textContent: '비즈니스 제휴 문의',
            href: "mailto:platform@fastfive.co.kr",
            target: "_blank",
            onClick: _cache[1] || (_cache[1] = 
              () => _ctx.analytics.presetLogEvent('footer_businesspartnership_email')
            )
          })
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            textContent: '부동산 입점 문의',
            href: "mailto:building@fastfive.co.kr",
            target: "_blank",
            onClick: _cache[2] || (_cache[2] = () => _ctx.analytics.presetLogEvent('footer_realestate_email'))
          })
        ])
      ], 2),
      _createElementVNode("ul", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createElementVNode("li", {
          class: _normalizeClass(_ctx.$style.title)
        }, "미디어", 2),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            textContent: '미디어',
            href: "https://corp.fastfive.co.kr/",
            target: "_blank",
            onClick: _cache[3] || (_cache[3] = () => _ctx.analytics.presetLogEvent('footer_media'))
          })
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            textContent: '언론 홍보 문의',
            href: "mailto:pr@fastfive.co.kr",
            target: "_blank",
            onClick: _cache[4] || (_cache[4] = () => _ctx.analytics.presetLogEvent('footer_press_email'))
          })
        ])
      ], 2),
      _createElementVNode("ul", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createElementVNode("li", {
          class: _normalizeClass(_ctx.$style.title)
        }, "패스트파이브를 만나보세요", 2),
        _createElementVNode("li", _hoisted_2, [
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.$style.snsBtn),
            href: "https://www.facebook.com/ftfive",
            target: "_blank",
            onClick: _cache[5] || (_cache[5] = () => _ctx.analytics.presetLogEvent('footer_SNS_facebook')),
            onMouseenter: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSnsHover('facebook'))),
            onMouseleave: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onSnsBlur && _ctx.onSnsBlur(...args)))
          }, [
            _createVNode(_component_IconFacebook, {
              color: _ctx.getSnsIconColor('facebook')
            }, null, 8, ["color"])
          ], 34),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.$style.snsBtn),
            href: "https://www.instagram.com/fastfive_official/",
            target: "_blank",
            onClick: _cache[8] || (_cache[8] = () => _ctx.analytics.presetLogEvent('footer_SNS_instagram')),
            onMouseenter: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSnsHover('instagram'))),
            onMouseleave: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onSnsBlur && _ctx.onSnsBlur(...args)))
          }, [
            _createVNode(_component_IconInstagram, {
              stroke: _ctx.getSnsIconColor('instagram')
            }, null, 8, ["stroke"])
          ], 34),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.$style.snsBtn),
            href: "https://blog.naver.com/fast_five_",
            target: "_blank",
            onClick: _cache[11] || (_cache[11] = () => _ctx.analytics.presetLogEvent('footer_SNS_naverblog')),
            onMouseenter: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSnsHover('naverblog'))),
            onMouseleave: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.onSnsBlur && _ctx.onSnsBlur(...args)))
          }, [
            _createVNode(_component_IconNaver, {
              color: _ctx.getSnsIconColor('naverblog')
            }, null, 8, ["color"])
          ], 34),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.$style.snsBtn),
            href: "https://www.youtube.com/c/FASTFIVEOFFICIAL",
            target: "_blank",
            onClick: _cache[14] || (_cache[14] = () => _ctx.analytics.presetLogEvent('footer_SNS_youtube')),
            onMouseenter: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onSnsHover('youtube'))),
            onMouseleave: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.onSnsBlur && _ctx.onSnsBlur(...args)))
          }, [
            _createVNode(_component_IconYoutube, {
              color: _ctx.getSnsIconColor('youtube')
            }, null, 8, ["color"])
          ], 34)
        ])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.section, _ctx.$style.secondSection])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.copyright)
      }, "ⓒ FASTFIVE Corp.", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.pointer),
          onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.moveFaqServiceAgreement && _ctx.moveFaqServiceAgreement(...args)))
        }, " 서비스 이용약관 ", 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.item)
      }, [
        _createElementVNode("a", {
          textContent: '개인정보 처리방침',
          href: "https://www.fastfive.co.kr/privacy-policy",
          target: "_blank",
          onClick: _cache[18] || (_cache[18] = () => _ctx.analytics.presetLogEvent('footer_privacypolicy'))
        })
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.section, _ctx.$style.thirdSection])
    }, [
      _cache[20] || (_cache[20] = _createElementVNode("span", null, "대표 : 김대일", -1)),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.dot)
      }, null, 2),
      _cache[21] || (_cache[21] = _createElementVNode("span", null, "사업자등록번호 : 151-81-00025", -1)),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.address)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.$style.dot)
        }, null, 2),
        _cache[19] || (_cache[19] = _createElementVNode("span", null, "서울시 서초구 남부순환로 333길 10, 2층, 3층 (서초동, 원일빌딩)", -1))
      ], 2)
    ], 2)
  ], 2))
}