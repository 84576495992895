import { MODE } from '@/env'

export const getFirebaseConfig = (mode: typeof MODE) => {
  switch (mode) {
    case 'production':
      return {
        apiKey: 'AIzaSyDWqktAJ7GBzLwe-d10_Tqau6l3pP7PHF4',
        authDomain: 'fastfive-e9ba6.firebaseapp.com',
        databaseURL: 'https://fastfive-e9ba6.firebaseio.com',
        projectId: 'fastfive-e9ba6',
        storageBucket: 'fastfive-e9ba6.appspot.com',
        messagingSenderId: '435955360511',
        appId: '1:435955360511:web:845481b8892f9561e5a2ca',
        measurementId: 'G-4DTHR67YGL',
      }

    case 'staging':
      return {
        apiKey: 'AIzaSyDWqktAJ7GBzLwe-d10_Tqau6l3pP7PHF4',
        authDomain: 'fastfive-e9ba6.firebaseapp.com',
        databaseURL: 'https://fastfive-e9ba6.firebaseio.com',
        projectId: 'fastfive-e9ba6',
        storageBucket: 'fastfive-e9ba6.appspot.com',
        messagingSenderId: '435955360511',
        appId: '1:435955360511:web:1f4bbc050a528040e5a2ca',
        measurementId: 'G-SV9HZ6PZ2B',
      }

    default:
      return {
        apiKey: 'AIzaSyDWqktAJ7GBzLwe-d10_Tqau6l3pP7PHF4',
        authDomain: 'fastfive-e9ba6.firebaseapp.com',
        databaseURL: 'https://fastfive-e9ba6.firebaseio.com',
        projectId: 'fastfive-e9ba6',
        storageBucket: 'fastfive-e9ba6.appspot.com',
        messagingSenderId: '435955360511',
        appId: '1:435955360511:web:71ad12e7638c39aae5a2ca',
        measurementId: 'G-84ZJBHJ2ZY',
      }
  }
}
