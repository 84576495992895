import { AxiosCacheInstance } from 'axios-cache-interceptor'

export const createService = (client: AxiosCacheInstance) => {
  return {
    async getProfile() {
      return client.get('/mobile/user/profile')
    },
    async getMemberGroups() {
      return client.get('/mobile/user/memberGroups')
    },
    async getDefaultMemberGroupSetting({
      memberGroupId,
      branchId,
    }: { memberGroupId?: number; branchId?: number } = {}) {
      return client.get(
        '/mobile/user/defaultMemberGroupSetting',
        memberGroupId
          ? {
              params: { memberGroupId, branchId },
            }
          : {},
      )
    },
    async putDevice({
      appVersion,
      pushToken,
      osType,
      userAgent,
      osVersion,
      deviceModel,
    }) {
      return client.put('/mobile/user/device', {
        appVersion,
        pushToken,
        osType,
        userAgent,
        osVersion,
        deviceModel,
      })
    },
    async setName(name) {
      return client.put('/mobile/user/name', {
        name,
      })
    },
    async setContactNo({
      contactNo,
      authenticationCode,
    }: {
      contactNo: string
      authenticationCode: string
    }) {
      return client.patch('/mobile/user/changeContactNo', {
        contactNo,
        authenticationCode,
      })
    },
    async setOccupation(occupation) {
      return client.patch('/mobile/user/changeOccupation', {
        occupation,
      })
    },
    async setBirthday(birthday) {
      return client.patch('/mobile/user/changeBirthday', {
        birthday,
      })
    },
    async setGender(gender: 'm' | 'f') {
      return client.patch('/mobile/user/changeGender', {
        gender,
      })
    },
    async setProfileImage(fileId) {
      return client.post('/mobile/user/profile', {
        fileId,
      })
    },
    async deleteProfileImage() {
      return client.delete('/mobile/user/profile')
    },
    async deleteUser() {
      return client.delete('/mobile/user')
    },
  }
}
