<template>
  <div
    :class="$style.container"
    :style="cssVars"
  >
    <IcnRecomm
      :class="$style.icon"
      :color="colors.white"
    />

    <span :class="$style.message">{{ message }}</span>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

import IcnRecomm from '@/components/svg/icon/IcnRecomm.vue'

import colors from '@/assets/scss/base/colors.module.scss'
import { ToastType } from '@/interfaces/components/common/toast'

export default defineComponent({
  name: 'Toast',
  components: {
    IcnRecomm,
  },
  props: {
    type: {
      type: String as PropType<ToastType>,
      default: 'info',
    },
    message: {
      type: String,
      default: '',
    },
    zIndex: {
      type: Number,
      default: 200,
    },
  },
  setup(props) {
    const cssVars = computed(() => {
      return {
        '--toast--z-index': props.zIndex,
      }
    })

    return {
      colors,
      cssVars,
    }
  },
})
</script>

<style lang="scss">
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>

<style lang="scss" module>
.container {
  position: fixed;
  top: 110px;
  left: 50%;
  z-index: var(--toast--z-index);
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 450px;
  padding: 9px 20px;
  border-radius: 21px;
  background-color: rgba($color: $black, $alpha: 0.7);
  box-shadow: 0px 0px 6px rgba($black, 0.1);

  @include font_ko(15px, 400);

  .icon {
    margin-right: 4px;
  }

  .message {
    flex: 1;
    color: $white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
