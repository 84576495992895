<template>
  <div
    class="input-wrap"
    :class="{ form_error: valid === false }"
  >
    <div
      class="input-container"
      :class="{ disabled: isDisabled }"
    >
      <slot name="left" />
      <input
        ref="inputEl"
        class="input"
        :name="name"
        :id="id"
        :type="type === 'password' && inputVisiblePassword ? 'text' : type"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :required="isRequired"
        :value="inputValue"
        @input="onInput"
        :style="inputStyle"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        @keydown="(ev) => $emit('keydown', ev)"
      />
      <slot name="right" />
      <div
        class="button-visible"
        v-if="type === 'password' && !blockVisiblePassword"
        @click="onClickVisible"
      >
        <img
          alt="input-visible"
          :src="iconVisibleUrl"
        />
      </div>
    </div>
    <p
      v-if="visibleErrorMessage"
      class="warning"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import _ from 'lodash'
import { computed, ref, watch, watchEffect } from 'vue'

export default {
  props: {
    id: String,
    name: String,
    isRequired: Boolean,
    placeholder: String,
    autocomplete: {
      type: String,
      default: 'on',
    },
    autocapitalize: {
      type: String,
      default: 'off',
    },
    maxlength: [String, Number],
    formatter: Function,
    validator: {
      default: null,
    },
    errorMessage: String,
    type: {
      type: String,
      default: 'text',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Boolean, Date, Number],
      default: null,
    },
    visiblePassword: {
      type: Boolean,
      default: false,
    },
    blockVisiblePassword: {
      type: Boolean,
      default: false,
    },
    inputStyle: Object,
  },
  emits: ['update:modelValue', 'validate', 'toggleVisible', 'keydown'],
  setup(props, { emit }) {
    const inputEl = ref()
    const inputValue = ref(null)
    const inputVisiblePassword = ref(props.visiblePassword)
    const valid = ref(null)

    const updateValidation = (v) => {
      if (!_.isNil(props.validator)) {
        if (!props.isRequired && (_.isString(v) ? _.size(v) < 1 : _.isNil(v))) {
          valid.value = null
        } else {
          valid.value = _.isFunction(props.validator)
            ? props.validator(v)
            : props.validator === v
        }
        emit('validate', valid.value, v)
      }
    }

    watch(
      [() => props.modelValue, () => props.formatter, () => props.validator],
      ([value]) => {
        if (value !== inputValue.value) {
          inputValue.value =
            props.formatter?.(props.modelValue) ?? props.modelValue
          updateValidation(inputValue.value)
        }
      },
      {
        immediate: true,
      },
    )

    watchEffect(() => {
      inputVisiblePassword.value = props.visiblePassword
    })

    const iconVisibleOffImageUrl = require('@/assets/images/button/button_pwview_off.png')
    const iconVisibleOnImageUrl = require('@/assets/images/button/button_pwview_on.png')

    return {
      inputEl,
      inputValue,
      inputVisiblePassword,
      valid,
      hasError: false,
      onInput({ target: { value: newValue } }) {
        const v = props.formatter?.(newValue) ?? newValue
        inputValue.value = v
        emit('update:modelValue', v)
        updateValidation(v)

        if (
          typeof v === 'string' &&
          inputEl.value &&
          inputEl.value.value !== v
        ) {
          inputEl.value.value = v
        }
      },

      visibleErrorMessage: computed(() => {
        return _.has(props, 'errorMessage') && valid.value === false
      }),

      onClickVisible() {
        inputVisiblePassword.value = !inputVisiblePassword.value
        emit('toggleVisible', inputVisiblePassword.value)
      },

      iconVisibleUrl: computed(() => {
        return inputVisiblePassword.value
          ? iconVisibleOnImageUrl
          : iconVisibleOffImageUrl
      }),
    }
  },
}
</script>

<style lang="scss" scoped>
.input-wrap {
  display: flex;
  flex-direction: column;

  > .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    background-color: $white;
    box-sizing: border-box;
    border: 1px solid $grey-05;
    border-radius: 4px;

    padding: 0 16px;

    &.disabled {
      background-color: $grey-06;
      color: $grey-04;
    }

    &:focus-within {
      border: 1px solid $primary-black;
    }
  }
}

.input {
  all: unset;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  flex: 1;
  @include font_ko(15px, 400);
  text-align: start;

  // Firefox Problem:
  // https://stackoverflow.com/questions/43314921/strange-input-widths-in-firefox-vs-chrome
  min-width: 0;

  &:valid {
    color: $primary-black;
  }
  &:disabled {
    background-color: $grey-06;
    color: $grey-04;
  }

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  &::placeholder,
  &::-webkit-input-placeholder {
    color: black;
    opacity: 0.25; /* Firefox */
    font-weight: 400 !important;
  }

  /* Microsoft Edge */
  &::-ms-input-placeholder {
    color: $grey-05;
    font-weight: 400 !important;
  }
}

.warning {
  padding-top: 5px;
  visibility: hidden;
  margin: 0;
  @include font_ko(12px, 400);
  color: $point4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.wrap {
    white-space: inherit;
  }
}

.form_error {
  .warning {
    visibility: visible;
  }

  > .input-container {
    border-color: $point4 !important;
  }

  + .description {
    padding-top: 15px;
  }
}

.button-visible {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 11px;

  > img {
    width: 25px;
    height: 16px;
  }
}
</style>
