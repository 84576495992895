<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.9355 6.06587C21.2458 6.37616 21.4692 6.76244 21.5835 7.18612C21.9951 8.74522 22 12.0001 22 12.0001C22 12.0001 22 15.2549 21.5835 16.814C21.4692 17.2377 21.2458 17.624 20.9355 17.9343C20.6252 18.2446 20.239 18.468 19.8153 18.5822C18.2562 19.0004 12 19.0004 12 19.0004C12 19.0004 5.74547 19.0004 4.18637 18.5822C3.76254 18.4683 3.3761 18.245 3.06576 17.9346C2.75543 17.6243 2.53211 17.2379 2.41817 16.814C2 15.2549 2 12.0001 2 12.0001C2 12.0001 2 8.74522 2.41817 7.18612C2.5311 6.76107 2.75397 6.37327 3.06437 6.06171C3.37477 5.75016 3.76175 5.52584 4.18637 5.41134C5.74383 4.99316 12 4.99316 12 4.99316C12 4.99316 18.2545 4.99316 19.8153 5.41793C20.239 5.53216 20.6252 5.75558 20.9355 6.06587ZM15.1976 12.0006L10 15.0003V9.00098L15.1976 12.0006Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconYoutube',
  props: {
    color: {
      type: String,
      default: colors['f-primary-black'],
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
