<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.inline]: inline,
    }"
    @click="(ev) => onContainerClick(ev)"
  >
    <Lottie
      :class="$style.loadingImg"
      :src="loadingImg"
      :width="size"
      :height="size"
      autoplay
      loop
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Lottie from '@/components/v2/Lottie.vue'

export default defineComponent({
  components: {
    Lottie,
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '86px',
    },
  },
  emits: {
    click: (ev: Event) => !!ev,
  },
  setup(props, { emit }) {
    const loadingImg = require('@/assets/lottiefiles/loading_fa_smile.json')

    function onContainerClick(ev: Event) {
      emit('click', ev)
    }

    return {
      loadingImg,
      onContainerClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $f-trans-white-80;

  .loadingImg {
    display: block;
  }

  &:not(.inline) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
  }
}
</style>
