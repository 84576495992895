import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.contents)
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.$style.image),
        src: _ctx.images.error
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title)
      }, "시스템 점검 중", 2),
      _createElementVNode("div", {
        textContent: _toDisplayString(_ctx.reason),
        class: _normalizeClass([_ctx.$style.subtitle, "tw-whitespace-pre-line"])
      }, null, 10, _hoisted_2)
    ], 2)
  ], 2))
}