import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["autoplay", "background", "controls", "count", "direction", "hover", "loop", "mode", "preserveAspectRatio", "renderer", "speed", "src", "intermission"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("lottie-player", {
    ref: "playerRef",
    autoplay: _ctx.autoplay,
    background: _ctx.background,
    controls: _ctx.controls,
    count: _ctx.count,
    direction: _ctx.direction,
    hover: _ctx.hover,
    loop: _ctx.loop,
    mode: _ctx.mode,
    preserveAspectRatio: _ctx.preserveAspectRatio,
    renderer: _ctx.renderer,
    speed: _ctx.speed,
    src: _ctx.src,
    intermission: _ctx.intermission,
    style: _normalizeStyle(_ctx.styles)
  }, null, 12, _hoisted_1))
}