<template>
  <div
    :class="[
      $style.container,
      {
        [$style[`lang--${lang}`]]: true,
        [$style[`size--small`]]: size === 'small',
        [$style[`size--large`]]: size === 'large',
        [$style.clickable]: isClickable,
      },
    ]"
    @click="onContainerClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <slot :palette="palette" />
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, reactive } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'
import { BadgeV2 } from '@/interfaces/components/v2/badge'

const colorMap = {
  'f-gray-10': {
    text: colors['f-gray-45'],
    bg: colors['f-gray-10'],
  },
  'f-gray-15': {
    text: colors['f-gray-45'],
    bg: colors['f-gray-15'],
  },
  'f-gray-40': {
    text: colors['f-primary-white'],
    bg: colors['f-gray-40'],
  },
  'f-trans-black-03': {
    text: colors['f-gray-45'],
    bg: colors['f-trans-black-03'],
  },
  'f-primary-black': {
    text: colors['f-primary-white'],
    bg: colors['f-primary-black'],
  },
  'f-secondary-extention-orange-10': {
    text: colors['f-secondary-orange'],
    bg: colors['f-secondary-extention-orange-10'],
  },
  'f-trans-orange-10': {
    text: colors['f-secondary-orange'],
    bg: colors['f-trans-orange-10'],
  },
  'f-warning-red-10': {
    text: colors['f-warning-red-100'],
    bg: colors['f-warning-red-10'],
  },
  'f-trans-green-10': {
    text: colors['f-secondary-green'],
    bg: colors['f-trans-green-10'],
  },
}

export default defineComponent({
  props: {
    lang: {
      type: String as PropType<'ko' | 'en'>,
      default: 'ko',
    },
    size: {
      type: String as PropType<BadgeV2.Size>,
      default: 'medium',
    },
    color: {
      type: String as PropType<BadgeV2.Color>,
      default: 'f-gray-15',
    },
    hoverColor: {
      type: String as PropType<BadgeV2.Color>,
    },
    activeColor: {
      type: String as PropType<BadgeV2.Color>,
    },
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * example: '12px'
     */
    radius: String,
    onClick: Function,
  },
  setup(props) {
    const state = reactive({
      isHover: false,
    })

    const isClickable = computed(() => typeof props.onClick === 'function')

    const palette = computed(() => {
      const color = colorMap[props.color] ?? colorMap['f-gray-15']

      if (props.active) {
        return (props.activeColor && colorMap[props.activeColor]) || color
      }

      if (state.isHover) {
        return (props.hoverColor && colorMap[props.hoverColor]) || color
      }

      return color
    })

    const cssVars = computed(() => {
      const borderRadius = props.size === 'large' ? '14px' : '12px'

      return {
        borderRadius: props.radius || borderRadius,
      }
    })

    function onContainerClick(ev) {
      if (isClickable.value) {
        props.onClick?.(ev)
      }
    }

    function onMouseEnter() {
      state.isHover = true
    }

    function onMouseLeave() {
      state.isHover = false
    }

    return {
      isClickable,
      palette,
      cssVars,
      onContainerClick,
      onMouseEnter,
      onMouseLeave,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  @include font_v2('ko', 12px, 500);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  border-radius: v-bind('cssVars.borderRadius');
  background-color: v-bind('palette.bg');
  color: v-bind('palette.text');
  min-width: 22px;
  text-align: center;
  box-sizing: content-box;

  &.lang--en {
    @include font_v2('en', 12px, 500);
  }

  &.size--small {
    padding: 0 4px;
  }

  &.size--large {
    @include font_v2('ko', 14px, 500);

    padding: 4px 12px;

    &.lang--en {
      @include font_v2('en', 14px, 500);
    }
  }

  &.clickable {
    cursor: pointer;
  }
}
</style>
