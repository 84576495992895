
import { PropType, computed, defineComponent } from 'vue'

import { ThumbnailV2 } from '@/interfaces/components/v2/thumbnail'

const images = {
  profilePlaceholder: require('@/assets/images/v2/thumbnail/profile_placeholder.png'),
  sqaure: require('@/assets/images/v2/thumbnail/sqaure.png'),
}

const __default__ = defineComponent({
  props: {
    src: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    /**
     * ratio = width / height
     */
    ratio: {
      type: Number,
      default: 1,
    },
    border: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ThumbnailV2.Size>,
      default: 'medium',
    },
    radius: {
      type: String,
      default: '0',
    },
    imageName: {
      type: String as PropType<ThumbnailV2.ImageName>,
      default: 'profilePlaceholder',
    },
    onClick: Function,
  },
  setup(props) {
    const isClickable = computed(() => typeof props.onClick === 'function')

    const backgroundImage = computed(() => {
      if (props.src) {
        return `url(${props.src})`
      }

      const defaultImage = images[props.imageName] ?? images.profilePlaceholder

      if (defaultImage) {
        return `url(${defaultImage})`
      }

      return 'none'
    })

    const paddingBottom = computed(() => {
      const height = 100 / props.ratio || 100

      return `${height}%`
    })

    const containerStyles = computed(() => {
      return {
        borderRadius: props.radius,
      }
    })

    const innerStyles = computed(() => {
      return {
        backgroundImage: backgroundImage.value,
        paddingBottom: paddingBottom.value,
        borderRadius: props.radius,
      }
    })

    const cssVars = computed(() => {
      const scope = {
        width: '100px',
        height: '100px',
      }

      if (props.size === 'small') {
        scope.width = '64px'
        scope.height = '64px'
      }

      return scope
    })

    function onContainerClick() {
      if (isClickable.value) {
        props.onClick?.()
      }
    }

    return {
      isClickable,
      containerStyles,
      innerStyles,
      cssVars,
      onContainerClick,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "312b152c": (_ctx.cssVars.width),
  "27d583fa": (_ctx.cssVars.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__