import { defineStore } from 'pinia'
import { RouteLocationNormalized, RouteRecordName } from 'vue-router'

import { GLOBAL } from '@/store/keys'
import { useAppConfigStore } from '@/store/modules/app-config'

export const useGlobalStore = defineStore(GLOBAL, {
  state: () => ({
    isInitialized: false,
    isEnableScrollBar: false,
    /**
     * 서버 점검 중일 때 true
     */
    isServiceUnavailableForServerChecking: false,
    routeHistory: {
      prevRouteName: null as RouteRecordName | null,
    },
  }),
  actions: {
    completeInitialize() {
      const appConfigStore = useAppConfigStore()

      this.isInitialized = true

      appConfigStore.enableScheduler()
      appConfigStore.showUpdateModal()
    },

    enableScrollBar(value = true) {
      this.isEnableScrollBar = value

      window.document.body.style.overflow = this.isEnableScrollBar
        ? ''
        : 'hidden'
    },

    updateRouteHistory(route: RouteLocationNormalized) {
      this.routeHistory.prevRouteName = route.name ?? null
    },
  },
})
