import qs from 'query-string'
import { RouteLocationRaw } from 'vue-router'

import { parseUrlToRouteFromAppLink } from '@/plugins/applink'
import router from '@/router'
import { useGlobalStore } from '@/store/modules/global'

export const goToNotFoundPage = (isReplace = true) => {
  const route = router.currentRoute.value

  return router.push({
    name: 'NotFound',
    params: {
      pathMatch: route.path.substring(1).split('/'),
    },
    query: route.query,
    hash: route.hash,
    replace: isReplace,
  })
}

export const goToBack = (
  fallbackRoute: RouteLocationRaw = { name: 'Home' },
) => {
  const globalStore = useGlobalStore()
  const currentRouteName = router.currentRoute.value.name
  const { prevRouteName } = globalStore.routeHistory

  if (prevRouteName && prevRouteName !== currentRouteName) {
    router.back()
  } else {
    router.replace(fallbackRoute)
  }
}

export const openWebUrl = (url: string | null = null) => {
  if (url === null) {
    return
  }

  // 프로토콜이 없는 경우 붙여준다.
  if (/^https?:\/\/.+/.test(url) === false) {
    url = `https://${url}`
  }

  const route = parseUrlToRouteFromAppLink(url)

  if (route) {
    router.push(route)
    return
  }

  window.open(url)
}

export const forceReload = () => {
  const [url, params] = window.location.href.split('?')
  const now = Date.now()
  const query = qs.parse(params)

  // ff_ut = fastfive_updated_time
  const updatedTime = Number(query?.ff_ut) || 0

  // 1분이상 차이가 날 경우만 실행
  if (now - updatedTime >= 60000) {
    query.ff_ut = now.toString()
  }

  const nextParams = qs.stringify(query)
  let nextUrl = url

  if (nextParams) {
    nextUrl += `?${nextParams}`
  }

  // safari 같은 경우는 location.reload로 캐시가 무효화되지 않기때문에 주소를 변경해줘야됨
  window.location.href = nextUrl
}
