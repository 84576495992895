import qs from 'query-string'
import { Plugin, inject, reactive } from 'vue'
import { LocationAsRelativeRaw, RouteQueryAndHash } from 'vue-router'

import { DB_TARGET } from '@/env'
import { Popup } from '@/libs/popups'
import router from '@/router'

namespace Applink {
  export interface State {
    isAppFeatures: boolean
    redirectUrl: string | undefined
    routeName: string | undefined
  }

  export interface Info {
    url: string
    routeName: string
    customQuery?: CustomQuery
  }

  export type CustomQuery = Record<string, CustomQuery.Value>

  export namespace CustomQuery {
    export type Value = number | string | (number | string)[]
  }

  export interface SpaceCategoryInfo {
    segment: string
    spaceCategoryId: number
  }
}

export const ApplinkSymbol = Symbol()

export const applinkInfo: Applink.Info[] = [
  {
    url: 'https://fastfive.app.link/YV8juBjKZ7',
    routeName: 'Home',
  },
  {
    url: 'https://fastfive.app.link/eats',
    routeName: 'BenefitEats',
  },
  {
    url: 'https://fastfive.app.link/benefits-detail',
    routeName: 'BenefitDetail',
  },
  {
    url: 'https://fastfive.app.link/benefits',
    routeName: 'Benefits',
  },
  {
    url: 'https://fastfive.app.link/invitation',
    routeName: 'Invitation',
  },
  {
    url: 'https://fastfive.app.link/onboarding',
    routeName: 'Onboarding',
  },
  {
    url: 'https://fastfive.app.link/branch-guide',
    routeName: 'BranchGuide',
  },
  {
    url: 'https://fastfive.app.link/benefits-list',
    routeName: 'BenefitListByCategory',
  },
  {
    url: 'https://fastfive.app.link/branch-notice',
    routeName: 'NoticeDetail',
  },
  {
    url: 'https://fastfive.app.link/inquiry',
    routeName: 'CreateInquiry',
  },
  {
    url: 'https://fastfive.app.link/inquirylist',
    routeName: 'InquiryList',
  },
  {
    url: 'https://fastfive.app.link/reserve',
    routeName: 'Reserve',
  },
  {
    url: 'https://fastfive.app.link/request-cleaning',
    routeName: 'InquiryRequestCleaning',
  },
  {
    url: 'https://fastfive.app.link/benefit-curation',
    routeName: 'BenefitCuration',
  },
  {
    url: 'https://fastfive.app.link/ffplus',
    routeName: 'FastfivePlus',
  },
  ...(
    [
      {
        segment: 'meeting-room',
        spaceCategoryId: 7,
      },
      {
        segment: 'recharging-zone',
        spaceCategoryId: 21,
      },
      {
        segment: 'lounge',
        spaceCategoryId: 9,
      },
      {
        segment: 'studio',
        spaceCategoryId: 15,
      },
      {
        segment: 'seminar-room',
        spaceCategoryId: 8,
      },
      {
        segment: 'moverse',
        spaceCategoryId: (() => {
          switch (DB_TARGET) {
            case 'production':
            case 'staging':
              return 18
            default:
              return 31
          }
        })(),
      },
      {
        segment: 'external-space',
        spaceCategoryId: 29,
      },
      {
        segment: 'meeting-booth',
        spaceCategoryId: 30,
      },
    ] as Applink.SpaceCategoryInfo[]
  ).map(({ segment, spaceCategoryId }): Applink.Info => {
    return {
      url: `https://fastfive.app.link/reserve/${segment}`,
      routeName: 'Reserve',
      customQuery: {
        spaceCategoryId,
      },
    }
  }),
]

export const applinkPlugin: Plugin = {
  install: (app) => {
    const fullPath = [
      window.location.pathname,
      window.location.search,
      window.location.hash,
    ].join('')

    const state = reactive<Applink.State>({
      isAppFeatures: false,
      redirectUrl: undefined,
      routeName: undefined,
    })

    const link = parseAppLink(fullPath)

    if (link.isAppFeatures) {
      state.isAppFeatures = true
    } else if (link.route) {
      state.redirectUrl = router.resolve(link.route).href
    }

    if (link.routeName) {
      state.routeName = link.routeName
    }

    app.provide(ApplinkSymbol, state)
  },
}

export function useApplink() {
  return inject(ApplinkSymbol) as Applink.State
}

/**
 * 앱 링크 파싱
 *
 * fullPath(URL에서 path 이상)에서 앱 링크 데이터를 가져옵니다.
 *
 * @param fullPath
 */
export function parseAppLink(fullPath: string = '') {
  /**
   * - isAppFeatures : 앱에서만 사용하는 기능인지에 대한 여부
   * - route : isAppFeatures가 true이면서 route가 null이면 앱링크로 들어온게 아님
   */
  const resultData = {
    isAppFeatures: false,
    route: null as (LocationAsRelativeRaw & RouteQueryAndHash) | null,
    routeName: null as string | null,
  }

  if (!fullPath) {
    return resultData
  }

  const [pathname, param = ''] = fullPath.split('?')
  const [search] = param.split('#')

  const query = qs.parse(search)
  const isApplink = query.applink === 'true'
  const routeName = String(query.to || '')

  if (!isApplink) {
    return resultData
  }

  resultData.route = {
    name: routeName,
  }

  resultData.routeName = routeName ?? null

  switch (routeName) {
    case 'Home':
    case 'Benefits':
    case 'Onboarding':
    case 'CreateInquiry':
    case 'InquiryList':
    case 'InquiryRequestCleaning':
    case 'FastfivePlus':
      break

    case 'BenefitEats':
      resultData.route.name = 'Home'
      break

    case 'BenefitDetail': {
      const benefitId = String(query.id || '')

      if (benefitId) {
        resultData.route.params = {
          benefitId,
        }
      } else {
        resultData.route.name = 'Benefits'
      }

      break
    }

    case 'Invitation': {
      resultData.route.query = {
        code: String(query.code || '') || undefined,
      }
      break
    }

    case 'BranchGuide': {
      const branchId = String(query.id || '') || undefined

      if (branchId) {
        resultData.route.params = {
          branchId,
        }
      }
      break
    }

    case 'BenefitListByCategory': {
      /**
       * @example
       * - id=중분류
       * - id=중분류,소분류
       */
      const [middleCategoryId, subCategoryId] = String(query.id || '')
        .split(',')
        .map((row) => row.trim() || undefined)

      resultData.route.params = {
        middleCategoryId,
      }
      resultData.route.query = {
        sc_id: subCategoryId,
      }
      break
    }

    case 'NoticeDetail': {
      const args = String(query.id || '')
        .split(',')
        .map((row) => Number(row.trim()))
        .filter((row) => !!row)

      if (args.length === 2) {
        const [branchId, noticeId] = args

        resultData.route.query = {
          branchId,
        }

        resultData.route.params = {
          noticeId,
        }
      } else {
        resultData.isAppFeatures = false
        resultData.route = null
      }
      break
    }

    case 'Reserve': {
      const spaceCategoryId = Number(query?.spaceCategoryId) || undefined

      resultData.route.params = {
        spaceCategoryId,
      }
      break
    }

    case 'BenefitCuration': {
      const curationId = Number(query?.id) || undefined

      if (curationId) {
        resultData.route.params = {
          curationId,
        }
      } else {
        resultData.route.name = 'Benefits'
      }

      break
    }

    /**
     * 딥링크 명세 : https://www.notion.so/fastfive/53fa229ed5b64e3680961d084a32f97a
     */
    case 'PopupPreview': {
      const data = Popup.parsePreviewPopupData(query)

      resultData.route.name = 'Home'

      if (data) {
        resultData.route.query = data
      }

      break
    }

    default: {
      resultData.isAppFeatures = true
      resultData.route = null
      break
    }
  }

  return resultData
}

export function parseUrlToRouteFromAppLink(url: string | null = null) {
  if (!url) return null

  const [urlOrigin, prevParam] = url.split('?')

  const info = applinkInfo.find((row) => urlOrigin === row.url)

  if (!info) return null

  let params = qs.stringify({
    ...info.customQuery,
    applink: true,
    to: info.routeName,
  })

  if (prevParam) {
    params += `&${prevParam}`
  }

  const fullPath = `/?${params}`

  const link = parseAppLink(fullPath)

  if (link.isAppFeatures) return null

  return link.route
}
