import { Notice } from '@/libs/notice'

export namespace Popup {
  export type PreviewPopup = ReturnType<typeof parsePreviewPopupData>

  export enum Type {
    EVENT = 1,
    NOTICE = 2,
  }

  export const parsePreviewPopupData = (input: object) => {
    if (!input || typeof input !== 'object' || Array.isArray(input)) {
      return null
    }

    const obj = input as any

    const popupType = parseInt(obj.popupType as string) || undefined
    const title = String(obj.title) || undefined
    const contents = String(obj.contents) || undefined
    const imageUrl = String(obj.imageUrl) || undefined
    const priority = (String(obj.priority) as Notice.Priority) || undefined

    if (!popupType || !(popupType in Popup.Type)) {
      return null
    }

    switch (popupType) {
      case Popup.Type.EVENT: {
        if (!imageUrl) {
          return null
        }

        return {
          popupType,
          imageUrl,
        }
      }

      case Popup.Type.NOTICE: {
        if (!title || !contents || !priority) {
          return null
        }

        try {
          Notice.getPriorityOption(priority)
        } catch {
          return null
        }

        return {
          popupType,
          title,
          contents,
          priority,
        }
      }

      default:
        return null
    }
  }
}
