<template>
  <modal-component
    :is-hard-rendering="isHardRendering"
    :is-show="isShow"
  >
    <div class="modal-content">
      <div class="modal-body">
        <div
          v-if="!!title"
          class="title"
        >
          {{ title }}
        </div>
        <div
          v-if="!!message"
          v-html="message"
          class="description"
        />
      </div>
      <div class="button-area">
        <div class="button-cancel">
          <button-component
            :text="cancelButtonText"
            @submit="onCancelClick"
            type="outline"
            color="primary-black"
            :height="39"
            :fontSize="15"
          />
        </div>
        <div class="button-submit">
          <button-component
            :text="confirmButtonText"
            @submit="onConfirmClick"
            color="primary-black"
            :height="39"
            :fontSize="15"
          />
        </div>
      </div>
    </div>
  </modal-component>
</template>

<script>
import ButtonComponent from '@/components/common/Button'
import ModalComponent from '@/components/common/Modal/index'

export default {
  name: 'Confirm',
  components: { ModalComponent, ButtonComponent },
  props: {
    isHardRendering: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    title: String,
    message: String,
    confirmButtonText: {
      type: String,
      default: '확인',
    },
    cancelButtonText: {
      type: String,
      default: '취소',
    },
  },
  emits: ['confirm', 'cancel'],
  setup(props, { emit }) {
    return {
      onCancelClick() {
        emit('cancel')
      },
      onConfirmClick() {
        emit('confirm')
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  width: 320px;
  .modal-body {
    padding: 24px 24px 40px 24px;
  }
  .title {
    white-space: pre-line;
    @include font_ko(18px, 400);
    color: $primary-black;
    padding-bottom: 12px;
  }
  .description {
    white-space: pre-line;
    @include font_ko(15px, 400);
    color: $grey-01;
  }
  .button-area {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px 20px;

    .button-submit {
      margin-left: 10px;
    }
  }
}
</style>
