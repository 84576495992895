import { GroupPermissionCode, MemberRole } from '@/interfaces/global'
import { SessionStore } from '@/interfaces/store/session'

export const memberRoles: MemberRole[] = ['master', 'admin', 'associate']

export const getMemberGroupPermission = (
  memberGroup?: SessionStore.State.MemberGroup | null,
): GroupPermissionCode => {
  if (!memberGroup) {
    return 'GUEST'
  }

  if (memberGroup.isRegistered) {
    if (memberGroup.isActive) {
      return 'RESIDENT'
    }

    return 'PRE_RESIDENT'
  }

  return 'LEAVER'
}
