<template>
  <lottie-player
    ref="playerRef"
    :autoplay="autoplay"
    :background="background"
    :controls="controls"
    :count="count"
    :direction="direction"
    :hover="hover"
    :loop="loop"
    :mode="mode"
    :preserveAspectRatio="preserveAspectRatio"
    :renderer="renderer"
    :speed="speed"
    :src="src"
    :intermission="intermission"
    :style="styles"
  >
  </lottie-player>
</template>

<script lang="ts">
import '@lottiefiles/lottie-player'
import { LottiePlayer, PlayMode } from '@lottiefiles/lottie-player'
import {
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'

/**
 * @see https://docs.lottiefiles.com/lottie-player
 */
export default defineComponent({
  props: {
    /**
     * When set to true, automatically plays the animation on loading it.
     */
    autoplay: {
      type: Boolean,
      default: false,
    },

    /**
     * Background color. By default, the background is transparent and will take the color of the parent container.
     */
    background: {
      type: String,
    },

    /**
     * When set to true, displays player controls
     */
    controls: {
      type: Boolean,
      default: false,
    },

    /**
     * Number of times to loop the animation
     */
    count: {
      type: Number,
    },

    /**
     * Direction of the animation. Set to 1 to play the animation forward or set to -1 to play it backward.
     */
    direction: {
      type: Number as PropType<1 | -1>,
      default: 1,
    },

    /**
     * When set to true, plays animation on mouse over
     */
    hover: {
      type: Boolean,
      default: false,
    },

    /**
     * When set to true, loops the animation. The count property defines the number of times to loop the animation.
     * Setting the count property to 0 and setting loop to true, loops the animation indefinitely.
     */
    loop: {
      type: Boolean,
      default: false,
    },

    /**
     * Play mode. Setting the mode to PlayMode.Bounce plays the animation in an indefinite cycle, forwards and then backwards.
     */
    mode: {
      type: String as PropType<PlayMode>,
      default: PlayMode.Normal,
    },

    /**
     * Valid value to preserve the aspect ratio. See the  for more information.
     */
    preserveAspectRatio: {
      type: String,
      default: 'xMidYMid meet',
    },

    /**
     * The renderer to use for the animation
     */
    renderer: {
      type: String as PropType<'svg' | 'html' | 'canvas'>,
      default: 'svg',
    },

    /**
     * Animation speed.
     * Set this parameter to any positive number.
     */
    speed: {
      type: Number,
      default: 1,
    },

    /**
     * The animation to play, either  JSON data or a URL to a JSON file.
     * This parameter is mandatory.
     */
    src: null,

    /**
     * Duration (in milliseconds) to pause before playing each cycle in a looped animation.
     * Set this parameter to 0 (no pause) or any positive number.
     */
    intermission: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  emits: {
    load: null,
    error: null,
    ready: null,
    play: null,
    pause: null,
    stop: null,
    freeze: null,
    loop: null,
    complete: null,
    frame: null,
  },
  setup(props, { emit }) {
    const playerRef = ref<LottiePlayer>()

    const styles = computed(() => {
      return {
        width: props.width,
        height: props.height,
      }
    })

    onMounted(() => {
      if (!playerRef.value) return

      playerRef.value.addEventListener('load', onLoad)
      playerRef.value.addEventListener('error', onError)
      playerRef.value.addEventListener('ready', onReady)
      playerRef.value.addEventListener('play', onPlay)
      playerRef.value.addEventListener('pause', onPause)
      playerRef.value.addEventListener('stop', onStop)
      playerRef.value.addEventListener('freeze', onFreeze)
      playerRef.value.addEventListener('loop', onLoop)
      playerRef.value.addEventListener('complete', onComplete)
      playerRef.value.addEventListener('frame', onFrame)
    })

    onBeforeUnmount(() => {
      if (!playerRef.value) return

      playerRef.value.removeEventListener('load', onLoad)
      playerRef.value.removeEventListener('error', onError)
      playerRef.value.removeEventListener('ready', onReady)
      playerRef.value.removeEventListener('play', onPlay)
      playerRef.value.removeEventListener('pause', onPause)
      playerRef.value.removeEventListener('stop', onStop)
      playerRef.value.removeEventListener('freeze', onFreeze)
      playerRef.value.removeEventListener('loop', onLoop)
      playerRef.value.removeEventListener('complete', onComplete)
      playerRef.value.removeEventListener('frame', onFrame)
    })

    function onLoad(...args) {
      emit('load', ...args)
    }

    function onError(...args) {
      emit('error', ...args)
    }

    function onReady(...args) {
      emit('ready', ...args)
    }

    function onPlay(...args) {
      emit('play', ...args)
    }

    function onPause(...args) {
      emit('pause', ...args)
    }

    function onStop(...args) {
      emit('stop', ...args)
    }

    function onFreeze(...args) {
      emit('freeze', ...args)
    }

    function onLoop(...args) {
      emit('loop', ...args)
    }

    function onComplete(...args) {
      emit('complete', ...args)
    }

    function onFrame(...args) {
      emit('frame', ...args)
    }

    return {
      playerRef,
      styles,
    }
  },
})
</script>
