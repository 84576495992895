import { defineStore } from 'pinia'

import { BENEFIT_SEARCH_MODAL } from '@/store/keys'

export const useBenefitSearchModalStore = defineStore(BENEFIT_SEARCH_MODAL, {
  state: () => ({
    isShow: false,
    searchText: '',
  }),
  actions: {
    showModal() {
      this.isShow = true
    },

    hideModal() {
      this.isShow = false
    },

    setSearchText(val = '') {
      this.searchText = val
    },
  },
})
