<template>
  <div :class="$style.container">
    <div :class="$style.contents">
      <img
        :class="$style.image"
        :src="images.error"
      />

      <div :class="$style.title">앗! 요청하신 정보를 불러오지 못했습니다.</div>

      <div :class="$style.subtitle">다시 한번 시도해 주세요.</div>

      <Button
        :class="$style.btn"
        frame="flexable"
        theme="border-gray-35"
        size="large"
        @click="() => onRetryClick()"
      >
        새로고침
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import Button from '@/components/v2/button/Button.vue'

import { useNetworkError } from '@/hooks/network-error'

export default defineComponent({
  name: 'NetworkError',
  components: {
    Button,
  },
  setup() {
    const networkError = useNetworkError()
    const images = ref({
      error: require('@/assets/images/v2/error/emptystate_error.svg'),
    })

    function onRetryClick() {
      networkError.clear()
    }

    return {
      images,
      onRetryClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  white-space: pre-line;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contents {
    text-align: center;

    .image {
      display: block;
      margin: 0 auto 32px;
      width: 140px;
    }

    .title {
      @include font_v2('ko', 22px, 700);
      color: $f-primary-black;
    }

    .subtitle {
      @include font_v2('ko', 15px, 400);
      color: $f-gray-50;
    }

    .btn {
      margin: 24px auto 0;
    }
  }
}
</style>
