<template>
  <div :class="$style.container">
    <SimpleBar
      ref="wrapperRef"
      :class="$style.wrapper"
    >
      <div
        v-if="searchable"
        :class="{
          [$style.item]: true,
          [$style.searchbar]: true,
        }"
      >
        <Search
          ref="searchRef"
          :modelValue="searchValue"
          :placeholder="searchInputPlaceholder"
          width="100%"
          @update:modelValue="updateSearchValue"
        />
      </div>

      <div
        v-if="noticeText"
        :class="{
          [$style.item]: true,
          [$style.noticeText]: true,
        }"
      >
        <img
          :class="$style.icon"
          src="@/assets/images/v2/icon/20px/reservation_fill.svg"
          width="16"
          height="16"
        />
        {{ noticeText }}
      </div>

      <template
        v-if="searchable && state.searchValue && !filteredOptions.length"
      >
        <div :class="[$style.item, $style.notFound]">
          {{ '검색 결과가 없습니다.\n다른 검색어를 입력해 주세요.' }}
        </div>
      </template>
      <template
        v-else
        v-for="(item, key) in filteredOptions"
        :key="key"
      >
        <li
          :class="{
            [$style.item]: true,
            [$style.header]: isHeaderItem(item),
            [$style.selected]: isSelectedItem(item),
            [$style.withSearchbar]: searchable && key === 0,
            [$style.withNoticeText]: noticeText && key === 0,
          }"
          @click="() => onItemClick(item)"
          @mouseenter="(ev) => showPreviewLayer(ev, item)"
          @mousemove="(ev) => item.type === 'item' && ev.stopPropagation()"
        >
          <div :class="$style.label">
            <div :class="$style.labelText">{{ item.label }}</div>
            <div
              v-if="item.notice"
              :class="$style.notice"
            >
              <img
                :class="$style.icon"
                src="@/assets/images/v2/icon/16px/notice.svg"
                width="16"
                height="16"
              />
              {{ item.notice }}
            </div>
          </div>
          <div
            v-if="item.subText"
            :class="$style.subText"
          >
            {{ item.subText }}
          </div>
        </li>
      </template>
    </SimpleBar>
  </div>

  <Teleport to="body">
    <div :class="$style.previewLayerContainer">
      <Transition
        :enterActiveClass="$style.ActiveTransition"
        :enterFromClass="$style.hiddenLayer"
        :enterToClass="$style.shownLayer"
        :leaveActiveClass="$style.ActiveTransition"
        :leaveFromClass="$style.shownLayer"
        :leaveToClass="$style.hiddenLayer"
      >
        <div
          v-if="previewLayerState.isShown"
          :class="[$style.interactionArea, 'tw-fixed']"
          :style="{
            top: withPx(previewLayerState.top) || 'auto',
            bottom: withPx(previewLayerState.bottom) || 'auto',
            left: withPx(previewLayerState.left) || 'auto',
            right: withPx(previewLayerState.right) || 'auto',
            'z-index': 101,
          }"
          @mousemove.stop
        >
          <div
            ref="previewLayerEl"
            :class="[
              $style.previewLayer,
              'tw-w-[360px] tw-rounded-[16px] tw-bg-v2-primary-white tw-p-4',
            ]"
            :style="{
              boxShadow: `0 0 16px ${hexToRGBA($colors.black, 0.05)}`,
            }"
          >
            <div
              class="tw-h-[205px] tw-w-[328px] tw-rounded-[8px] tw-bg-cover tw-bg-center"
              :style="{
                backgroundImage: `url(${previewLayerValue.imgUrl || require('@/assets/images/placeholder_800w.jpg')})`,
              }"
            />

            <div class="tw-mb-2 tw-mt-4 tw-flex tw-items-center">
              <div
                v-if="previewLayerValue.summaryPrefix"
                class="tw-mr-1"
              >
                <template
                  v-if="previewLayerValue.summaryPrefix.type === 'icon'"
                >
                  <img
                    class="tw-block"
                    :src="previewLayerValue.summaryPrefix.url"
                    alt="icon"
                    :width="previewLayerValue.summaryPrefix.width"
                    :height="previewLayerValue.summaryPrefix.height"
                  />
                </template>
              </div>
              <!-- 상세주소 표시 -->
              <div :class="[$style.summaryText, 'tw-text-v2-gray-50']">
                {{ previewSummaryText }}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  useCssModule,
  watch,
} from 'vue'

import SimpleBar from '@/components/v2/SimpleBar.vue'
import Search from '@/components/v2/textfield/Search.vue'

import { DropdownV2 } from '@/interfaces/components/v2/dropdown'
import { withPx } from '@/libs/css'
import { thumbnailMaker } from '@/libs/thumbnail'

export default defineComponent({
  components: {
    SimpleBar,
    Search,
  },
  props: {
    options: {
      type: Array as PropType<DropdownV2.List.Option[]>,
      default: () => [],
    },
    modelValue: {
      type: null as unknown as PropType<DropdownV2.List.ItemOption['value']>,
      default: null,
    },
    width: {
      type: String,
      default: '248px',
    },
    maxHeight: {
      type: Number,
    },
    scrollingAfterMounted: {
      type: Boolean,
      default: false,
    },
    scrollingAfterChanged: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchInputPlaceholder: {
      type: String,
      default: '',
    },
    searchInputAutoFocusing: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    noticeText: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': (_: DropdownV2.List.ItemOption['value']) => true,
    'update:searchValue': (val: string) => typeof val === 'string',
    select: (val: DropdownV2.List.ItemOption) => !!val,
  },
  setup(props, { emit }) {
    const wrapperRef = ref<InstanceType<typeof SimpleBar>>()
    const searchRef = ref<InstanceType<typeof Search>>()
    const previewLayerEl = ref<HTMLDivElement>()

    const $style = useCssModule()

    const state = reactive({
      selectedValue: null as DropdownV2.List.ItemOption['value'],
      wrapperMaxHeight: 0,
      searchValue: '',
    })

    const previewLayerState = reactive({
      isShown: false,
      lastItemEl: null as HTMLLIElement | null,
      lastValue: null as DropdownV2.List.ValueType,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    })

    const wrapperEl = computed(() => wrapperRef.value?.scrollEl)

    const cssVars = computed(() => {
      return {
        width: props.width,
        wrapperMaxHeight: withPx(state.wrapperMaxHeight) || 'none',
        fontSize: withPx(props.fontSize) || 'inherit',
      }
    })

    const itemOptionMap = computed(() => {
      const map = new Map<
        DropdownV2.List.ValueType,
        DropdownV2.List.ItemOption
      >()

      props.options.forEach((row) => {
        if (row.type !== 'item') return
        map.set(row.value, row)
      })

      return map
    })

    const previewSummaryText = computed(() => {
      const subway = previewLayerValue.value.subwayName
      const summary = previewLayerValue.value.summary

      if (subway && summary) {
        return `${subway}역 ${previewLayerValue.value.summary}`
      }
      if (summary) {
        return summary
      }
      return '준비중입니다.'
    })

    const previewLayerValue = computed<DropdownV2.PreviewLayer>(() => {
      const option = itemOptionMap.value.get(previewLayerState.lastValue)

      const imgUrl = thumbnailMaker.fromServerUrl({
        url: option?.preview?.imgUrl,
        width: 656,
        height: 410,
      })

      return {
        position: option?.preview?.position || 'right',
        imgUrl,
        summary: option?.preview?.summary || null,
        summaryPrefix: option?.preview?.summaryPrefix || null,
        subwayName: option?.preview?.subwayName || null,
      }
    })

    const filteredOptions = computed(() => {
      const nextOptions: DropdownV2.List.Option[] = []
      let lastHeader = null as DropdownV2.List.HeaderOption | null
      const optionMapByHeader = new Map<
        typeof lastHeader,
        DropdownV2.List.ItemOption[]
      >()

      props.options.forEach((option) => {
        switch (option.type) {
          case 'header':
            lastHeader = option
            break

          case 'item':
            if (
              !state.searchValue ||
              option.label.includes(state.searchValue) ||
              option.subText?.includes(state.searchValue) ||
              option.preview?.summary?.includes(state.searchValue)
            ) {
              const arr = optionMapByHeader.get(lastHeader) || []
              optionMapByHeader.set(lastHeader, arr.concat(option))
            }
            break
        }
      })

      optionMapByHeader.forEach((items, header) => {
        if (!items.length) return

        if (header) {
          nextOptions.push(header)
        }

        nextOptions.push(...items)
      })

      return nextOptions
    })

    watch(
      () => props.modelValue,
      (value) => {
        state.selectedValue = value

        useScrollingAfterChanged()
      },
    )

    watch(
      () => props.searchValue,
      (value) => {
        state.searchValue = value
      },
      {
        immediate: true,
      },
    )

    onBeforeMount(() => {
      state.selectedValue = props.modelValue
    })

    onMounted(() => {
      window.addEventListener('resize', globalResizeListener)
      window.addEventListener('mousemove', hidePreviewLayer)
      window.addEventListener('scroll', calcPreviewLayerPosition)

      calcDropdownContentMaxHeight()

      useScrollingAfterMounted()

      if (props.searchable && props.searchInputAutoFocusing) {
        searchRef.value?.focus()
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', globalResizeListener)
      window.removeEventListener('mousemove', hidePreviewLayer)
      window.removeEventListener('scroll', calcPreviewLayerPosition)
    })

    function onItemClick(item: DropdownV2.List.Option) {
      if (item.type === 'header') {
        return
      }

      state.selectedValue = item.value

      emit('update:modelValue', item.value)
      emit('select', item)
    }

    function isHeaderItem(item: DropdownV2.List.Option) {
      return item.type === 'header'
    }

    function isSelectedItem(item: DropdownV2.List.Option) {
      if (item.type === 'header') {
        return
      }

      return item.value === state.selectedValue
    }

    function calcDropdownContentMaxHeight() {
      const bottomFreeSpace = 40
      const el = wrapperEl.value
      let offsetTop = 0
      let maxHeight = window.innerHeight - bottomFreeSpace

      if (!el) {
        state.wrapperMaxHeight = 0
        return
      }

      offsetTop += el.getBoundingClientRect().top

      maxHeight -= offsetTop

      if (maxHeight <= 0) {
        state.wrapperMaxHeight = 0
        return
      }

      if (props.maxHeight && props.maxHeight < maxHeight) {
        maxHeight = props.maxHeight
      }

      state.wrapperMaxHeight = Math.round(maxHeight)
    }

    function globalResizeListener() {
      calcDropdownContentMaxHeight()
    }

    function useScrollingAfterMounted() {
      if (props.scrollingAfterMounted) {
        scrollToSelectedItem()
      }
    }

    function useScrollingAfterChanged() {
      if (props.scrollingAfterChanged) {
        scrollToSelectedItem()
      }
    }

    async function scrollToSelectedItem() {
      await nextTick()

      const el = wrapperEl.value

      if (!el) return

      const target = el
        .getElementsByClassName($style.selected)
        .item(0) as HTMLElement | null

      if (typeof target?.offsetTop === 'number') {
        el.scrollTop = target.offsetTop
      } else {
        target?.scrollIntoView()
      }
    }

    function showPreviewLayer(ev: MouseEvent, item: DropdownV2.List.Option) {
      const itemEl = ev.target as HTMLLIElement | null

      if (
        !itemEl ||
        item.type !== 'item' ||
        !item.preview
        //
      ) {
        return
      }

      previewLayerState.isShown = true
      previewLayerState.lastItemEl = itemEl
      previewLayerState.lastValue = item.value

      calcPreviewLayerPosition()
    }

    function calcPreviewLayerPosition() {
      const option = itemOptionMap.value.get(previewLayerState.lastValue)
      const itemElRect = previewLayerState.lastItemEl?.getBoundingClientRect()
      const scrollElRect = wrapperEl.value?.getBoundingClientRect()

      if (!option?.preview || !itemElRect || !scrollElRect) return

      previewLayerState.top = itemElRect.top
      previewLayerState.bottom = 0

      switch (option.preview.position) {
        case 'left':
          previewLayerState.left = 0
          previewLayerState.right = window.innerWidth - itemElRect.left
          break

        case 'right':
          previewLayerState.left = itemElRect.right
          previewLayerState.right = 0
          break
      }

      requestAnimationFrame(() => {
        const layerEl = previewLayerEl.value
        const layerElRect = layerEl?.getBoundingClientRect()

        if (layerElRect && scrollElRect.bottom < layerElRect.bottom) {
          previewLayerState.top = 0
          previewLayerState.bottom = window.innerHeight - scrollElRect.bottom
        }
      })
    }

    function hidePreviewLayer() {
      previewLayerState.isShown = false
    }

    function updateSearchValue(val: string) {
      state.searchValue = val
      emit('update:searchValue', val)
    }

    return {
      // ref
      wrapperRef,
      searchRef,
      previewLayerEl,

      // state
      state,
      previewLayerState,

      // computed
      wrapperEl,
      cssVars,
      previewLayerValue,
      filteredOptions,
      previewSummaryText,

      // fn
      onItemClick,
      isHeaderItem,
      isSelectedItem,
      showPreviewLayer,
      hidePreviewLayer,
      updateSearchValue,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  @include font_v2('ko', 14px, 400);
  width: v-bind('cssVars.width');
  max-height: v-bind('cssVars.wrapperMaxHeight');
  overflow: hidden;
  border-radius: 16px;
  filter: drop-shadow(0px 0px 16px $f-trans-black-05);
  will-change: filter;

  .wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: $f-primary-white;
    width: 100%;
    max-height: v-bind('cssVars.wrapperMaxHeight');
    font-size: v-bind('cssVars.fontSize');
    position: relative;

    .item {
      padding: 10px 24px;
      word-break: break-all;
      color: $f-gray-80;

      .label {
        display: flex;

        .labelText {
          word-break: break-all;
        }

        .notice {
          min-width: max-content;
          margin-left: 8px;
          display: flex;
          align-items: center;
          height: 22px;
          color: $f-gray-50;
          @include font_v2('ko', 10px, 500);

          .icon {
            margin-right: 2px;
          }
        }
      }

      .subText {
        margin-top: 2px;
        color: $f-gray-40;
        @include font_v2('ko', 12px, 400);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:not(.header):not(.searchbar):not(.notFound):not(.noticeText) {
        cursor: pointer;

        &:hover {
          background-color: $f-trans-black-03;
        }

        &:active {
          background-color: $f-trans-black-05;
        }

        &.selected {
          .label {
            .labelText {
              font-weight: 700;
            }
          }
        }
      }

      &.header {
        @include font_v2('ko', 12px, 400);

        padding-top: 24px;
        color: $f-gray-50;
        border-top: 1px solid $f-gray-25;
        box-sizing: border-box;
      }

      &:first-child {
        padding-top: 24px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-top: none;
      }

      &:last-child {
        padding-bottom: 24px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &.withSearchbar,
      &.withNoticeText {
        border-top: none;
        padding-top: 10px;
      }

      &.searchbar {
        background-color: $f-primary-white;
        padding: 24px 24px 10px;
        position: sticky;
        top: 0;
        z-index: 1;
      }

      &.notFound {
        @include font_v2('ko', 15px, 400);
        color: $f-gray-50;
        text-align: center;
        white-space: pre-line;
        padding-top: 24px;
        padding-bottom: 48px;
      }
    }

    .noticeText {
      display: flex;
      align-items: center;
      gap: 4px;
      @include font_v2('ko', 13px, 400);
      color: $f-gray-40;
    }
  }
}

.previewLayerContainer {
  .interactionArea {
    padding-left: 8px;
    padding-right: 8px;
  }

  .previewLayer {
    .summaryText {
      @include font_v2('ko', 12px, 400);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ActiveTransition {
    transition: opacity 150ms ease-in-out;
  }

  .hiddenLayer {
    opacity: 0;
  }

  .shownLayer {
    opacity: 1;
  }
}
</style>
