import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M3.21369 2C2.71499 2 2.23672 2.19867 1.88408 2.55231C1.53145 2.90595 1.33334 3.38559 1.33334 3.88571V12.1143C1.33334 12.6144 1.53145 13.094 1.88408 13.4477C2.23672 13.8013 2.71499 14 3.21369 14H8.00001C8.49871 14 8.97698 13.8013 9.32962 13.4477C9.68225 13.094 9.88036 12.6144 9.88036 12.1143V10.7429C9.88036 10.4588 9.65076 10.2286 9.36754 10.2286C9.08431 10.2286 8.85472 10.4588 8.85472 10.7429V12.1143C8.85472 12.3416 8.76467 12.5596 8.60438 12.7204C8.44409 12.8811 8.2267 12.9714 8.00001 12.9714H3.21369C2.98701 12.9714 2.76961 12.8811 2.60932 12.7204C2.44903 12.5596 2.35899 12.3416 2.35899 12.1143V3.88571C2.35899 3.65839 2.44903 3.44037 2.60932 3.27962C2.76961 3.11888 2.98701 3.02857 3.21369 3.02857H8.00001C8.2267 3.02857 8.44409 3.11888 8.60438 3.27962C8.76467 3.44037 8.85472 3.65839 8.85472 3.88571V5.25714C8.85472 5.54118 9.08431 5.77143 9.36754 5.77143C9.65076 5.77143 9.88036 5.54118 9.88036 5.25714V3.88571C9.88036 3.38559 9.68225 2.90595 9.32962 2.55231C8.97698 2.19867 8.49871 2 8.00001 2H3.21369ZM12.4652 5.57916C12.2649 5.37832 11.9402 5.37832 11.7399 5.57916C11.5397 5.78 11.5397 6.10563 11.7399 6.30647L12.9158 7.48567H4.58118C4.29796 7.48567 4.06836 7.71592 4.06836 7.99996C4.06836 8.28399 4.29796 8.51424 4.58118 8.51424H12.9158L11.7399 9.69344C11.5397 9.89429 11.5397 10.2199 11.7399 10.4208C11.9402 10.6216 12.2649 10.6216 12.4652 10.4208L14.5165 8.36361C14.7167 8.16277 14.7167 7.83714 14.5165 7.6363L12.4652 5.57916Z",
      fill: _ctx.stroke
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}