import { AxiosCacheInstance } from 'axios-cache-interceptor'

import {
  BenefitCategoryGetListApiPayload,
  BenefitCategoryGetListApiResponse,
} from '@/interfaces/api/benefits/category'
import { axiosHandler } from '@/libs/api/axios-handler'

export const createBenefitCategoryService = (client: AxiosCacheInstance) => {
  return {
    async getList(payload: BenefitCategoryGetListApiPayload = {}) {
      return await axiosHandler<BenefitCategoryGetListApiResponse>(() => {
        return client.get('/mobile/benefit/category', {
          params: payload,
        })
      })
    },
  }
}
