<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.readonly]: readonly,
      [$style.active]: state.isChecked,
      [themeClassName]: true,
    }"
    @click="onClick"
  >
    <div :class="$style.point"></div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  useCssModule,
  watch,
} from 'vue'

import type { IRadio } from '@/interfaces/components/v2/toggle/radio'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<IRadio.Type>,
      default: 'dark' as IRadio.Type,
    },
  },
  emits: {
    'update:modelValue': (val: boolean) => typeof val === 'boolean',
    change: (val: boolean) => typeof val === 'boolean',
  },
  setup(props, { emit }) {
    const $style = useCssModule()

    const state = reactive({
      isChecked: false,
    })

    const themeClassName = computed(() => {
      switch (props.type) {
        case 'light':
          return $style.lightTheme
      }

      return $style.darkTheme
    })

    watch(
      () => props.modelValue,
      (checked) => {
        state.isChecked = checked
      },
    )

    onBeforeMount(() => {
      state.isChecked = !!props.modelValue
    })

    function onClick() {
      if (props.readonly) {
        return
      }

      state.isChecked = !state.isChecked

      emit('update:modelValue', state.isChecked)
      emit('change', state.isChecked)
    }

    return {
      state,
      themeClassName,
      onClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: inline-flex;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: $f-gray-40;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  .point {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 50%;
  }

  &:not(.readonly) {
    cursor: pointer;
  }

  &.darkTheme {
    &.active {
      border-color: $f-secondary-orange;

      .point {
        background-color: $f-secondary-orange;
      }
    }
  }

  &.lightTheme {
    &.active {
      border-color: $f-primary-black;
      border-width: 7px;
    }
  }
}
</style>
